import ExcelUpload from "../base"

const schema = [
    {
        name: "Supplier",
        type: "text"
    },
    {
        name: "UPC",
        type: "text"
    },
    {
        name: "Vendor SKU",
        type: "text"
    },
    {
        name: "Title",
        type: "text"
    },
    {
        name: "Size",
        type: "text"
    },
    {
        name: "UOM",
        type: "text"
    },
    {
        name: "Unit Cost",
        type: "number"
    },
    {
        name: "Case Pack",
        type: "number"
    },
    {
        name: "Case Cost",
        type: "number"
    },
] as const

const excelUpload = new ExcelUpload(schema)

export default excelUpload
