import { PromoV2 } from 'assets/images/public'
import useAuthContext from 'contexts/Auth/useAuthContext'
import { FC } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

const PublicLayout: FC = () => {
  const path = useLocation().pathname
  const user = useAuthContext()
  return (
    <div className="overflow-y-auto overflow-x-hidden bg-slate-950 w-full h-full">
      <div className="flex items-center py-8 px-8 xl:px-24 gap-8 border-b border-b-slate-900 w-full">
        <div className="basis-1 grow flex justify-start">
          <img src={PromoV2.logo} alt="logo" className="h-12 w-auto aspect-[2]" />
        </div>
        <div className="basis-1 grow">
        </div>
      </div>
      <Outlet />
      <div className="grow" />
    </div>
  )
}

export default PublicLayout
