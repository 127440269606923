import { MasterUPCCatalogItem } from "types/housedAsins";
import { IColumnDefinition } from "types/table";

const columnDef: IColumnDefinition<MasterUPCCatalogItem>[] = [
    {
        field: "supplier",
        title: "Supplier",
        filters: "string",
        pin: true
    },
    {
        field: "title",
        title: "Title",
        filters: "string",
        pin: true
    },
    {
        field: "size",
        title: "Size",
        filters: "number"
    },
    {
        field: "uom",
        title: "UOM",
        filters: "string"
    },
    {
        field: "upc",
        title: "UPC",
        filters: "string"
    },
    {
        field: "vendorSKU",
        title: "Vendor SKU",
        filters: "string"
    },
    {
        field: "unitCost",
        title: "Unit Cost",
        filters: "number"
    },
    {
        field: "casePack",
        title: "Case Pack",
        filters: "number"
    },
    {
        field: "caseCost",
        title: "Case Cost",
        filters: "number"
    }
]

export default columnDef;