import api from 'api'
import { FC, useState } from 'react'

const AmazonLoginPage: FC = () => {
  const [submitting, setSubmitting] = useState(false)

  const onClick = () => {
    if (submitting) return
    setSubmitting(true)
    api.getAmazonAuthUri().then((res) => {
      window.open(res, "_self")
    })
  }

  return (
    <div className="flex w-full h-full items-center justify-center">
      <button disabled={submitting} className="button-blue" children="Connect Amazon seller account" onClick={onClick} />
    </div>
  )
}

export default AmazonLoginPage
