import { IColumnDefinition } from "types/table";
import { IWarehouseInboundShipment } from "types/warehouse";

export const columnDef: IColumnDefinition[] = [
    {
        field: "createdAt",
        title: "Processed Date",
        filters: "date",
    },
    {
        field: "title",
        title: "Title",
        filters: "string",
    },
    {
        field: "supplier",
        queryField: "supplier.name",
        title: "Supplier",
        filters: "string",
    },
    {
        field: "asins",
        title: "Unique ASINs",
        filters: "number",
    },
    {
        field: "units",
        title: "Units",
        filters: "number",
    },
    {
        field: "status",
        title: "Status",
        filters: "string",
    },
    {
        field: "eta",
        title: "Expected Delivery Date",
        filters: "date",
    }
];