import useTableContext from 'contexts/Table/useTableContext'
import { FC, PropsWithChildren, TdHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react'

const TD: FC<PropsWithChildren<TdHTMLAttributes<HTMLTableCellElement>>> = ({ children, className, style, ...props }) => {
  const tdRef = useRef<HTMLTableCellElement>(null)
  const {
    table: { columnOffsets, columns: _columns, select },
  } = useTableContext()
  const [index, setIndex] = useState<number>(0)

  const columns = useMemo(() => [...(select ? [{ pin: true }] : []), ..._columns], [select, _columns])

  useEffect(() => {
    if (tdRef.current) {
      const index = tdRef.current.cellIndex
      setIndex(index)
    }
  }, [tdRef, select, columns])

  const isPinned = !!columns[index]?.pin || (select && index === 0)

  return (
    <td className={className} data-pinned={isPinned} ref={tdRef} style={{ ...style, ...(isPinned ? { left: columnOffsets[index] } : undefined) }} {...props}>
      {children}
    </td>
  )
}

export default TD
