import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import housedAsinsApi from "api/housedAsins"
import BulkUpload from "components/modals/BulkUpload"
import firebase from "config/firebase"
import useFilteringContext from "contexts/Filter/useFilteringContext"
import useUserContext from "contexts/User/useUserContext"
import { uploadBytes, ref } from "firebase/storage"
import useAwaitableModal from "hooks/useAwaitableModal"
import useExcelUpload from "hooks/useExcelUpload"
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { HousedAsin } from "types/housedAsins"
import { IApiFilter } from "types/tableFiltering"
import { HousedAsinsExcelUpload } from "utils/excelUpload/templates"

const queryFn = async ({pageParam: page, queryKey}: {pageParam: number, queryKey: [string, IApiFilter]}) => {
  return housedAsinsApi.getHoused({page, ...queryKey[1]})
}

const useHoused = () => {
  const filter = useFilteringContext()
  const {search, ordering, opFilters: filters} = filter
  const path = useLocation().pathname

  const queryKey = useMemo(() => (
    [
    'housed-asins',
    {
      search,
      ordering,
      filters
    }
    ] as [string, IApiFilter]
  ), [search, ordering, filters])

  const {data, refetch, fetchNextPage, isFetchingNextPage, hasNextPage: hasMore} = useInfiniteQuery(
    {
      queryKey: queryKey,
      queryFn,
      initialPageParam: 1,
      getNextPageParam: (lastPage) => lastPage.next,
      enabled: path === '/app/housed/housed'
    }
  )
  const loadingNextPage = isFetchingNextPage

  const housed = useMemo(() => data ? [...data.pages.flatMap((p) => p.items)] : undefined, [data])

  const exportCSV = useCallback(() => {
    housedAsinsApi.exportAsinEntries(queryKey[1])
  }, [queryKey])

  const qc = useQueryClient()

  const setItems: Dispatch<SetStateAction<HousedAsin[] | undefined>> = (action) => {
    qc.setQueryData(queryKey, (old: typeof data) => {
      if (!old) return old
      if (action instanceof Function){
        return {
          ...old,
          pages: old.pages.map((p) => ({
            ...p,
            items: action(p.items)
          }))
        }
      } if (!action) {
        refetch()
      } else {
        return {
          ...old,
          pages: old.pages.map((p, i) => ({
            ...p,
            items: action[i]
          }))
        }
      }
    })
  };

  const entryExcelUpload = useExcelUpload(HousedAsinsExcelUpload)
  const [requestUpload, UploadModal] = useAwaitableModal(BulkUpload, { template: entryExcelUpload, respondWithFile: true })
  const [importing, setImporting] = useState<boolean | 'uploading' | 'processing'>(false)
  const user = useUserContext()
  const navigate = useNavigate()

  const importExcel = useCallback(async () => {
    if (importing) return
    setImporting('uploading')
    return requestUpload()
      .then(async (res) => {
        if (!res?.result) return
        const { result } = res
        const dest = `users/${user?.id}/housedAsins/${Date.now()}.xlsx`
        await uploadBytes(ref(firebase.storage, dest), result as File).then(async () => {
          setImporting('processing')
          return housedAsinsApi.uploadHoused(dest).then(() => {
            navigate(0)
          })
        })
      })
      .finally(() => setImporting(false))
  }, [importing, requestUpload, user?.id])

  return {
    exportCSV,
    loadHoused: refetch,
    loadHousedNextPage: fetchNextPage,
    hasMore,
    setHoused: setItems,
    loadingNextPage,
    housed,
    UploadModal,
    importExcel,
    importing,
  }
}

export default useHoused
