import TD from 'components/tables/TD'
import TR from 'components/tables/TR'
import { FC } from 'react'
import { IWarehouseOutboundShipment } from 'types/warehouse'
import { usNumber } from 'utils/formatting'

interface props {
  item: IWarehouseOutboundShipment
}

const Item: FC<props> = ({ item }) => {
  return (
    <TR>
      <TD>
        {new Date(item.dateIn).toLocaleDateString("en-US", {month: "short", day: "numeric", year: "numeric"})}
      </TD>
      <TD>
        <input type="text" value={item.shipmentId} readOnly />
      </TD>
      <TD>
        {usNumber(item.asins, 0)}
      </TD>
      <TD>
        {usNumber(item.units, 0)}
      </TD>
      <TD>
        {item.shipmentStatus}
      </TD>
      <TD>
        {new Date(item.dateOut).toLocaleDateString("en-US", {month: "short", day: "numeric", year: "numeric"})}
      </TD>
    </TR>
  )
}

export default Item
