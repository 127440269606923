import { Dispatch, SetStateAction, createContext } from "react";

export interface SearchContextType {
    search: string,
    setSearch: Dispatch<SetStateAction<string>>
    searching: boolean,
    setSearching: Dispatch<SetStateAction<boolean>>
}

const SearchContext = createContext<SearchContextType>({} as SearchContextType)

export default SearchContext