import ExcelUpload from "../base"

const schema = [
    {
        name: "SKU",
        type: "text"
    },
    {
        name: "Unit Cost",
        type: "number"
    },
    {
        name: "Bundle Qty",
        type: "number",
        default: 1,
    },
    {
        name: "Supplier",
        type: "text",
        default: "",
    }
] as const

const excelUpload = new ExcelUpload(schema)

export default excelUpload
