import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import housedAsinsApi from "api/housedAsins"
import BulkUpload from "components/modals/BulkUpload"
import firebase from "config/firebase"
import useFilteringContext from "contexts/Filter/useFilteringContext"
import useUserContext from "contexts/User/useUserContext"
import { ref, uploadBytes } from "firebase/storage"
import useAwaitableModal from "hooks/useAwaitableModal"
import useExcelUpload from "hooks/useExcelUpload"
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { AsinEntryItem } from "types/housedAsins"
import { IApiFilter } from "types/tableFiltering"
import { AsinEntryExcelUpload } from "utils/excelUpload/templates"

const queryFn = async ({pageParam: page, queryKey}: {pageParam: number, queryKey: [string, IApiFilter]}) => {
  return housedAsinsApi.getAsinEntry({page, ...queryKey[1]})
}

const useAsinEntries = () => {
  const {search, ordering, opFilters: filters} = useFilteringContext()
  const path = useLocation().pathname

  const queryKey = useMemo(() => (
    [
    'asin-entries',
    {
      search,
      ordering,
      filters
    }
    ] as [string, IApiFilter]
  ), [search, ordering, filters])

  const {data, refetch, fetchNextPage, isFetching, isRefetching, isFetchingNextPage, hasNextPage: hasMore} = useInfiniteQuery(
    {
      queryKey: queryKey,
      queryFn,
      initialPageParam: 1,
      getNextPageParam: (lastPage) => lastPage.next,
      enabled: path === '/app/housed/asin-entry'
    }
  )
  const loading = isFetching || isRefetching
  const loadingNextPage = isFetchingNextPage

  const asinEntries = useMemo(() => data ? [...data.pages.flatMap((p) => p.items)] : undefined, [data])

  const exportCSV = useCallback(() => {
    housedAsinsApi.exportAsinEntries(queryKey[1])
  }, [queryKey])

  const qc = useQueryClient()

  const setItems: Dispatch<SetStateAction<AsinEntryItem[] | undefined>> = (action) => {
    qc.setQueryData(queryKey, (old: typeof data) => {
      if (!old) return old
      if (action instanceof Function){
        return {
          ...old,
          pages: old.pages.map((p) => ({
            ...p,
            items: action([...p.items])
          }))
        }
      } if (!action) {
        refetch()
      } else {
        return {
          ...old,
          pages: old.pages.map((p, i) => ({
            ...p,
            items: p.items.map((item, j) => action[i * p.items.length + j] || item)
          }))
        }
      }
    })
  };

  const entryExcelUpload = useExcelUpload(AsinEntryExcelUpload)
  const [requestUpload, UploadModal] = useAwaitableModal(BulkUpload, { template: entryExcelUpload, respondWithFile: true })
  const [importing, setImporting] = useState<boolean | 'uploading' | 'processing'>(false)
  const user = useUserContext()
  const navigate = useNavigate()

  const importExcel = useCallback(async () => {
    if (importing) return
    setImporting('uploading')
    return requestUpload()
      .then(async (res) => {
        if (!res?.result) return
        const { result } = res
        const dest = `users/${user?.id}/asinEntries/${Date.now()}.xlsx`
        await uploadBytes(ref(firebase.storage, dest), result as File).then(async () => {
          setImporting('processing')
          return housedAsinsApi.uploadAsinEntries(dest).then(() => {
            navigate(0)
          })
        })
      })
      .finally(() => setImporting(false))
  }, [importing, requestUpload, user?.id])

  return {
    loading,
    loadAsinEntries: refetch,
    loadAsinEntriesNextPage: fetchNextPage,
    hasMore,
    pages: data?.pages.length || 0,
    setAsinEntries: setItems,
    loadingNextPage,
    asinEntries,
    exportCSV,
    importExcel,
    UploadModal,
    importing
  }
}

export default useAsinEntries
