import { FC, useCallback, useEffect, useState } from 'react'
import Dropdown from '../Dropdown'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import Icon from 'assets/icons/iconset'
import Calendar from './Calendar'

const getTodayMidnight = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  return date.toISOString()
}

const prettyDate = (date: string | undefined, fallback: string) => {
  return date ? new Date(date).toLocaleDateString('en-US', { month: 'long', year: 'numeric', day: "numeric" }) : fallback
}

const quickPeriods = [
  {
    name: 'Today',
    period: {
      since: () => getTodayMidnight(),
      until: () => {
        const date = new Date(getTodayMidnight())
        date.setDate(date.getDate() + 1)
        return date.toISOString()
      },
    },
  },
  {
    name: 'Yesterday',
    period: {
      since: () => {
        const date = new Date(getTodayMidnight())
        date.setDate(date.getDate() - 1)
        return date.toISOString()
      },
      until: () => getTodayMidnight(),
    },
  },
  {
    name: 'This week',
    period: {
      since: () => {
        const date = new Date(getTodayMidnight())
        const weekDay = date.getDay()
        date.setDate(date.getDate() - weekDay + 1)
        return date.toISOString()
      },
      until: () => {
        const date = new Date(getTodayMidnight())
        date.setDate(date.getDate() + 1)
        return date.toISOString()
      },
    },
  },
  {
    name: 'Last week',
    period: {
      since: () => {
        const date = new Date(getTodayMidnight())
        const weekDay = date.getDay()
        date.setDate(date.getDate() - weekDay - 7)
        return date.toISOString()
      },
      until: () => {
        const date = new Date(getTodayMidnight())
        const weekDay = date.getDay()
        date.setDate(date.getDate() - weekDay)
        return date.toISOString()
      },
    },
  },
  {
    name: 'This month',
    period: {
      since: () => {
        const date = new Date(getTodayMidnight())
        date.setDate(1)
        return date.toISOString()
      },
      until: () => {
        const date = new Date(getTodayMidnight())
        date.setDate(date.getDate() + 1)
        return date.toISOString()
      },
    },
  },
  {
    name: 'Last month',
    period: {
      since: () => {
        const date = new Date(getTodayMidnight())
        date.setMonth(date.getMonth() - 1, 1)
        return date.toISOString()
      },
      until: () => {
        const date = new Date(getTodayMidnight())
        date.setDate(1)
        return date.toISOString()
      },
    },
  },
  {
    name: 'This year',
    period: {
      since: () => {
        const date = new Date(getTodayMidnight())
        date.setMonth(0, 1)
        return date.toISOString()
      },
      until: () => {
        const date = new Date(getTodayMidnight())
        date.setDate(date.getDate() + 1)
        return date.toISOString()
      },
    },
  },
  {
    name: 'Last year',
    period: {
      since: () => {
        const date = new Date(getTodayMidnight())
        date.setMonth(0, 1)
        date.setFullYear(date.getFullYear() - 2)
        return date.toISOString()
      },
      until: () => {
        const date = new Date(getTodayMidnight())
        date.setMonth(0, 1)
        date.setFullYear(date.getFullYear() - 1)
        return date.toISOString()
      },
    },
  },
  {
    name: 'All time',
    period: {
      since: () => undefined,
      until: () => undefined,
    },
  },
]

const getDate = (iso?: string) => {
  return iso ? new Date(iso).toISOString().split('T')[0] : undefined
}

const PeriodFilter: FC = () => {
  const { period: currentPeriod, setPeriod: setCurrentPeriod } = useFilteringContext()
  const [period, setPeriod] = useState(currentPeriod)

  useEffect(() => {
    setPeriod(currentPeriod)
  }, [currentPeriod])

  const currentSince = currentPeriod.since
  const currentUntil = currentPeriod.until
  const currentSinceDate = getDate(currentSince)
  const currentUntilDate = getDate(currentUntil)
  const periodSet = !!currentSince || !!currentUntil

  const onApply = useCallback(() => {
    document.dispatchEvent(new CustomEvent("hideSelector"))
    setCurrentPeriod(period)
  }, [period, setCurrentPeriod])

  const onClear = useCallback(() => {
    document.dispatchEvent(new CustomEvent("hideSelector"))
    setCurrentPeriod({since: undefined, until: undefined})
  }, [setCurrentPeriod])

  return (
    <Dropdown
      anchorEl={
        <div
          className={
            [
              'flex items-center p-2 gap-2 h-10 rounded-lg hover:text-brand-primary transition-colors bg-white hover:bg-surface-secondary text-sm',
              periodSet ? 'text-brand-primary font-medium' : 'text-text-secondary font-normal',
            ].asClass
          }
        >
          <Icon width={20} height={20} name="Calendar" />
          {
            !currentPeriod?.since && !currentPeriod.until ?
              "All time" :
            [prettyDate(currentPeriod.since, "Beginning"), prettyDate(currentPeriod.until, "Now")].join(" - ")
          }
        </div>
      }
      wrapperClass="mt-2 -translate-x-4"
    >
      <div className="bg-white rounded-xl flex">
        <div className="flex flex-col p-3 gap-1">
          {quickPeriods.map(({ name, period }) => {
            const since = period.since()
            const until = period.until()
            const sinceDate = getDate(since)
            const untilDate = getDate(until)

            const isThis = currentSinceDate === sinceDate && currentUntilDate === untilDate

            return (
              <button
                key={name}
                onClick={() => setCurrentPeriod({ since, until })}
                className={
                  [
                    'hover:bg-surface-secondary hover:text-brand-primary min-w-max text-center px-4 py-2.5 font-medium text-sm rounded-lg',
                    isThis ? 'bg-surface-secondary text-brand-primary' : 'bg-white text-text-secondary',
                  ].asClass
                }
              >
                {name}
              </button>
            )
          })}
        </div>
        <div onClick={(e) => e.stopPropagation()} className="flex flex-col border-l border-l-border-primary">
          <div className="flex grow divide-x divide-x-border-primary">
            <Calendar period={period} setPeriod={setPeriod} />
            <Calendar end period={period} setPeriod={setPeriod} />
          </div>
          <div className="flex items-center justify-end px-6 py-4 gap-3 border-t border-t-border-primary">
            <button onClick={onClear} className="button-tertiary">Clear</button>
            <button onClick={onApply} className="button-primary">Apply</button>
          </div>
        </div>
      </div>
    </Dropdown>
  )
}

export default PeriodFilter
