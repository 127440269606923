import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import AmazonProductImage from 'components/AmazonProductImage'
import TD from 'components/tables/TD'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import Icon from 'assets/icons/iconset'
import { usNumber } from 'utils/formatting'
import TR from 'components/tables/TR'
import { IPrepCost } from 'types/prepCosts'
import { IPurchaseOrderItem } from 'types/purchaseOrders'
import { IPurchaseOrderColumnItem } from 'utils/purchaseOrders'
import purchaseOrdersApi from 'api/purchaseOrders'
import BadgeSelect from 'components/BadgeSelect'
import { useParams } from 'react-router'

interface props {
  raw: IPurchaseOrderItem
  item: IPurchaseOrderColumnItem
  onUpdate: (item: IPurchaseOrderItem) => void
}

const Item: FC<props> = ({ onUpdate, item, raw }) => {
  const {id} = useParams() as {id: string}
  const table = useTableContext()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({
    ...raw,
  } as IPurchaseOrderItem)

  const prepCosts = table.table.extra?.prepCosts || []
  const locked = !!table.table.locked

  useEffect(() => {
    setValues({ ...raw })
  }, [raw])

  const update = (overrides?: Partial<IPurchaseOrderItem>) => {
    if (submitting) return
    if (locked) return
    setSubmitting(true)
    purchaseOrdersApi
      .updatePurchaseOrderItem(id, { ...values, ...overrides })
      .then((res) => {
        onUpdate(res)
      })
      .finally(() => setSubmitting(false))
  }

  const onSubmit = () => {
    update()
    return false
  }

  const onValueChange = (key: string, value: any) => {
    return (submit?: boolean) => {
      setValues((old) => ({ ...old, [key]: value }))
      submit && update({ [key]: value })
    }
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | null>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if (isNaN(value)) return updater(null)
    updater(value)
  }

  const listing = `https://www.amazon.com/dp/${item.asin}`

  const isChecked = !!table.table.select?.selected.includes(item.id)
  const allSelected = !!table.table.select?.allSelected

  return (
    <TR className={['relative', submitting && 'animate-pulse'].asClass} onBlur={onSubmit}>
      {!!table.table.select && (
        <TD onBlur={(e) => e.stopPropagation()} onClick={() => table.table.select?.onSelectClick(item.id)}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </TD>
      )}
      <TD onBlur={(e) => e.stopPropagation()}>
        <AmazonProductImage asin={item?.asin} size={48} imageSize={96} />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={item?.asin} readOnly />
          {!!raw.asin && (
            <a href={listing} target="_blank" rel="noreferrer">
              <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
        </div>
      </TD>
      <TD onBlur={(e) => e.stopPropagation()} className="w-32">
        <input type="text" value={item.title} readOnly />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <input type="text" value={item.vendorIdentifier} readOnly />
      </TD>
      <TD>
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input readOnly={locked} type="number" className="!pl-2.5" value={values.unitCost || ''} onChange={onNumberChange((val) => onValueChange('unitCost', val)())} placeholder="0.00" />
        </div>      
      </TD>
      <TD>
        <input readOnly={locked} className="table-input" type="number" value={values.bundle} onChange={onNumberChange((val) => onValueChange('bundle', val)())} placeholder="0" />
      </TD>
      <TD>
        <input readOnly={locked} className="table-input" type="number" value={values.buyQty} onChange={onNumberChange((val) => onValueChange('buyQty', val)())} placeholder="0" />
      </TD>
      <TD className={item.sellable !== Math.floor(item.sellable) ? "bg-red-400" : ""}>
          {usNumber(item.sellable, 0)}
      </TD>
      <TD>
        <input readOnly={locked} className="table-input" type="number" value={values.casePack} onChange={onNumberChange((val) => onValueChange('casePack', val)())} placeholder="0" />
      </TD>
      <TD className={item.cases !== Math.floor(item.cases) ? "bg-red-400" : ""}>
          {usNumber(item.cases, 0)}
      </TD>
      <TD>
          ${usNumber(Number(item.price), 0)}
      </TD>
      <TD className="!py-1.5 !px-3">
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input readOnly={locked} type="number" className="!pl-2.5" value={values.targetPrice || ''} onChange={onNumberChange((val) => onValueChange('targetPrice', val)())} placeholder="0.00" />
        </div>
      </TD>
      <TD>$ {usNumber(item.cogs)}</TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={values.housedAsin.prepCost || ''}
            badges={prepCosts?.map((p: IPrepCost) => p.name) || []}
            onSelect={() => {}}
            singleColor="blue"
            editable={false}
          />
          <span>${usNumber(prepCosts?.find((p: IPrepCost) => p.name === values.housedAsin.prepCost)?.amount)}</span>
        </div>
      </TD>
      <TD>${usNumber(item.productionCost)}</TD>
      <TD>${usNumber(item.shippingCost)}</TD>
      <TD>${usNumber(item.landedCost)}</TD>
      <TD>${usNumber(item.revenue)}</TD>
      <TD>${usNumber(item.fees)}</TD>
      <TD>${usNumber(item.netProceeds)}</TD>
      <TD className={item.gross > 0 ? "text-green-500" : "text-red-500"}>
        ${usNumber(item.gross)}
      </TD>
      <TD>{usNumber(item.roi)}%</TD>
      <TD>{usNumber(item.margin)}%</TD>
      <TD>{usNumber(item.weight)} lbs</TD>
    </TR>
  )
}

export default Item
