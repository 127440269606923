import purchaseOrdersApi from 'api/purchaseOrders'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import firebase from 'config/firebase'
import useUserContext from 'contexts/User/useUserContext'
import { ref, uploadBytes } from 'firebase/storage'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { ChangeEvent, useCallback, useState } from 'react'

interface params {
    id: string
}

interface res {
    invoice: string
}

const UploadInvoiceModal: AwaitableModal<res | undefined, params> = ({ open, resolve, initialData }) => {
    const user = useUserContext()
  const [submitting, setSubmitting] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const id = initialData?.id

  const submit = useCallback(() => {
    if (submitting) return
    if (!file) return 
    setSubmitting(true);
    const fileName = file.name.includes(' ') ? file.name.replace(/\s/g, '_') : file.name
    const random = Math.random().toString(36).substring(7)
    const name = `${random}-${fileName}`
    const dest = `users/${user?.id}/purchaseOrders/${name}`
    uploadBytes(ref(firebase.storage, dest), file)
        .then(async () => {
            return purchaseOrdersApi.updateInvoice(id, name).then(() => {
                resolve({ invoice: name })
            })
        })
        .finally(() => {
            setSubmitting(false)
        })
  }, [submitting, id, user?.id, file, resolve])

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target?.files?.[0] || null);
  }, [])

  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="flex items-start justify-start p-6 pb-4 gap-2">
            <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
            <div className="flex flex-col items-start gap-1">
              <span className="text-base text-text-primary font-medium">Upload Invoice</span>
              <span className="text-sm text-text-secondary">Upload the corresponding invoice from this supplier.</span>
            </div>
          </div>
          <div className="flex flex-col bg-surface-primary px-4 gap-4">
            <label className="w-full button-primary py-2 cursor-pointer">
                <Icon name="UploadSimple" className="w-6 h-6" />
                Browse Invoice
              <input name="invoice" type="file" onChange={onFileChange} className="border hidden border-border-primary py-2.5 px-2 rounded-lg" />
            </label>
          </div>
          <div className="flex items-center gap-4 p-4 w-full">
            <button className="button-secondary grow" disabled={submitting} onClick={() => resolve()}>
              Cancel
            </button>
            <button className="button-primary grow" disabled={submitting || !file} onClick={() => submit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UploadInvoiceModal
