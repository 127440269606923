import { FC, FormEvent, useEffect, useState } from 'react'
import suppliersApi from 'api/suppliers'
import { handleError } from 'helpers/errors'
import TD from 'components/tables/TD'
import Icon from 'assets/icons/iconset'
import BadgeSelect from 'components/BadgeSelect'
import { SupplierLead } from 'types/supplierLeads'
import AreYouSure from 'components/modals/AreYouSure'
import useAwaitableModal from 'hooks/useAwaitableModal'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import { stateArea, yesNo, contactMethods, statuses, amazonIndustries } from 'constants/badgeSelect'

interface props {
  onUpdate: (lead?: SupplierLead) => void
  lead: SupplierLead
  onRemove: () => void
}

const LeadRow: FC<props> = ({ onUpdate, lead, onRemove }) => {
  const [submitting, setSubmitting] = useState(false)
  const table = useTableContext()
  const [areYouSure, AreYouSureModal] = useAwaitableModal(AreYouSure, {})
  const [values, setValues] = useState({
    ...lead,
  } as SupplierLead)
  const [editingWebsite, setEditingWebsite] = useState(false)

  const onSubmit = (e?: FormEvent) => {
    e?.preventDefault()
    if (locked) return false
    if (submitting) return
    if (!values.name) return handleError('Name is required')
    setSubmitting(true)
    suppliersApi
      .updateLead(lead.id, values)
      .then(() => {
        onUpdate({ ...values })
        setEditingWebsite(false)
      })
      .finally(() => setSubmitting(false))
    return false
  }

  const onValueChange = (key: string, value: any) => {
    return (submit?: boolean) => {
      setValues((old) => ({ ...old, [key]: value }))
      submit && onSubmit()
    }
  }

  const [favicon, setFavicon] = useState<string | null>(null)
  useEffect(() => {
    if (!values.website) return
    try {
      const url = new URL(values.website)
      const favicon = `https://www.google.com/s2/favicons?domain=${url.href}&sz=${32}`
      setFavicon(favicon)
    } catch {
      return
    }
  }, [values.website])

  const onRemoveClick = () => {
    if (submitting) return
    setSubmitting(true)
    areYouSure({
      header: 'Delete Supplier Lead',
      description: 'Are you sure you want to delete Supplier Lead from your account? You can’t restore the removed ones.',
      action: 'Delete',
    })
      .then(() => {
        suppliersApi
          .deleteLead(lead.id)
          .then(() => {
            onRemove()
          })
          .finally(() => setSubmitting(false))
      })
      .finally(() => setSubmitting(false))
  }

  const isChecked = !!table.table.select?.selected.includes(lead.id)
  const allSelected = !!table.table.select?.allSelected
  const locked = !!table.table.locked

  return (
    <>
      <AreYouSureModal />
      <tr className={[submitting && 'animate-pulse'].asClass}>
        {!!table.table.select && (
          <TD onClick={() => table.table.select?.onSelectClick(lead.id)} style={{ pointerEvents: locked ? 'none' : 'auto' }}>
            <Checkbox checked={allSelected ? !isChecked : isChecked} />
          </TD>
        )}
        <TD>
          <div className="flex gap-1 items-center">
            <input className="table-input" type="text" readOnly={locked} value={values.name} onBlur={onSubmit} onChange={(e) => onValueChange('name', e.target.value)()} />
            <button className="rounded-lg hover:bg-surface-secondary group transition-colors hover:text-text-primary" style={{ pointerEvents: locked ? 'none' : 'auto' }} onClick={onRemoveClick}>
              <Icon name="TrashSimple" className="group-hover:opacity-100 opacity-0 transition-all w-5 h-5" />
            </button>
          </div>
        </TD>
        <TD>
          <div className="flex items-center gap-2">
            {(values.website && (!editingWebsite || submitting || locked)) ? (
              <a href={values.website} target="_blank" rel="noreferrer">
                {favicon && <img src={favicon} alt="favicon" className="w-6 h-6 rounded-lg" />}
                {!favicon && <Icon name="Globe" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />}
              </a>
            ) : (
              <input className="table-input" type="text" readOnly={locked} value={values.website} onBlur={onSubmit} onChange={(e) => onValueChange('website', e.target.value)()} />
            )}
            {values.website && !editingWebsite && !locked && (
              <button className="p-0.5 rounded-lg hover:bg-surface-primary group transition-colors hover:text-text-primary" onClick={() => setEditingWebsite(true)}>
                <Icon name="CaretDoubleLeft" className="group-hover:opacity-100 opacity-0 transition-all w-4 h-4" />
              </button>
            )}
          </div>
        </TD>
        <TD>
          <input type="text" className="table-input" value={values.contact} readOnly={locked} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('contact', e.target.value)()} />
        </TD>
        <TD>
          <BadgeSelect badges={amazonIndustries} selected={values.industry} onSelect={(id) => onValueChange('industry', id)(true)} editable={!locked} />
        </TD>
        <TD>
          <BadgeSelect badges={stateArea} selected={values.state} onSelect={(id) => onValueChange('state', id)(true)} editable={!locked} />
        </TD>
        <TD>
          <input type="text" className="table-input" readOnly={locked} value={values.notes} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('notes', e.target.value)()} />
        </TD>
        <TD>
          <BadgeSelect badges={yesNo} selected={values.contactFirst} onSelect={(id) => onValueChange('contactFirst', id)(true)} editable={!locked} />
        </TD>
        <TD>
          <input type="date" className="table-input" readOnly={locked} value={values.dateOfContact || ''} onBlur={onSubmit} onChange={(e) => onValueChange('dateOfContact', e.target.value)()} />
        </TD>
        <TD>
          <BadgeSelect badges={contactMethods} selected={values.contactMethod} onSelect={(id) => onValueChange('contactMethod', id)(true)} editable={!locked} />
        </TD>
        <TD>
          <input type="text" className="table-input" readOnly={locked} value={values.conversation || '---'} onBlur={onSubmit} onChange={(e) => onValueChange('conversation', e.target.value)()} />
        </TD>
        <TD>
          <BadgeSelect badges={statuses} selected={values.outcome} onSelect={(id) => onValueChange('outcome', id)(true)} editable={!locked} />
        </TD>
        <TD>
          <input type="date" className="table-input" readOnly={locked} value={values.dateOfNextContact || ''} onBlur={onSubmit} onChange={(e) => onValueChange('dateOfNextContact', e.target.value)()} />
        </TD>
      </tr>
    </>
  )
}

export default LeadRow
