import Icon from 'assets/icons/iconset'
import Loader from 'components/loaders/Loader'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { FC, ReactNode } from 'react'

interface props {
  topElement?: ReactNode
  searchOverride?: ReactNode
  searchPlaceholder?: string
  afterSearch?: ReactNode
  underSearch?: ReactNode
  buttons?: ReactNode
  children: ReactNode
  wrapperClass?: string
}

const ContentLayout: FC<props> = ({ children, topElement, afterSearch, searchOverride, buttons, searchPlaceholder, underSearch, wrapperClass = "" }) => {
  const { search, setSearch, searching } = useFilteringContext()
  return (
    <div className="flex flex-col h-full bg-surface-light w-full p-4 overflow-hidden gap-3">
      {topElement}
      <div className="flex justify-between gap-4 items-center min-h-10">
        <div className="flex flex-col gap-3">
        <div className="flex items-center gap-4">
          {searchOverride ? (
            searchOverride
          ) : (
            <div className="input-box relative">
              <input type="text" value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder={searchPlaceholder || 'Search'} className="!px-8" />
              <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
                <Icon name="MagnifyingGlass" className="w-5 h-5" />
                {searching && <Loader size={20} />}
              </div>
            </div>
          )}
          {afterSearch}
        </div>
        {underSearch}
        </div>
        <div>{buttons}</div>
      </div>
      <div
       className={[
        "flex w-full grow overflow-hidden rounded-lg bg-surface-primary border border-border-primary",
        wrapperClass
       ].asClass}
       >
        {children}
      </div>
    </div>
  )
}

export default ContentLayout
