import { FC, ReactNode, useCallback, useMemo } from 'react'
import { IColumnDefinition } from 'types/table'
import TH from './TH'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import Loader from 'components/loaders/Loader'
import TableSpanner from 'components/TableSpanner'
import Empty from 'components/Empty'
import TableProvider from 'contexts/Table/TableProvider'
import TableLoader from './Loader'
import Checkbox from 'components/Checkbox'
import useSelect from 'hooks/useSelect'

export interface TableProps<T extends Record<string, any> = any> {
  items?: T[]
  disabled?: boolean
  select?: ReturnType<typeof useSelect>
  noSelectAll?: boolean
  renderItem: (item: T, index: number) => ReactNode
  onBottom?: () => void
  columns: IColumnDefinition<T>[]
  locked?: boolean
  loading?: boolean
  loadingNext?: boolean
  loaderOverride?: ReactNode
  extra?: any
}

const Table: FC<TableProps> = (props) => {
  const { items, select, columns, loading, loadingNext, loaderOverride, renderItem, onBottom, disabled, noSelectAll } = props
  const withSelection = !!select
  const onBottomCb = useCallback(() => {
    if (onBottom) onBottom()
  }, [onBottom])
  const scrollRef = useBottomScrollListener<HTMLDivElement>(onBottomCb, { triggerOnNoScroll: false, offset: 1000, debounce: 500, debounceOptions: { leading: true } })
  const columnCount = useMemo(() => columns.length + (withSelection ? 1 : 0), [columns, withSelection])

  const loader = useMemo(() => {
    if (loading || loadingNext) {
      if (loaderOverride) {
        return (
          <TableSpanner cols={columnCount} rows={4}>
            {loaderOverride}
          </TableSpanner>
        )
      }
      return <TableLoader />
    }
    return null
  }, [loaderOverride, loading, loadingNext, columnCount])

  const checkState = select?.allSelected ? (select.selected.length ? 'semi' : 'checked') : 'unchecked'

  return (
    <TableProvider table={props}>
      <div className="w-full h-full overflow-hidden relative">
        {items && loading && (
          <div className="flex flex-col w-full items-center justify-center absolute inset-0 z-[100] backdrop-blur-[256px]">
            <Loader />
          </div>
        )}
        {items && !items?.length && (
          <div className="flex flex-col w-full items-center justify-center absolute inset-0 z-[100] backdrop-blur-[256px]">
            <Empty text="No Data" />
          </div>
        )}
        <div className="w-full h-full overflow-auto" ref={scrollRef}>
          <table className={['h-full w-full', disabled && 'grayscale pointer-events-none cursor-default'].asClass}>
            <thead className="sticky -top-px z-[100]">
              <tr>
                {withSelection &&
                  (noSelectAll ? (
                    <TH header={{ title: '', field: 'selected', filters: 'string', pin: true }} />
                  ) : (
                    <TH header={{ title: '', field: 'selected', filters: 'string', pin: true }} onClick={select.onSelectAllClick}>
                      <Checkbox checked={checkState !== 'unchecked'} semichecked={checkState === 'semi'} />
                    </TH>
                  ))}
                {(!!items || !items) && columns.map((header) => <TH key={header.field.toString()} header={header} />)}
              </tr>
            </thead>
            <tbody className="overflow-y-auto">
              {items?.map(renderItem)}
              {items && <tr />}
              {(loadingNext || (!items && loading)) && loader}
            </tbody>
          </table>
        </div>
      </div>
    </TableProvider>
  )
}

export default Table
