import { IColumnDefinition } from 'types/table'

const columnDef: IColumnDefinition[] = [
  {
    field: 'image',
    title: 'Image',
    pin: true,
  },
  {
    field: 'asin',
    title: 'ASIN',
    filters: 'string',
    pin: true,
  },
  {
    field: 'title',
    title: 'Title',
    filters: 'string',
  },
  {
    field: 'vendorIdentifier',
    title: 'UPC / Vendor SKU',
    filters: 'string',
  },
  {
    field: 'unitCost',
    title: 'Unit Cost',
    filters: 'number',
  },
  {
    field: 'bundle',
    title: 'Bundle Qty.',
    filters: 'number',
  },
  {
    field: 'buyQty',
    title: 'Buy Qty.',
    filters: 'number',
  },
  {
    field: 'sellable',
    title: 'Sellable Qty.',
    filters: 'number',
  },
  {
    field: 'casePack',
    title: 'Case Pack',
    filters: 'number',
  },
  {
    field: 'cases',
    title: 'Case Order',
    filters: 'number',
  },
  {
    field: 'price',
    title: 'Current Sold Price',
    filters: 'number',
  },
  {
    field: 'targetPrice',
    title: 'Target Sold Price',
    filters: 'number',
  },
  {
    field: 'cogs',
    title: 'Total COGS',
    filters: 'number',
  },
  {
    field: 'prepCost',
    title: 'Prep Cost',
    filters: 'number',
  },
  {
    field: 'productionCost',
    title: 'Production Cost',
    filters: 'number',
  },
  {
    field: 'shippingCost',
    title: 'Shipping Cost',
    filters: 'number',
  },
  {
    field: 'landedCost',
    title: 'Landed Cost',
    filters: 'number',
  },
  {
    field: 'revenue',
    title: 'Total Revenue',
    filters: 'number',
  },
  {
    field: 'fees',
    title: 'Total Fees',
    filters: 'number',
  },
  {
    field: 'net',
    title: 'Net Proceeds',
    filters: 'number',
  },
  {
    field: 'gross',
    title: 'Gross Profit',
    filters: 'number',
  },
  {
    field: 'roi',
    title: 'ROI',
    filters: 'number',
  },
  {
    field: 'margin',
    title: 'Margin',
    filters: 'number',
  },
  {
    field: 'weight',
    title: 'Total Weight',
    filters: 'number',
  },
]

export default columnDef

