import Icon from 'assets/icons/iconset'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import ColFilterSelect from './ColFilterSelect'
import { IColumnDefinition } from 'types/table'
import useTableContext from 'contexts/Table/useTableContext'

interface props {
  header: IColumnDefinition
  onClick?: () => void
}

const TH: FC<PropsWithChildren<props>> = ({ header, children, onClick }) => {
  const field = header.queryField || header.field
  const divider = field === '___divider___'
  const filterable = !!field && !!header.filters
  const sortable = (filterable && ['string', 'number', 'date'].includes(header.filters as string)) || header.queryField
  const {
    table: { columnOffsets },
  } = useTableContext()
  const [index, setIndex] = useState<number>(0)

  const { ordering, setOrdering, opFilters } = useFilteringContext()

  const isCol = ordering?.by === field
  const isDesc = ordering?.direction === -1

  const onOrderingClick = () => {
    if (isCol) {
      setOrdering((old) => old && { by: field, direction: (old.direction * -1) as -1 | 1 })
    } else {
      setOrdering({ by: field, direction: -1 })
    }
  }

  const isPinned = !!header?.pin

  const thRef = useRef<HTMLTableHeaderCellElement>(null)

  useEffect(() => {
    if (thRef.current) {
      const index = thRef.current.cellIndex
      setIndex(index)
    }
  }, [thRef])

  const activeFilter = !!opFilters?.[field]

  return (
    <th
      className={[!divider ? 'bg-surface-secondary' : "bg-border-primary", !!onClick && "cursor-pointer"].asClass}
      ref={thRef}
      onClick={onClick}
      data-pinned={isPinned}
      style={isPinned ? { left: columnOffsets[index] } : undefined}
    >
      {children ? (
        children
      ) : (
        <div className="flex items-center gap-4 min-w-max w-full">
          <span className="min-w-max grow">{header.title}</span>
          {sortable && (
            <button className={['p-0.5 rounded-lg hover:bg-surface-primary group transition-colors hover:text-text-primary', ordering?.by === field && 'bg-surface-primary'].asClass} onClick={onOrderingClick}>
              {ordering?.by !== field && <Icon name="CaretRight" className="rotate-90 transition-all" />}
              {ordering?.by === field && <Icon name="CaretRight" className={[isDesc ? 'rotate-90 transition-all' : '-rotate-90 transition-all'].asClass} />}
            </button>
          )}
          {filterable && (
            <ColFilterSelect
              title={header.title}
              field={field}
              anchor={
                <div className={['p-0.5 rounded-lg cursor-pointer hover:bg-surface-primary transition-colors hover:text-text-primary', activeFilter ? 'text-brand-primary' : 'text-text-primary'].asClass}>
                  <Icon name="Funnel" />
                </div>
              }
              dbType={header.filters as any}
              source={header.source}
            />
          )}
        </div>
      )}
    </th>
  )
}

export default TH
