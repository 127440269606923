import { SupplierLead } from "types/supplierLeads";
import { IColumnDefinition } from "types/table";

const columnDef: IColumnDefinition<SupplierLead>[] = [
    {
        field: "name",
        title: "Supplier Name",
        pin: true,
        filters: "string",
    },
    {
        field: "website",
        title: "Website",
        filters: "string",
        pin: true,
    },
    {
        field: "contact",
        title: "Contact Number / Email",
        filters: "string",
    },
    {
        field: "industry",
        title: "Industry",
        filters: "string",
    },
    {
        field: "state",
        title: "State / Area",
        filters: "string",
    },
    {
        field: "notes",
        title: "Notes",
        filters: "string",
    },
    {
        field: "contactFirst",
        title: "Contact First",
        filters: "string",
    },
    {
        field: "dateOfContact",
        title: "Date Of Contact",
        filters: "date",
    },
    {
        field: "contactMethod",
        title: "Contact Method",
        filters: "string",
    },
    {
        field: "conversation",
        title: "Details About Convo",
        filters: "string",
    },
    {
        field: "outcome",
        title: "Opened / Declined",
        filters: "string",
    },
    {
        field: "dateOfNextContact",
        title: "Date Of Next Contact",
        filters: "date",
    }
]

export default columnDef;