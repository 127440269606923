import { Dispatch, SetStateAction, createContext } from "react";
import { SupplierLead } from "types/supplierLeads";

export interface SupplierLeadsContextType {
    leads: SupplierLead[] | undefined
    setLeads: Dispatch<SetStateAction<SupplierLead[] | undefined>>
    loading: boolean
    loadingNextPage: boolean
    loadNextPage: () => void
    hasMore: boolean
}

const SuppliersLeadsContext = createContext<SupplierLeadsContextType>({} as SupplierLeadsContextType)

export default SuppliersLeadsContext