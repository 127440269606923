import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'

interface params {
    url: string
}

const PDFPreviewModal: AwaitableModal<undefined, params> = ({ open, resolve, initialData }) => {
  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100vh-4rem)] h-[48rem] min-w-[32rem] overflow-hidden z-[100000000000]">
        <div className="flex flex-col bg-surface-primary rounded-t-xl h-full">
          <iframe src={initialData?.url + "#toolbar=0&navpanes=0"} className="w-full h-full" />
          <div className="flex items-center justify-center gap-4 p-4 w-full border-t border-t-border-primary">
            <button className="button-secondary grow" onClick={() => resolve()}>
              Done
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PDFPreviewModal
