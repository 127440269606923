import { AxiosInstance } from 'axios'
import axios from './axios'
import { IShippingCost } from 'types/housedAsins'
import { IPurchaseOrder, IPurchaseOrderItem } from 'types/purchaseOrders'

const possibleStatuses = ['open', 'closed'] as const
const notStatuses = ['!open', '!closed'] as const

type StatusQuery = typeof possibleStatuses[number] | typeof notStatuses[number]

class PurchaseOrdersApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  getPurchaseOrders = async ({ page = 1, status }: { page?: number; status: StatusQuery }) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    sp.append('status', status)
    return this.axios.get(`/purchaseOrders?${sp.toString()}`).then((res) => (res.data as { next: number | null; purchaseOrders: IPurchaseOrder[], shippingCostDef: IShippingCost }))
  }

  getPurchaseOrder = async (id: string) => {
    return this.axios.get(`/purchaseOrders/${id}`).then((res) => res.data as {
      purchaseOrder: IPurchaseOrder,
      shippingCostDef: IShippingCost,
    })
  }

  updatePurchaseOrderItem = async (id: string, data: Partial<IPurchaseOrderItem>) => {
    return this.axios.patch(`/purchaseOrders/${id}/items/${data.id}`, data).then((res) => res.data as IPurchaseOrderItem)
  }

  async addItemsToPurchaseOrder(id: string, {ids, except}: {ids?: string[], except?: string[]}) {
    return axios.post(`/purchaseOrders/${id}/items`, {ids, except})
  }

  async deletePurchaseOrderItems(id: string, {ids, except}: {ids?: string[], except?: string[]}) {
    return axios.post(`/purchaseOrders/${id}/items/delete`, {ids, except}).then((res) => {
      const remainingItems = res.data.remaining as number;
      if (!remainingItems) {
        window.location.href = '/app/purchase-orders/open'
        throw new Error()
      }
    })
  }

  async createBackOrder(id: string, {ids, except}: {ids?: string[], except?: string[]}) {
    return axios.post(`/purchaseOrders/${id}/backOrder`, {ids, except}).then((res) => {
      const remainingItems = res.data.remaining as number;
      if (!remainingItems) {
        window.location.href = '/app/purchase-orders/open'
        throw new Error()
      }
    })
  }

  async inboundPurchaseOrder(id: string, data: {ids?: string[], except?: string[], shippingCost: number, eta: string}) {
    return axios.post(`/purchaseOrders/${id}/inbound`, data).then((res) => res.data.shipment as string)
  }

  async updateInvoice(id: string, invoice?: string) {
    return axios.patch(`/purchaseOrders/${id}/invoice`, {invoice})
  }
}

const purchaseOrdersApi = new PurchaseOrdersApi()

export default purchaseOrdersApi
