import { FC } from "react";
import Loader from "./Loader";

interface props {
  size?: number
  colorClass?: string
}

const FullLoader: FC<props> = (props) => {
    return (
      <div className="w-full h-full grow flex items-center justify-center">
        <Loader {...props} />
      </div>
    )
}

export default FullLoader
