import { FC } from 'react'
import { createPortal } from 'react-dom'

interface props {
  open: boolean
  close: (...args: any) => void
}

const Modal: FC<React.PropsWithChildren<props>> = ({ children, open, close }) => {
  if (!open) return null
  return createPortal(
    <div
      className="fixed inset-0 bg-[rgba(0,0,0,0.48)] h-full overflow-hidden p-8 flex items-center justify-center z-[100000000] animate-fade-in"
      onClick={(e) => {
        e.stopPropagation()
        close()
      }}
    >
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </div>,
    document.body
  )
}

export default Modal
