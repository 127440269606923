import { AsinEntryItem } from 'types/housedAsins'
import { IColumnDefinition } from 'types/table'

const columnDef: IColumnDefinition<AsinEntryItem>[] = [
  {
    field: "amzLink",
    title: "AMZ Link",
  },
  {
    field: 'supplier',
    title: 'Supplier',
  },
  {
    field: 'currentCost',
    title: 'Unit Cost',
    filters: 'number',
  },
  {
    field: 'upc',
    title: 'UPC',
    filters: 'string',
  },
  {
    field: 'vendorSKU',
    title: 'Vendor SKU',
    filters: 'string',
  },
  {
    field: 'bundleQty',
    title: 'Bundle Qty',
    filters: 'number',
  },
  {
    field: 'caseQty',
    title: 'Case Qty',
    filters: 'number',
  },
  {
    field: 'prepCost',
    title: 'Prep Type',
  },
  {
    field: 'buyer',
    title: 'Buyer',
    filters: 'string',
  },
  {
    field: "created_at",
    title: "Added At",
      filters: "date",
  },
]

export default columnDef
