import { FormEvent, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import firebase from '../../../config/firebase'
import { confirmPasswordReset, sendPasswordResetEmail, verifyPasswordResetCode } from 'firebase/auth'
import { FirebaseError } from 'firebase/app'
import Loader from 'components/loaders/Loader'

const PasswordResetPage = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [sp] = useSearchParams()
  const oobCode = sp.get('oobCode')
  const isRequest = !oobCode
  const [linkInvalid, setLinkInvalid] = useState<boolean>()

  useEffect(() => {
    if (!isRequest) {
      verifyPasswordResetCode(firebase.auth, oobCode)
        .then(() => setLinkInvalid(false))
        .catch(() => setLinkInvalid(true))
    }
  }, [isRequest, oobCode])

  const [error, setError] = useState<string>()

  const emailValid = email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setSubmitting(true)
    if (isRequest) {
      if (!emailValid) return setError('Please enter a valid email address')
      sendPasswordResetEmail(firebase.auth, email)
        .then(() => setSubmitted(true))
        .catch((err: FirebaseError) => {
          switch (err.code) {
            case 'auth/invalid-email':
              setError('Please enter a valid email address')
              break
            case 'auth/user-disabled':
              setError('This account has been disabled')
              break
            case 'auth/user-not-found':
              setError('This account does not exist')
              break
            case 'auth/wrong-password':
              setError('Incorrect password')
              break
            default:
              setError('An unknown error occurred')
          }
          setSubmitting(false)
        })
    } else {
      if (!password) return setError('Please enter a password')
      setError(undefined)
      setSubmitting(true)
      confirmPasswordReset(firebase.auth, oobCode, password)
        .then(() => setSubmitted(true))
        .catch((err: FirebaseError) => {
          switch (err.code) {
            case 'auth/expired-action-code':
              setError('This link has expired')
              break
            case 'auth/invalid-action-code':
              setError('This link is invalid')
              break
            case 'auth/user-disabled':
              setError('This account has been disabled')
              break
            case 'auth/user-not-found':
              setError('This account does not exist')
              break
            case 'auth/wrong-password':
              setError('Incorrect password')
              break
            default:
              setError('An unknown error occurred')
          }
          setSubmitting(false)
        })
    }

    return false
  }

  return (
    <div className="flex flex-col mx-32 p-8 gap-8 rounded-lg bg-white-50">
      <h1 className="text-orange-500">Reset Password</h1>
      <form className="flex flex-col w-full gap-8" name="login" onSubmit={handleSubmit}>
        {isRequest ? (
          submitted ? (
            <p className="text-xl">Email successfully sent, check inbox</p>
          ) : (
            <input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              className="grow p-5 default rounded-md bg-white-50 border border-white-100 text-white text-base placeholder:text-white"
            />
          )
        ) : linkInvalid === undefined ? (
          <Loader />
        ) : linkInvalid ? (
          <p className="text-xl text-orange-500">Link invalid</p>
        ) : submitted ? (
          <p className="text-xl text-orange-500">
            Password successfully changed.{' '}
            <Link className="text-orange-500" to="/auth" replace>
              Login
            </Link>
          </p>
        ) : (
          <input
            placeholder="New Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            className="grow p-5 default rounded-md bg-white-50 border border-white-100 text-white text-base placeholder:text-white"
          />
        )}
        {((!submitted && isRequest) || (!isRequest && linkInvalid === false && !submitted)) && (
          <button className="mt-4 button-blue" disabled={submitting} children={submitting ? '...' : isRequest ? 'Request Reset Link' : 'Change Password'} onSubmit={handleSubmit} />
        )}
      </form>
      {error && <p className="text-rose-500 text-center">{error}</p>}
    </div>
  )
}

export default PasswordResetPage
