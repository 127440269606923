import { useQuery, useQueryClient } from "@tanstack/react-query"
import suppliersApi from "api/suppliers"
import { Dispatch, SetStateAction, useCallback } from "react"

const queryFn = async () => {
    return suppliersApi.getNames()
}

const useSupplierNames = () => {
    const {data: supplierNames} = useQuery({
        queryKey: ['supplierNames'],
        queryFn,
    })

    const qc = useQueryClient();

    const setSupplierNames: Dispatch<SetStateAction<string[] | undefined>> = useCallback((updater) => {
        qc.setQueryData<string[] | undefined>(['supplierNames'], updater)
    }, [qc])

    return {
        supplierNames,
        setSupplierNames,
    }
}

export default useSupplierNames