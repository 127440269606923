import ExcelUpload from "../base"

const schema = [
    {
        name: "DATE",
        type: "text"
    },
    {
        name: "TITLE",
        type: "text"
    },
    {
        name: "CATEGORY",
        type: "text"
    },
    {
        name: "AMOUNT",
        type: "number"
    },
    {
        name: "FREQUENCY",
        type: "text"
    },
] as const

const excelUpload = new ExcelUpload(schema)

export default excelUpload
