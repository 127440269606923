import expensesApi from 'api/expenses'
import BadgeSelect from 'components/BadgeSelect'
import Checkbox from 'components/Checkbox'
import TD from 'components/tables/TD'
import { expenseFrequencies } from 'constants/badgeSelect'
import useTableContext from 'contexts/Table/useTableContext'
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { IExpense } from 'types/expenses'

interface Props {
  item: IExpense
  onUpdate: (expenses: IExpense[]) => void
}

const Expense: FC<Props> = ({ item, onUpdate }) => {
  const [values, setValues] = useState(item)
  const [updating, setUpdating] = useState(false)
  const table = useTableContext()

  const expenseCategories = table.table.extra.availableCategories || []

  useEffect(() => {
    setValues(item)
  }, [item])

  const update = (overrides?: Partial<IExpense>) => {
    if (updating) return
    setUpdating(true)
    expensesApi
      .updateExpense(item.id, { ...values, ...overrides })
      .then((created) => {
        setValues(created.expenses.find((e) => e.id === item.id) || values)
        onUpdate(created.expenses)
      })
      .finally(() => setUpdating(false))
  }

  const onValueChange = <T extends keyof IExpense = keyof IExpense>(key: T, value: IExpense[T]) => {
    return (submit = false) => {
      setValues((old) => ({ ...old, [key]: value }))
      submit && update({[key]: value})
    }
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | undefined>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if (isNaN(value)) return updater(undefined)
    updater(value)
  }

  const stopped = !!item.stoppedAt
  const replaced = !!item.replacedBy && !stopped
  const textColor = stopped ? "#F04438" : "#0F56CA"
  const backgroundColor = stopped ? "#FEF3F2" : "#EAF2FF"

  const isChecked = !!table.table.select?.selected.includes(item.id)
  const allSelected = !!table.table.select?.allSelected

  return (
    <tr className={[updating && 'grayscale'].asClass} onBlur={() => update()}>
      {!!table.table.select && (
        <TD onClick={() => table.table.select?.onSelectClick(item.id)}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </TD>
      )}
      <TD className="max-w-[20rem] truncate">
        <input type="date" value={values.dateOfExpense} onChange={(e) => onValueChange('dateOfExpense', e.currentTarget.value)(true)} />
      </TD>
      <TD className="max-w-[20rem] truncate">
        <div className='flex items-center gap-2'>
          <input type="text" value={values.title} onBlur={() => update()} onChange={(e) => onValueChange('title', e.currentTarget.title)} />
        {(stopped || replaced) && (<span
          style={{ backgroundColor, color: textColor }}
          className="px-2 py-1 rounded gap-1 text-xs font-medium flex items-center transition-colors cursor-default"
        >
          {stopped ? 'Stopped' : replaced ? 'Replaced' : ''}
        </span>)}
        </div>
      </TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <BadgeSelect badges={expenseCategories} selected={values.category} onSelect={(id) => onValueChange('category', id)(true)} editable />
      </TD>
      <TD>
        <div className="relative">
          <span>$</span>
          <input type="number" className="pl-1" value={values.amount} onChange={onNumberChange((val) => onValueChange('amount', val as number)())} />
        </div>
      </TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <BadgeSelect badges={expenseFrequencies} selected={values.frequency} onSelect={(id) => onValueChange('frequency', id as any)(true)} editable />
      </TD>
    </tr>
  )
}

export default Expense
