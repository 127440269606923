import { useState, FC, PropsWithChildren, useCallback, useEffect } from "react"
import useOperatorFilter from "hooks/useOperatorFilter"
import { IOrdering, IPeriodFilter } from "types/tableFiltering"
import FilteringContext from "./filteringContext"
import { useLocation } from "react-router"

const FilteringProvider: FC<PropsWithChildren> = ({ children }) => {
  const {filter, setFilter} = useOperatorFilter()
  const [search, setSearch] = useState<string>('')
  const [searching, setSearching] = useState<boolean>(false)
  const [ordering, setOrdering] = useState<IOrdering | undefined>(undefined)
  const [period, setPeriod] = useState<IPeriodFilter>({since: undefined, until: undefined})

  const resetFilters = useCallback(() => {
    setFilter({})
    setSearch('')
    setOrdering(undefined)
    setSearching(false)
    setPeriod({since: undefined, until: undefined})
  }, [setFilter])

  const location = useLocation()

  useEffect(() => {
    resetFilters()
  }, [location.pathname, resetFilters])

  return (
    <FilteringContext.Provider value={{ search, setSearch, opFilters: filter, setOpFilters: setFilter, ordering, setOrdering, searching, setSearching, period, setPeriod}}>
      {children}
    </FilteringContext.Provider>
  )
}

export default FilteringProvider