import { VectorLogo } from "assets";
import { FC } from "react";

interface props {
    className?: string
}

const Logo: FC<props> = ({ className }) => {
  
    return (
      <div className={["flex justify-center", className].join(" ")}>
        <div className="flex flex-col max-w-min">
          <VectorLogo className="m-2" />
        </div>
      </div>
    )
}

export default Logo;
