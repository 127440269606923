import { useState, useEffect, PropsWithChildren, forwardRef, useImperativeHandle, Dispatch, SetStateAction } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'
import UserContext from './userContext'
import useAuthContext from '../Auth/useAuthContext'
import firebase from '../../config/firebase'
import api from 'api'
import { useLocation } from 'react-router-dom'
import FullscreenError from 'components/FullScreenError'
import { IUser } from 'types/user'

export interface IConnectedClient {
  id: string
  client: string
}

const UserProvider = forwardRef<{ setUser: Dispatch<SetStateAction<IUser | null>> }, PropsWithChildren>(({ children }, forwarded) => {
  const auth = useAuthContext()
  const [user, setUser] = useState<IUser | null>(null)
  const location = useLocation()
  const [error, setError] = useState<Error>()

  useEffect(() => {
    if (auth?.uid) {
      if (!user) {
        api
          .getUser()
          .then(setUser)
          .catch(() => setError(new Error("Couldn't load user data, try again")))
        if (!sessionStorage.getItem('act-as')) {
          const unsubscribe = onSnapshot(doc(firebase.db, 'users', auth.uid), (doc) => {
            setUser((old) => old && { ...old, ...doc.data() })
          })

          return unsubscribe
        }
      }
    } else {
      sessionStorage.clear()
      setUser(null)
    }
  }, [auth?.uid, user])

  useImperativeHandle(forwarded, () => ({
    setUser,
  }))

  if (error && location.pathname.startsWith('/app')) return <FullscreenError error={error} />

  return <UserContext.Provider value={user ? { ...user } : null}>{children}</UserContext.Provider>
})

export default UserProvider
