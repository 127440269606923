import { useEffect } from 'react'

const useNoScrollNumberInput = () => {
  useEffect(() => {
    const onWheel = () => {
      // @ts-ignore
      if (document.activeElement?.type === 'number') {
        // @ts-ignore
        document.activeElement.blur()
      }
    }

    document.addEventListener('wheel', onWheel)

    return () => {
      document.removeEventListener('wheel', onWheel)
    }
  }, [])
}

export default useNoScrollNumberInput
