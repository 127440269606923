import { AxiosInstance } from 'axios'
import axios from './axios'
import { AsinEntryItem, AsinEntryItemInput, HousedAsin, HousedAsinInput, IShippingCost, MasterUPCCatalogItem } from 'types/housedAsins'
import firebase from 'config/firebase'
import { IApiFilter } from 'types/tableFiltering'
import { handleError } from 'helpers/errors'

class HousedAsinsApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  getMasterCatalog = async ({ page = 1, search, filters, ordering }: { page?: number; } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/housed/master-catalog?${sp.toString()}`).then((res) => ({ ...res.data, search } as { pages: number; items: MasterUPCCatalogItem[]; search?: string, next: number | null }))
  }

  exportMasterCatalog = async ({ catalog, search, filters, ordering }: { catalog?: string; } & IApiFilter) => {
    const sp = new URLSearchParams()
    if (catalog) sp.append('catalog', catalog)
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    const token = await firebase.auth.currentUser?.getIdToken()
    sp.append('token', token || '')
    window.open(`${this.axios.defaults.baseURL}/housed/master-catalog/bulk?${sp.toString()}`, '_blank')
  }

  exportAsinEntries = async ({ page = 1, search, filters, ordering }: { page?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    if (search) sp.append('search', search)
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    const token = await firebase.auth.currentUser?.getIdToken()
    sp.append('token', token || '')
    window.open(`${this.axios.defaults.baseURL}/housed/asin-entry/bulk?${sp.toString()}`, '_blank')
  }

  updateMasterCatalog = async (catalogUri: string, name: string) => {
    return this.axios.post(`/housed/master-catalog`, { catalogUri, name })
  }

  getUploadedCatalogs = async () => {
    return this.axios.get(`/housed/master-catalog/catalogs`).then((res) => res.data as string[])
  }

  deleteUploadedCatalog = async (catalog: string) => {
    return this.axios.delete(`/housed/master-catalog`, {params: {name: catalog}})
  }

  querySupplierNames = async (search: string) => {
    const sp = new URLSearchParams()
    sp.append('search', search)
    return this.axios.get(`/housed/master-catalog/suppliers?${sp.toString()}`).then((res) => ({names: res.data as string[], search}))
  }

  getAsinEntry = async ({ page = 1, search, filters, ordering }: { page?: number; } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/housed/asin-entry?${sp.toString()}`).then((res) => ({ ...res.data, search } as { pages: number; items: AsinEntryItem[]; search?: string, next: number | null }))
  }

  addAsinEntry = async (asinEntry: AsinEntryItemInput) => {
    return this.axios.post(`/housed/asin-entry`, asinEntry).then((res) => res.data as AsinEntryItem)
  }

  updateAsinEntry = async (id: string, asinEntry: AsinEntryItemInput) => {
    return this.axios.put(`/housed/asin-entry/${id}`, asinEntry).then((res) => res.data as AsinEntryItem)
  }

  deleteAsinEntry = async (items: string[]) => {
    const sp = new URLSearchParams()
    const joined = items.join(",")
    sp.append('items', joined)
    return this.axios.delete(`/housed/asin-entry?`+ sp.toString())
  }

  deleteAsinEntriesBulk = async ({ids, except, filters, search, ordering}: {ids?: string[], except?: string[]} & IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.post(`/housed/asin-entry/delete?${sp.toString()}`, {ids, except})
  }

  uploadAsinEntries = async (uri: string) => {
    return this.axios.post(`/housed/asin-entry/bulk`, {uri})
  }

  asinEntriesPushToHoused = async ({ids, except, filters, search, ordering}: {ids?: string[], except?: string[]} & IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.post(`/housed/asin-entry/to-housed?${sp.toString()}`, {ids, except}).then(
      res => {
        if (res.data?.missingAsins?.length) {
          handleError(`These ASINs data: ${res.data.missingAsins.join(", ")} couln't be fetched, so they were not pushed to housed`)
        }
      }
    )
  }

  getAvailableBuyers = async () => {
    return this.axios.get(`/housed/asin-entry/buyers`).then((res) => res.data as string[])
  }

  queryDistributorNames = async (search: string) => {
    const sp = new URLSearchParams()
    sp.append('search', search)
    return this.axios.get(`/housed/asin-entry/distributors?${sp.toString()}`).then((res) => ({names: res.data as string[], search}))
  }

  getHoused = async ({ page = 1, search, ordering, filters }: { page?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/housed/housed?${sp.toString()}`).then((res) => ({ ...res.data, search } as { items: HousedAsin[]; search?: string, next: number | null }))
  }

  addHoused = async (housed: HousedAsinInput) => {
    return this.axios.post(`/housed/housed`, housed).then((res) => res.data as HousedAsin)
  }

  updateHoused = async (id: string, housed: HousedAsinInput) => {
    return this.axios.put(`/housed/housed/${id}`, housed).then((res) => res.data as HousedAsin)
  }

  deleteHoused = async (id: string) => {
    return this.axios.delete(`/housed/housed/${id}`)
  }

  deleteHousedBulk = async ({ids, except, filters, search, ordering}: {ids?: string[], except?: string[]} & IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.post(`/housed/housed/delete?${sp.toString()}`, {ids, except})
  }

  uploadHoused = async (uri: string) => {
    return this.axios.post(`/housed/housed/bulk`, {uri})
  }

  exportHoused = async ({ search, filters, ordering }: IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    const token = await firebase.auth.currentUser?.getIdToken()
    sp.append('token', token || '')
    window.open(`${this.axios.defaults.baseURL}/housed/housed/bulk?${sp.toString()}`, '_blank')
  }

  queryHousedSupplierNames = async (search: string) => {
    const sp = new URLSearchParams()
    sp.append('search', search)
    return this.axios.get(`/housed/housed/suppliers?${sp.toString()}`).then((res) => ({names: res.data as string[], search}))
  }

  getShippingCost = async () => {
    return this.axios.get(`/housed/housed/shipping`).then((res) => res.data as IShippingCost)
  }

  updateShippingCost = async (shippingCost: IShippingCost) => {
    return this.axios.put(`/housed/housed/shipping`, shippingCost)
  }

  createPurchaseOrder = async ({ids, except, filters, search, ordering}: {ids?: string[], except?: string[]} & IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.post(`/housed/housed/purchase-order?${sp.toString()}`, {ids, except})
  }
}

const housedAsinsApi = new HousedAsinsApi()

export default housedAsinsApi
