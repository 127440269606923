import axios from './axios'
import { AxiosInstance } from 'axios'
import { AmazonImagesIndexedDB } from 'utils/indexedDB'

class Api {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  signup = async (data: { email: string; password: string; name: string, phone: string }) => {
    return this.axios.post('/auth/signup', data).then((res) => res.data as { token: string; user: { id: string } })
  }

  getReferralEmail = async (code: string) => {
    return this.axios.get('/invited/' + code).then((res) => res.data)
  }

  getUser = async (userId: string = 'me') => {
    if (userId === 'me' && Rewardful.referral) {
      return this.axios.get('/user/me?rewardfulReferralId=' + Rewardful.referral).then((res) => res.data)
    }
    return this.axios.get('/user/' + userId).then((res) => res.data)
  }

  getAmazonAuthUri = () => {
    return this.axios.get('/user/amazon/auth-uri').then((res) => res.data)
  }

  authorizeAmazon = (code: string, sellerId: string, state: string) => {
    return this.axios.put('/user/amazon/code', { code, sellerId, state }).then((res) => res.data)
  }

  getAmazonProductImage = async (asin: string) => {
    const image = await AmazonImagesIndexedDB.get(asin)
    if (image) return image
    return this.axios.get('/amazon/products/pictures/'+asin).then(async res => {
      await AmazonImagesIndexedDB.set(res.data.image).catch(() => {})
      return res.data.image
    })
  }

  subscribeToNewsletter = async (data: {email: string, fullName: string, monthlyRevenue: string, businessModel: string, message: string}) => {
    return this.axios.post('/emailSubscribers/subscribe', data)
  }
}

const api = new Api()

export default api
