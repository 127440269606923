import Icon from 'assets/icons/iconset'
import AmazonProductImage from 'components/AmazonProductImage'
import BadgeSelect from 'components/BadgeSelect'
import Tooltip from 'components/Tooltip'
import TD from 'components/tables/TD'
import useTableContext from 'contexts/Table/useTableContext'
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react'
import { CatalogDashboard, CatalogItem } from 'types/catalog'
import { usNumber } from 'utils/formatting'
import catalogApi from 'api/catalog'

interface props {
  item: CatalogItem
  setDashboard: Dispatch<SetStateAction<CatalogDashboard | undefined>>
}

const feeMap = {
  ReferralFee: 'Referral Fee',
  VariableClosingFee: 'Variable Closing Fee',
  PerItemFee: 'Per Item Fee',
  FBAFees: 'FBA Fees',
  StorageFee: 'Storage Fee',
}

const Item: FC<props> = ({ item, setDashboard }) => {
  const [_buyPrice, setBuyPrice] = useState<number | undefined>(item.buyPrice)
  const [_bundle, setBundle] = useState<number | undefined>(item.bundle)
  const [supplier, setSupplier] = useState(item.supplier)
  const [prepCost, setPrepCost] = useState(item.prepCost)
  const {
    table: { locked, extra },
  } = useTableContext()

  const [updating, setUpdating] = useState(false)

  const supplierNames = extra?.supplierNames || []

  const listingPrice = item.offerPrice || item.salesPrice || 0
  const buyPrice = _buyPrice || 0
  const bundle = _bundle || 0

  const netProceeds = listingPrice - item.feePrice || 0
  const profit = netProceeds - buyPrice * bundle
  const totalNetProceeds = netProceeds * item.stock
  const totalProfit = profit * item.stock
  const totalRevenue = listingPrice * item.stock
  // const grossProfit = listingPrice - buyPrice * bundle - item.feePrice
  // const grossMargin = listingPrice ? grossProfit / listingPrice : 0
  // const totalEstimated = grossProfit * item.stock
  const totalStockValue = buyPrice * bundle * item.stock

  const referralPercentage = item.otherData?.fees?.ReferralFee ? item.otherData?.fees?.ReferralFee / listingPrice : 0

  const formattedFeeBreakdown = Object.entries(item.otherData?.fees || {})
    .map(([type, price]) => {
      if (!price) return null
      // @ts-ignore
      if (!feeMap[type]) return null
      // @ts-ignore
      const formattedType = feeMap[type]
      if (type === 'ReferralFee') {
        return `${formattedType}: $${price} (${(referralPercentage * 100).toFixed(2)}%)`
      } else {
        return `${formattedType}: $${price}`
      }
    })
    .filter(Boolean)
    .join('\n')

  const formattedInventoryBreakdown = Object.entries(item.stockBreakdown)
    .map(([type, qty]) => {
      const formattedType = type
        .replace('Quantity', '')
        .replace(/([A-Z])/g, ' - $1')
        .replace(/^./, (str) => str.toUpperCase())
      return `${formattedType}: ${qty}`
    })
    .join('\n')

  const update = async (overrides?: any) => {
    if (updating) return
    setUpdating(true)
    const bundle = overrides?.bundle || _bundle
    const buyPrice = overrides?.buyPrice || _buyPrice
    const supplierOverridden: string = overrides?.supplier || supplier
    const prepCostOverridden: string = overrides?.prepCost || prepCost
    setBundle(Math.floor(bundle))
    catalogApi
      .updateItem(item.id, {
        buyPrice,
        bundle: Math.floor(bundle),
        supplier: supplierOverridden,
        prepCost: prepCostOverridden,
      })
      .then(({dashboard}) => {
        setDashboard(dashboard)
      })
      .finally(() => setUpdating(false))
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | undefined>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if (isNaN(value)) return updater(undefined)
    updater(value)
  }

  return (
<tr className={[updating && 'grayscale'].asClass} onBlur={update}>
      <TD>
        <AmazonProductImage asin={item.asin} size={48} className="!w-12 !h-8 object-cover" />
      </TD>
      <TD>{item.asin}</TD>
      <TD>
        <a href={`https://www.amazon.com/dp/${item.asin}`} target="_blank" rel="noreferrer">
          <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
        </a>
      </TD>
        <TD className="max-w-[20rem] truncate">{item.name}</TD>
      <TD>{item.sku}</TD>
       <TD onBlur={(e) => e.stopPropagation()}>
        <BadgeSelect
          selected={supplier || ""}
          badges={supplierNames}
          onSelect={(supplier) => {
            setSupplier(supplier)
            update({supplier})
          }}
          editable={!locked && !updating}
        />
      </TD> 
      <TD className="p-1">
        <div className="relative border border-border-primary rounded">
          <input type="number" readOnly={locked} value={_buyPrice} onBlur={update} onChange={onNumberChange(setBuyPrice)} className="p-2 pl-5" placeholder="0.00" />
          <span className="text-sm text-base-900 p-2 absolute left-0">$</span>
        </div>
      </TD>
      <TD className="p-1">
        <input type="number" readOnly={locked} value={_bundle} onBlur={update} onChange={onNumberChange(setBundle)} className="p-2 border border-border-primary rounded" placeholder="0" />
      </TD>
       <TD onBlur={(e) => e.stopPropagation()}>
        <BadgeSelect
          selected={prepCost || ""}
          badges={extra?.prepCosts || []}
          onSelect={(prepCost) => {
            setPrepCost(prepCost)
            update({prepCost})
          }}
          singleColor="blue"
          editable={!locked && !updating}
        />
      </TD> 
      <TD>${usNumber(buyPrice * bundle)}</TD>
      <TD>
        <Tooltip className="leading-[200%]" text={formattedInventoryBreakdown} position="left-start">
          {usNumber(item.stock, 0)}
        </Tooltip>
      </TD>
      <TD>
        <Tooltip className="leading-[200%]" text={formattedFeeBreakdown} position="left">
          ${usNumber(listingPrice)}
        </Tooltip>
      </TD>
      <TD>${usNumber(totalRevenue)}</TD>
      <TD>${usNumber(totalStockValue)}</TD>
      <TD>${usNumber(totalNetProceeds)}</TD>
      <TD
      className={totalProfit < 0 ? 'text-red-500' : totalProfit > 0 ? 'text-green-500' : ''}
      >
        ${usNumber(totalProfit)}
        </TD>
      <TD>{item.listingCreatedAt ? new Date(item.listingCreatedAt).toLocaleDateString('en-US') : '---'}</TD>
      <TD>{item.type}</TD>
    </tr>
  )
}

export default Item
