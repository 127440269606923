import { FC } from 'react'
import { HousedAsin } from 'types/housedAsins'
import AmazonProductImage from 'components/AmazonProductImage'
import TD from 'components/tables/TD'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import { usNumber } from 'utils/formatting'
import TR from 'components/tables/TR'

interface props {
  item: HousedAsin
}

const Item: FC<props> = ({ item }) => {
  const table = useTableContext()

  const isChecked = !!table.table.select?.selected.includes(item.id)
  const allSelected = !!table.table.select?.allSelected

  return (
    <TR className="relative">
      {!!table.table.select && (
        <TD onBlur={(e) => e.stopPropagation()} onClick={() => table.table.select?.onSelectClick(item.id)}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </TD>
      )}
      <TD onBlur={(e) => e.stopPropagation()}>
        <AmazonProductImage asin={item.asin} size={48} imageSize={96} />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()} className="w-20">
        {item.asin}
      </TD>
      <TD className={item.gross < 0 ? 'text-red-500' : item.gross > 0 ? 'text-green-500' : ''}>$ {usNumber(item.gross)}</TD>
      <TD>{usNumber(item.margin !== null ? item.margin * 100 : null, 2)}%</TD>
      <TD>{usNumber(item.roi !== null ? item.roi * 100 : null, 2)}%</TD>
    </TR>
  )
}

export default Item
