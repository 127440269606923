import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import housedAsinsApi from 'api/housedAsins'
import Table from 'components/tables/Table'
import useSelect from 'hooks/useSelect'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import columnDef from './tableDef'
import { HousedAsin } from 'types/housedAsins'
import Item from './Item'
import withFiltering from 'contexts/Filter/wrapper'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import Loader from 'components/loaders/Loader'
import Icon from 'assets/icons/iconset'
import { CloseIcon } from 'assets/icons'
import { useNavigate } from 'react-router'
import purchaseOrdersApi from 'api/purchaseOrders'
import { IApiFilter } from 'types/tableFiltering'

interface props {
  id: string
  open: boolean
  setOpen: (open: boolean) => void
  supplier: string
  asins: string[]
}

const queryFn = async ({ queryKey, pageParam }: { queryKey: string[]; pageParam: number }) => {
  const {filters, ordering, search} = JSON.parse(queryKey[3]) as IApiFilter
  return housedAsinsApi.getHoused({
    page: pageParam,
    ordering,
    search,
    filters: {
      ...filters,
      'supplier.name': {
        o: '=',
        v: queryKey[1],
      },
      asin: {
        o: 'NOT IN',
        v: queryKey[2].split(','),
      },
    },
  })
}

const AddAsin: FC<props> = ({ id, open, setOpen, supplier, asins }) => {
  const select = useSelect()
  const { search, period, opFilters, ordering, setSearch, searching } = useFilteringContext()
  const filters = useMemo(() => JSON.stringify({ search, period, filters: opFilters, ordering }), [search, period, opFilters, ordering])
  const { clear } = useSelect()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (open) {
      clear()
    }
  }, [open, clear])

  const { data, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['housedAsins', supplier, asins.join(','), filters],
    queryFn,
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
  })

  const renderItem = useCallback((item: HousedAsin) => <Item item={item} />, [])

  const canDoBulk = select.selected.length > 0 || select.allSelected

  const submit = useCallback(() => {
    if (!canDoBulk) return
    if (submitting) return
    setSubmitting(true)    
    purchaseOrdersApi.addItemsToPurchaseOrder(id, {ids: select.selected}).then(() => {
        navigate(0)
    }).catch(() => {
        setSubmitting(false)
    })
  }, [canDoBulk, submitting, navigate, id, select.selected])    

  return (
    <div 
    className="w-full h-full fixed inset-0 flex justify-end z-[100000]"
        style={{
            transform: `translateX(${open ? 0 : '100%'})`,
            transition: 'transform 0.5s',
            pointerEvents: open ? 'auto' : 'none',
        }}
        onClick={() => setOpen(false)}
    >
      <div onClick={(e) => e.stopPropagation()} className="w-full max-w-[min(95%,32rem)] relative h-full bg-white drop-shadow-xl overflow-hidden">
        <CloseIcon className="absolute top-4 right-4 cursor-pointer z-10" onClick={() => setOpen(false)} />
        <div className="flex flex-col min-h-full gap-6 p-4 h-full">
            <div className="flex flex-col gap-1">
                <div className='flex items-center gap-4'>
                    <span className='text-text-primary font-medium text-sm'>
                        Supplier:
                    </span>
                    <span className="bg-brand-disabled text-brand-primary py-1 px-2 rounded text-xs font-medium">
                        {supplier}
                    </span>
                </div>
                <span className='text-text-secondary text-sm mt-2'>
                    Please enter new ASINS in Housed ASINS.
                </span>
                <span className='text-text-secondary text-sm'>
                    Note: Profit is calculated based on current Amazon price.
                </span>
            </div>
          <div className="flex items-center gap-4">
            <div className="input-box relative w-full">
              <input type="text" value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder="Search by UPC / ASIN..." className="!px-8 [&:not(:hover)]:!border-border-secondary" />
              <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
                <Icon name="MagnifyingGlass" className="w-5 h-5" />
                {searching && <Loader size={20} />}
              </div>
            </div>
          </div>
          <div className="border border-border-primary bg-surface-primary rounded-lg h-full overflow-hidden">
            <Table columns={columnDef} renderItem={renderItem} select={select} items={data?.pages.map((p) => p.items).flat()} loadingNext={isFetchingNextPage} />
          </div>
          <div className="flex justify-end w-full">
            <button className="button-primary"
                disabled={submitting || !canDoBulk}
            onClick={() => {
                setOpen(false)
                submit()
                }}>
              Add to Purchase Order
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withFiltering(AddAsin)
