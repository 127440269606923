import { IShippingCost } from "types/housedAsins";
import { IPrepCost } from "types/prepCosts";
import { IPurchaseOrder, IPurchaseOrderItem } from "types/purchaseOrders";

const getSellableQty = (item: IPurchaseOrderItem) => {
    const buyQty = item.buyQty;
    const bundle = item.bundle;
    return buyQty / bundle;
}

const getEstimatedRevenue = (item: IPurchaseOrderItem) => {
    const price = parseFloat(item.targetPrice || item.housedAsin.price);
    const sellable = getSellableQty(item);
    return price * sellable;
}

const getCostOfGoods = (item: IPurchaseOrderItem) => {
    const unitCost = parseFloat(item.unitCost || item.housedAsin.unitCost);
    const buyQty = item.buyQty;
    return unitCost * buyQty;
}

const getWeight = (item: IPurchaseOrderItem) => {
    const weight = parseFloat(item.housedAsin.weightValue);
    const buyQty = item.buyQty;
    return weight * buyQty;
}

const getFees = (item: IPurchaseOrderItem) => {
    return getSellableQty(item) * +item.housedAsin.fees;
}

const getShippingCost = (item: IPurchaseOrderItem, shippingCost?: IShippingCost) => {
    switch (shippingCost?.type) {
        case "per_pound":
            return getWeight(item) * shippingCost.cost;
        case "per_item":
            return item.buyQty * shippingCost.cost;
        default:
            return 0;
    }
}

const getPrepCost = (item: IPurchaseOrderItem, prepCosts?: IPrepCost[]) => {
    const prepCost = prepCosts?.find(cost => cost.name === item.housedAsin.prepCost);
    return (prepCost?.amount || 0) * getSellableQty(item);
}

const getCases = (item: IPurchaseOrderItem) => {
    return item.buyQty / item.casePack;
}

export type IPurchaseOrderOverview = ReturnType<typeof getPurchaseOrderOverview>;

export const getPurchaseOrderOverview = ({ purchaseOrder, shippingCostDef, prepCosts}: { purchaseOrder: IPurchaseOrder, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[] }) => {
    const totalEstimatedRevenue = purchaseOrder.items.reduce((acc, item) => acc + getEstimatedRevenue(item), 0);

    const totalCOGs = purchaseOrder.items.reduce((acc, item) => acc + getCostOfGoods(item), 0);
    const totalWeight = purchaseOrder.items.reduce((acc, item) => acc + getWeight(item), 0);
    const totalShipping = Number(purchaseOrder.shippingCost) || purchaseOrder.items.reduce((acc, item) => acc + getShippingCost(item, shippingCostDef), 0);
    const totalProduction = purchaseOrder.items.reduce((acc, item) => acc + getPrepCost(item, prepCosts), 0);

    const totalFees = purchaseOrder.items.reduce((acc, item) => acc + getFees(item), 0);
    const totalExpenses = totalShipping + totalProduction + totalCOGs;
    const totalCases = purchaseOrder.items.reduce((acc, item) => acc + getCases(item), 0);
    const totalUnitsPurchased = purchaseOrder.items.reduce((acc, item) => acc + item.buyQty, 0);
    const totalSellableAsins = purchaseOrder.items.reduce((acc, item) => acc + getSellableQty(item), 0);

    const totalNetProceeds = totalEstimatedRevenue - totalFees;
    const totalGrossProfit = totalNetProceeds - totalExpenses;
    const totalLandedCost = totalExpenses;

    const totalMargin = totalEstimatedRevenue ? totalGrossProfit * 100 / totalEstimatedRevenue : null;

    const totalROI = totalLandedCost ? totalGrossProfit * 100 / totalLandedCost : null;
    const buttonText = totalUnitsPurchased ? "View Purchase Order" : "Begin Purchase Order";

    return {
        totalEstimatedRevenue,
        totalCOGs,
        totalWeight,
        totalShipping,
        totalProduction,
        totalExpenses,
        totalCases,
        totalUnitsPurchased,
        totalSellableAsins,
        totalNetProceeds,
        totalGrossProfit,
        totalMargin,
        totalFees,
        totalROI,
        buttonText,
        totalLandedCost
    }
}

export type IPurchaseOrderColumnItem = ReturnType<typeof getPurchaseOrderItemColums>;

export const getPurchaseOrderItemColums = (item: IPurchaseOrderItem, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[]) => {
    const id = item.id;
    const asin = item.asin;
    const title = item.housedAsin.title;
    const vendorIdentifier = `${item.housedAsin.upc} / ${item.housedAsin.vendorSKU}`;
    const unitCost = item.unitCost || item.housedAsin.unitCost;
    const bundle = item.bundle;
    const buyQty = item.buyQty;
    const sellable = getSellableQty(item);
    const casePack = item.casePack;
    const cases = getCases(item);
    const price = item.housedAsin.price;
    const targetPrice = item.targetPrice;
    const cogs = getCostOfGoods(item);
    const prepCost = getPrepCost(item, prepCosts);
    const productionCost = prepCost;
    const shippingCost = getShippingCost(item, shippingCostDef);

    const landedCost = cogs + prepCost + shippingCost;

    const revenue = getEstimatedRevenue(item);
    const fees = getFees(item);
    const netProceeds = revenue - fees;
    const gross = netProceeds - landedCost;
    const roi = landedCost ? gross * 100 / landedCost : null;
    const margin = revenue ? gross * 100 / revenue : null;
    const weight = getWeight(item);

    return {
        id,
        asin,
        title,
        vendorIdentifier,
        unitCost,
        bundle,
        buyQty,
        sellable,
        casePack,
        cases,
        price,
        targetPrice,
        cogs,
        prepCost,
        productionCost,
        shippingCost,
        landedCost,
        revenue,
        fees,
        netProceeds,
        gross,
        roi,
        margin,
        weight
    }
}