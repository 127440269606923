import Icon from 'assets/icons/iconset'
import AmazonProductImage from 'components/AmazonProductImage'
import BadgeSelect from 'components/BadgeSelect'
import TD from 'components/tables/TD'
import TR from 'components/tables/TR'
import { FC, useMemo } from 'react'
import { IWarehouseInventoryItem } from 'types/warehouse'
import { usNumber } from 'utils/formatting'

interface props {
  item: IWarehouseInventoryItem
}

const Item: FC<props> = ({ item }) => {
  const listing = `https://www.amazon.com/dp/${item.asin}`

  const expirationDate = useMemo(() => {
    if (!item.expirationDate) return ''
    const date = new Date(item.expirationDate)
    const [month, day, year] = date.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, "-").split("-")
    return `${year}-${month}-${day}`
  }, [item.expirationDate])

  return (
    <TR>
      <TD onBlur={(e) => e.stopPropagation()}>
        <AmazonProductImage asin={item?.asin} size={32} imageSize={32} />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={item?.asin} readOnly />
          {!!item.asin && (
            <a href={listing} target="_blank" rel="noreferrer">
              <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
        </div>
      </TD>
      <TD>
        <input type="text" value={item.title} readOnly />
      </TD>
      <TD>
        <input type="text" value={item.upc} readOnly />
      </TD>
      <TD>
        <input type="text" value={item.units} readOnly />
      </TD>
      <TD>
        <input type="text" value={item.bundle} readOnly />
      </TD>
      <TD>
        <input type="text" value={item.sellable} readOnly />
      </TD>
      <TD>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect selected={item.prepCost_name || ''} badges={[]} onSelect={() => {}} singleColor="blue" editable={false} />
          <span>${usNumber(+item.prepCost_amount)}</span>
        </div>
      </TD>
      <TD>{item.expirationDate ? <input type="date" value={expirationDate} readOnly /> : '...'}</TD>
    </TR>
  )
}

export default Item
