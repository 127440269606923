import TD from "components/tables/TD";
import { FC } from "react";
import { MasterUPCCatalogItem } from "types/housedAsins";
import { usNumber } from "utils/formatting";

interface props {
    item: MasterUPCCatalogItem
}

const Item: FC<props> = ({item: {supplier, vendorSKU, upc, unitCost, caseCost, casePack, title, uom, size}}) => {
    return (
        <tr className="[&>td]:select-text h-0">
            <TD>
                {supplier || "---"}
            </TD>
            <TD>
                {title || "---"}
            </TD>
            <TD>
                {size || "---"}
            </TD>
            <TD>
                {uom || "---"}
            </TD>
            <TD>
                {upc || "---"}
            </TD>
            <TD>
                {vendorSKU || "---"}
            </TD>
            <TD>
                ${usNumber(Number(unitCost))}
            </TD>
            <TD>
                {Number(casePack)}
            </TD>
            <TD>
                ${usNumber(Number(caseCost))}
            </TD>
        </tr>
    )
}

export default Item