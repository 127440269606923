import { ReactComponent as First } from './first.svg'
import { ReactComponent as Check } from './check.svg'
import { default as SecondLeft } from './left.png'
import { default as SecondCenter } from './center.png'
import { default as SecondRight } from './right.png'
import { default as Laptop } from './laptop.png'
import { default as Database } from './database.png'
import { default as Suppliers } from './suppliers.png'
import { default as Expenses } from './expenses.png'
import { default as Paul } from './paul.png'
import { default as Logo } from './logo.png'

export const PromoV2 = {
  first: First,
  secondSrc: {
    left: SecondLeft,
    center: SecondCenter,
    right: SecondRight,
  },
  check: Check,
  database: Database,
  suppliers: Suppliers,
  expenses: Expenses,
  paul: Paul,
  laptop: Laptop,
  logo: Logo,
}
