import useTableContext from 'contexts/Table/useTableContext'
import { FC } from 'react'
import TD from './TD'

const TableLoader: FC = () => {
  const {
    table: { columns, select },
  } = useTableContext()
  const withSelection = !!select

  const colSpan = withSelection ? columns.length + 1 : columns.length
  const cols = Array.from({ length: colSpan })
  const rows = Array.from({ length: 20 })

  return (
    <>
      {rows.map((_, i) => (
        <tr key={i}>
          {cols.map((def, j) => (
            <TD key={`${i}_${j}`}>
              <div className="animate-pulse h-6 bg-surface-secondary rounded-md" />
            </TD>
          ))}
        </tr>
      ))}
    </>
  )
}

export default TableLoader
