import { FC, useCallback, useState } from 'react'
import { columnDef } from './tableDef'
import Table from 'components/tables/Table'
import ContentLayout from 'components/layout/Content'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { useMemo } from 'react'
import { IApiFilter } from 'types/tableFiltering'
import warehouseApi from 'api/warehouse'
import { IWarehouseInventoryItem } from 'types/warehouse'
import Item from './Item'
import useSupplierNames from 'hooks/useSupplierNames'
import withFiltering from 'contexts/Filter/wrapper'
import useExcelUpload from 'hooks/useExcelUpload'
import { WarehouseInventoryExcelUpload } from 'utils/excelUpload/templates'
import useAwaitableModal from 'hooks/useAwaitableModal'
import BulkUpload from 'components/modals/BulkUpload'
import useUserContext from 'contexts/User/useUserContext'
import { useNavigate } from 'react-router'
import { ref, uploadBytes } from 'firebase/storage'
import firebase from 'config/firebase'

const queryFn = async ({ pageParam: page, queryKey }: { pageParam: number; queryKey: [string, IApiFilter] }) => {
  return warehouseApi.getWarehouseInventoryItems({ page, ...queryKey[1] })
}

const dashboardQueryFn = async () => {
  return warehouseApi.getWarehouseInventoryDashboard()
}

const WarehouseInventoryPage: FC = () => {
  const { supplierNames } = useSupplierNames()
  const { search, ordering, opFilters: filters } = useFilteringContext()

  const queryKey = useMemo(
    () => [
      'warehouse-inventory',
      {
        search,
        ordering,
        filters,
      },
    ],
    [search, ordering, filters]
  )

  const { data, isLoading, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: queryKey as [string, IApiFilter],
    queryFn: (params) => queryFn(params),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
  })

  const { data: dashboard } = useQuery({
    queryKey: ["warehouse-inventory-dashboard"],
    queryFn: dashboardQueryFn
  })

  const renderItem = useCallback((item: IWarehouseInventoryItem) => <Item item={item} />, [])

  const entryExcelUpload = useExcelUpload(WarehouseInventoryExcelUpload)
  const [requestUpload, UploadModal] = useAwaitableModal(BulkUpload, { template: entryExcelUpload, respondWithFile: true })
  const [importing, setImporting] = useState<boolean | 'uploading' | 'processing'>(false)
  const user = useUserContext()
  const navigate = useNavigate()

  const importExcel = useCallback(async () => {
    if (importing) return
    setImporting('uploading')
    return requestUpload()
      .then(async (res) => {
        if (!res?.result) return
        const { result } = res
        const dest = `users/${user?.id}/inventory/${Date.now()}.xlsx`
        await uploadBytes(ref(firebase.storage, dest), result as File).then(async () => {
          setImporting('processing')
          return warehouseApi.uploadInventory(dest).then(() => {
            navigate(0)
          })
        })
      })
      .finally(() => setImporting(false))
  }, [importing, requestUpload, user?.id])

  return (
    <ContentLayout
     wrapperClass="gap-4 !bg-surface-light !border-none !flex !flex-col !h-full w-full"
      buttons={
        <button
          className="button-secondary"
          onClick={importExcel}
          disabled={!!importing}
        >
          {importing === 'uploading' ? 'Uploading...' : importing === "processing" ? "Processing..." : 'Upload CSV'}
        </button>
      }
     >
      <UploadModal />
      <div className="flex items-center gap-4">
        <div className="flex flex-col gap-1 p-4 rounded-lg border border-border-primary bg-surface-primary w-[12rem]">
            <span className='text-text-secondary text-sm'>
                Total COGS
            </span>
            <span className='text-text-primary text-xl'>
                ${dashboard?.cogs ?? "..."}
            </span>
        </div>
        <div className="flex flex-col gap-1 p-4 rounded-lg border border-border-primary bg-surface-primary w-[12rem]">
            <span className='text-text-secondary text-sm'>
                ASIN Qty
            </span>
            <span className='text-text-primary text-xl'>
                {dashboard?.asins ?? "..."}
            </span>
        </div>
        <div className="flex flex-col gap-1 p-4 rounded-lg border border-border-primary bg-surface-primary w-[12rem]">
            <span className='text-text-secondary text-sm'>
                Total Sellable Units
            </span>
            <span className='text-text-primary text-xl'>
                {dashboard?.units ?? "..."}
            </span>
        </div>
      </div>
      <div className="!overflow-y-auto !bg-surface-light !border-none !flex !flex-col h-full">
        <div className="flex flex-col w-full h-full bg-surface-primary !rounded-lg gap-4 [&>div:nth-child(2)]:border [&>div:nth-child(2)]:border-border-primary [&>div:nth-child(2)]:rounded-lg [&>div:nth-child(2)]:min-h-full">
          <Table
            columns={columnDef}
            items={data?.pages.flatMap((p) => p.items)}
            renderItem={renderItem}
            loading={isLoading && !data}
            loadingNext={isFetchingNextPage}
            onBottom={fetchNextPage}
            extra={{ supplierNames }}
          />
        </div>
      </div>
    </ContentLayout>
  )
}

export default withFiltering(WarehouseInventoryPage)
