import ExcelUpload from "../base"

const schema = [
    {
        name: "Name",
        type: "text",
    },
    {
        name: "Account Number",
        type: "text",
    },
    {
        name: "Website",
        type: "text",
    },
    {
        name: "Username",
        type: "text",
    },
    {
        name: "Password",
        type: "text",
    },
    {
        name: "Order Day",
        type: "text",
    },
    {
        name: "Order Due By",
        type: "text",
    },
    {
        name: "Delivery Day",
        type: "text",
    },
    {
        name: "Lead Time",
        type: "text",
    },
    {
        name: "Payment Terms",
        type: "text",
    },
    {
        name: "Payment Date",
        type: "text",
    },
    {
        name: "Carrier",
        type: "text",
    },
    {
        name: "Discrepancy Time",
        type: "text",
    },
    {
        name: "Rep Name",
        type: "text",
    },
    {
        name: "Rep Email",
        type: "text",
    },
    {
        name: "Rep Phone",
        type: "text",
    },
    {
        name: "Rep Birthday",
        type: "text",
    },
    {
        name: "Credit Dept",
        type: "text",
    },
] as const

const excelUpload = new ExcelUpload(schema)

export default excelUpload
