import { useQuery, useQueryClient } from '@tanstack/react-query'
import purchaseOrdersApi from 'api/purchaseOrders'
import FullLoader from 'components/loaders/FullLoader'
import useTitle from 'contexts/Title/useTitle'
import usePrepCosts from 'hooks/usePrepCosts'
import { FC, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import Dashboard from './components/Dashboard'
import Overview from './components/Overview'
import Table from 'components/tables/Table'
import columnDef from './tableDef'
import { IPurchaseOrderColumnItem, getPurchaseOrderItemColums } from 'utils/purchaseOrders'
import { frontendFilter } from 'hooks/frontendFilter'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import Item from './components/Item'
import { IPurchaseOrderItem } from 'types/purchaseOrders'
import withFiltering from 'contexts/Filter/wrapper'
import Loader from 'components/loaders/Loader'
import Icon from 'assets/icons/iconset'
import useSelect from 'hooks/useSelect'
import useBulkDelete from 'hooks/useBulkDelete'
import useBulkAction from 'hooks/useBulkAction'
import useAwaitableModal from 'hooks/useAwaitableModal'
import InboundModal from './components/InboundModal'
import AddAsin from './components/AddAsin'

const PurchaseOrderPage: FC = () => {
  const { id, status } = useParams<{ id: string; status: 'open' | 'closed' }>()
  const open = status === 'open'
  const { data } = useQuery({
    queryKey: ['purchaseOrder', id],
    queryFn: () => purchaseOrdersApi.getPurchaseOrder(id!),
  })
  const filters = useFilteringContext()
  const { prepCosts } = usePrepCosts()
  const { search, setSearch, searching } = filters
  const select = useSelect()
  const [adding, setAdding] = useState(false)

  const items = useMemo(() => {
    if (!data) return
    const transformed = data.purchaseOrder.items.map((item) => getPurchaseOrderItemColums(item, data.shippingCostDef, prepCosts))
    const filtered = frontendFilter(transformed, filters)
    return filtered.map((item) => ({ raw: data.purchaseOrder.items.find((i) => i.id === item.id)!, transformed: item })) as { raw: IPurchaseOrderItem; transformed: IPurchaseOrderColumnItem }[]
  }, [prepCosts, data, filters])

  const [onDelete, deleting, AreYouSureModal] = useBulkDelete(select, purchaseOrdersApi.deletePurchaseOrderItems.bind(this, id!), {
    header: 'Delete Items',
    description: 'Are you sure you want to delete these entries?',
  })
  const [onCreateBackOrder, creatingBackOrder] = useBulkAction(select, purchaseOrdersApi.createBackOrder.bind(this, id!), '/app/purchase-orders/open')
  const [submit, InboundModalComponent] = useAwaitableModal(InboundModal, select)

  useTitle('__back__Purchase Order')

  const qc = useQueryClient()

  const onUpdate = useCallback(
    (entry: Partial<IPurchaseOrderItem>) => {
      qc.setQueryData(['purchaseOrder', id], (old: typeof data) => {
        if (!old) return old
        return {
          ...old,
          purchaseOrder: {
            ...old.purchaseOrder,
            items: old.purchaseOrder.items.map((i: IPurchaseOrderItem) => (i.id === entry.id ? { ...i, ...entry } : i)),
          },
        }
      })
    },
    [id, qc]
  )

  const renderItem = useCallback((item: { raw: IPurchaseOrderItem; transformed: IPurchaseOrderColumnItem }) => <Item raw={item.raw} item={item.transformed} onUpdate={onUpdate} />, [onUpdate])

  const canDoBulk = select.selected.length > 0 || select.allSelected
  const bulkInProgress = creatingBackOrder || deleting

  if (!data || !prepCosts) return <FullLoader />

  return (
    <div className="w-full h-full overflow-y-auto p-4 bg-surface-light">
      <AddAsin id={data.purchaseOrder.id} asins={data.purchaseOrder.items.map((i) => i.asin)} open={adding} setOpen={setAdding} supplier={data.purchaseOrder.supplier} />
      <div className="flex flex-col grow w-full gap-4">
        <Dashboard purchaseOrder={data.purchaseOrder} shippingCostDef={data.shippingCostDef} prepCosts={prepCosts} />
        <Overview purchaseOrder={data.purchaseOrder} shippingCostDef={data.shippingCostDef} prepCosts={prepCosts} />
        <div className="flex items-center gap-4">
          <div className="input-box relative">
            <input type="text" value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder={'Search'} className="!px-8" />
            <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
              <Icon name="MagnifyingGlass" className="w-5 h-5" />
              {searching && <Loader size={20} />}
            </div>
          </div>
          {open && (
            <>
              <button className="button-primary" onClick={() => setAdding(true)} disabled={bulkInProgress}>
                <Icon name="Plus" className="w-4 h-4" />
                Add New ASIN
              </button>
              <div className="grow flex gap-4 justify-end">
                {canDoBulk ? (
                  <div className="flex gap-4">
                    <button className="button-destructive w-max" onClick={onDelete} disabled={bulkInProgress}>
                      Delete
                    </button>
                    <button className="button-secondary w-max" onClick={onCreateBackOrder} disabled={bulkInProgress}>
                      Back Order
                    </button>
                  </div>
                ) : undefined}
                <button className="button-primary" onClick={() => submit(select)} disabled={bulkInProgress}>
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <AreYouSureModal />
      <InboundModalComponent />
      <div className="border border-border-primary bg-surface-primary rounded-lg mt-4 h-full overflow-hidden">
        <Table
          columns={columnDef}
          renderItem={renderItem}
          items={items}
          loading={!prepCosts}
          noSelectAll
          select={open ? select : undefined}
          locked={!open}
          extra={{
            prepCosts,
          }}
        />
      </div>
    </div>
  )
}

export default withFiltering(PurchaseOrderPage)
