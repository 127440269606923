import { HousedAsin } from 'types/housedAsins'
import { IColumnDefinition } from 'types/table'

const columnDef: IColumnDefinition<HousedAsin & { image: string }>[] = [
  {
    field: 'image',
    title: 'Image',
    pin: true,
  },
  {
    field: 'asin',
    queryField: 'item.asin',
    title: 'ASIN',
    filters: 'string',
    pin: true,
  },
  {
    field: 'gross',
    queryField: "gross",
    title: 'Profit',
  },
  {
    field: 'margin',
    queryField: "margin",
    title: 'Margin',
  },
  {
    field: 'roi',
    queryField: "roi",
    title: 'ROI',
  },
]

export default columnDef
