import { IWarehouseInboundShipmentItem } from "types/warehouse";

export type IWarehouseInboundShipmentItemColumn = ReturnType<typeof getWarehouseInboundShipmentItemColumns>;

export const getWarehouseInboundShipmentItemColumns = (item: IWarehouseInboundShipmentItem, invoiceNumber: string) => {
    const image = item.asin;
    const asin = item.asin;
    const title = item.housedAsin.title;
    const upcOverSKU = `${item.housedAsin.upc} / ${item.housedAsin.vendorSKU}`;
    const casePack = item.casePack;
    const caseOrder = item.caseQty;
    const arrivedCaseQty = item.arrivedCaseQty;
    const units = item.units;
    const arrivedUnits = item.arrivedUnits;
    const difference = units - (arrivedUnits ?? 0);
    const missing = item.missing;
    const damaged = item.damaged;
    const bundle = item.bundleUnits;
    const sellable = (arrivedUnits ?? 0) / bundle;
    const prepCost = item.housedAsin.prepCost
    const expirationDate = item.expirationDate;
    return {
        id: item.id,
        invoiceNumber,
        image,
        asin,
        title,
        upcOverSKU,
        casePack,
        caseOrder,
        arrivedCaseQty,
        units,
        arrivedUnits,
        difference,
        missing,
        damaged,
        bundle,
        sellable,
        prepCost,
        expirationDate
    }
}
