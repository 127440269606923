import Icon from "assets/icons/iconset";
import Modal from "components/Modal";
import { AwaitableModal } from "hooks/useAwaitableModal";

const AreYouSure: AwaitableModal<undefined, {header?: string, description?: string, action?: string}> = ({ resolve, onCancel, open, initialData }) => {
    const Continue = initialData?.action || "Continue";
    const header = initialData?.header || "Are You Sure?";
    const description = initialData?.description || "This action cannot be undone.";
    return (
        <Modal open={open} close={onCancel}>
            <div className="bg-surface-primary rounded-lg overflow-hidden flex flex-col p-6 gap-4 max-w-[420px]">
                <div className="flex gap-2">
                    <Icon name="Question" className="w-6 h-6 text-text-error shrink-0" />
                    <div className="flex flex-col gap-1">
                        <span className="text-base text-text-primary font-medium">{header}</span>
                        <span className="text-sm text-text-secondary">{description}</span>
                    </div>
                </div>
                <footer className="flex items-center max-h-max justify-between gap-2">
                    <button className="button-secondary grow" onClick={onCancel}>Cancel</button>
                    <button className="button-destructive grow" onClick={() => resolve()}>{Continue}</button>
                </footer>
            </div>
        </Modal>
    )
};

export default AreYouSure;