import { FC } from 'react'
import { CatalogDashboard } from 'types/catalog'
import { usNumber } from 'utils/formatting'

interface DashboardTileProps {
  title: string
  value: string | number
  color: string
}

export const DashboardTile: FC<DashboardTileProps> = ({ title, value, color }) => {
  const loading = value === '...'
  return (
    <div 
    className={[
      "w-full h-[92px] flex flex-col rounded-lg bg-surface-primary p-4 gap-2 border border-border-primary",
      loading && 'animate-pulse'
      ].asClass}
      >
      <div className="flex gap-1 items-center justify-start">
        <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }} />
        <span className="text-sm text-text-secondary">{title}</span>
      </div>
      <div className="h-px bg-border-secondary w-full shrink-0"></div>
      <span className="text-xl font-medium text-text-primary">
        $&nbsp;{typeof value === 'string' ? value : usNumber(value)}
      </span>
    </div>
  )
}

interface DashboardProps {
  dashboard?: CatalogDashboard
}

const Dashboard: FC<DashboardProps> = ({ dashboard }) => {
  const tiles = [
    {
      title: 'Total Revenue Value',
      value: dashboard ? Number(dashboard?.totalRevenue) : '...',
      color: '#8F20FF',
    },
    {
      title: 'Total Net Proceeds',
      value: dashboard ? Number(dashboard?.totalNetProceeds) : '...',
      color: '#F0A30D',
    },
    {
      title: 'Total Cost',
      value: dashboard ? Number(dashboard?.totalCost) : '...',
      color: '#52CBFF',
    },
    {
      title: 'Total Profit',
      value: dashboard ? dashboard.totalNetProceeds - dashboard.totalCost : '...',
      color: '#12B76A',
    },
  ]

  return (
    <div className="grid grid-cols-4 w-full gap-4 pt-4 px-4">
      {tiles.map((tile, i) => (
        <DashboardTile key={i} {...tile} />
      ))}
    </div>
  )
}

export default Dashboard
