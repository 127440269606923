import { useQuery, useQueryClient } from "@tanstack/react-query"
import housedAsinsApi from "api/housedAsins"
import { Dispatch, SetStateAction, useCallback } from "react"

const queryFn = async () => {
    return housedAsinsApi.getAvailableBuyers()
}

const useAvailableBuyers = () => {
    const {data: availableBuyers} = useQuery({
        queryKey: ['buyers'],
        queryFn,
    })

    const qc = useQueryClient();

    const setAvailableBuyers: Dispatch<SetStateAction<string[] | undefined>> = useCallback((updater) => {
        qc.setQueryData<string[] | undefined>(['buyers'], updater)
    }, [qc])

    return {
        availableBuyers,
        setAvailableBuyers,
    }
}

export default useAvailableBuyers