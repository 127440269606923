import Icon from 'assets/icons/iconset'
import TD from 'components/tables/TD'
import useTableContext from 'contexts/Table/useTableContext'
import { FC, useCallback, useMemo, useState } from 'react'
import { IPnLTableRow } from 'types/pnl'

export const PNLTopLevelColors = {
  orange: "#F9CB9C",
  red: "#EA9999",
  teal: "#A2C4C9",
  blue: "#A4C2F4",
  green: "#B6D7A8",
}

interface props {
  item: IPnLTableRow
  index: number
  total: number
}

const currencyValue = (value = 0) => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

const numberValue = (value = 0) => {
  return value.toLocaleString('en-US')
}

const percentValue = (value = 0) => {
  return value.toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })
}

const Item: FC<props> = ({ item, index, total }) => {
  const [expanded, setExpanded] = useState(false)
  const { table } = useTableContext()

  const topLevelValues = item.data.map((d) => d?.value)
  const breakdownValues = item.data.map((d) => d?.breakdowns?.map((b) => b.value))

  const valueFormatter = useCallback((value: number, typeOverride?: typeof item.type) => {
    switch (typeOverride || item.type) {
      case 'currency':
        return currencyValue(value)
      case 'number':
        return numberValue(value)
      case 'percentage':
        return percentValue(value)
      default:
        return value
    }
  }, [item])

  const bgColor = useMemo(() => PNLTopLevelColors[item.color], [item.color])

  return (
    <>
      <tr 
      className={[
        "h-0 top-level",
        index % 2 === 0 ? '[&>td]:bg-[#D0E0FB]' : '[&>td]:bg-[#FFF]',
      ].asClass}
      >
        <TD className="w-0" /*style={{backgroundColor: bgColor}}*/ onClick={() => setExpanded(!expanded)}>
          {item.data?.[0]?.breakdowns?.length && (
            <div className="w-4 h-4 flex items-center justify-center cursor-pointer p-0.5 rounded-full hover:bg-[#00000020] transition-colors">
          <Icon name={expanded ? 'CaretDown' : 'CaretRight'} />
        </div>
        )}
        </TD>
        <TD className="max-w-[20rem] truncate font-bold" /*style={{backgroundColor: bgColor}}*/>{item.parameter}</TD>
        {table.columns.slice(2).map((col, i) => (
          <TD key={col.field.toString() + i} className="text-right" /*style={{backgroundColor: bgColor}}*/>
            {valueFormatter(topLevelValues[i])}
          </TD>
        ))}
      </tr>
      {(expanded || !item.data?.[0]?.breakdowns?.length) && item?.otherTopLevelValues?.map((b, i) => (
        <tr
        key={b.parameter + i}
        className={[
          "h-0",
        index % 2 === 0 ? '[&>td]:bg-[#D0E0FB]' : '[&>td]:bg-[#FFF]',
        ].asClass}
        >
          <TD className="w-0 font-semibold" /*style={{backgroundColor: bgColor}}*/></TD>
          <TD className="max-w-[20rem] truncate font-semibold" /*style={{backgroundColor: bgColor}}*/>{b.parameter}</TD>
          {table.columns.slice(2).map((col, j) => (
            <TD key={col.field.toString() + j} className="text-right" /*style={{backgroundColor: bgColor}}*/>
              {valueFormatter(b.value[j], b.type)}
            </TD>
          ))}
        </tr>
      ))}
      {expanded && item.data[0]?.breakdowns?.map((b, i) => (
        <tr 
        key={b.parameter + i} 
        className={[
          "h-0",
          i % 2 === 0 ? '[&>td]:bg-white' : '[&>td]:bg-blue-50',
        ].asClass}
        >
          <TD className="w-0"></TD>
          <TD className="max-w-[20rem] truncate">{b.parameter}</TD>
          {table.columns.slice(2).map((col, j) => (
            <TD key={col.field.toString() + j} className="text-right">
              {valueFormatter(breakdownValues[j]?.[i] || 0, b.type)}
            </TD>
          ))}
        </tr>
      ))}
      {index !== total - 1 && false && (
        <tr className="h-10">
          <TD className="w-0" colSpan={table.columns.length}></TD>
        </tr>
      
      )}
    </>
  )
}

export default Item
