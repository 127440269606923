import { IApiFilter } from "types/tableFiltering";
import useSelect from "./useSelect";
import { useCallback, useState } from "react";
import useAwaitableModal from "./useAwaitableModal";
import AreYouSure from "components/modals/AreYouSure";
import useFilteringContext from "contexts/Filter/useFilteringContext";
import { useNavigate } from "react-router";

const useBulkDelete = (
    select: ReturnType<typeof useSelect>, 
    deleteFn: (data: IApiFilter & {except?: string[], ids?: string[]}) => Promise<any>, 
    areYouSureConfig?: {
    header?: string | undefined;
    description?: string | undefined;
    action?: string | undefined;
}) => {
    const [deleting, setDeleting] = useState(false);
    const {opFilters: filters, search, ordering} = useFilteringContext();
    const [areYouSure, AreYouSureModal] = useAwaitableModal(AreYouSure, areYouSureConfig || {});
    const navigate = useNavigate();
    const handleDelete = useCallback(() => {
        if (deleting) return;
        setDeleting(true);
        let data = {} as Record<string, any>;
        if (select.allSelected) data.except = select.selected;
        else data.ids = select.selected;
        deleteFn({...data, filters, search, ordering})
            .then(() => navigate(0))
            .finally(() => setDeleting(false));
    }, [deleteFn, deleting, filters, ordering, search, select.allSelected, select.selected])

    const onDelete = useCallback(() => {
        areYouSure(areYouSureConfig).then(() => {
            handleDelete();
        });
    }, [areYouSure, handleDelete, areYouSureConfig]);

    return [onDelete, deleting, AreYouSureModal] as const;
};

export default useBulkDelete;