import Icon from "assets/icons/iconset";
import { Dispatch, FC, SetStateAction } from "react";

interface props {
    locked: boolean
    toggle: Dispatch<SetStateAction<boolean>>
}

const LockButton: FC<props> = ({locked, toggle}) => {
    return (
        <button onClick={() => toggle(!locked)} className={["button-secondary !px-2", locked ? "text-text-primary" : "text-brand-primary"].asClass}>
            <Icon name={locked ? "LockSimple" : "LockSimpleOpen"} />
        </button>
    )
}

export default LockButton
