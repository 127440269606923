import { Dispatch, SetStateAction, createContext } from "react";
import { Supplier } from "types/suppliers";

export interface SuppliersContextType {
    suppliers: Supplier[] | undefined
    setSuppliers: Dispatch<SetStateAction<Supplier[] | undefined>>
    loading: boolean
    loadingNextPage: boolean
    loadNextPage: () => void
    hasMore: boolean
}

const SuppliersContext = createContext<SuppliersContextType>({} as SuppliersContextType)

export default SuppliersContext