import { FirebaseOptions, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { getMessaging } from 'firebase/messaging'
import { isDev, isLocal } from 'config/environment'

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyB3xRiacbv3SnxHfTPtuVLLLltVFDgCILI",
  authDomain: "apex-blue.firebaseapp.com",
  projectId: "apex-blue",
  storageBucket: "apex-blue.appspot.com",
  messagingSenderId: "721064355514",
  appId: "1:721064355514:web:fea62bec99b503a9af0483",
  measurementId: "G-YYGEGD88Z4"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
isLocal && isDev && connectAuthEmulator(auth, 'http://localhost:9099')
const db = getFirestore(app)
isLocal && isDev && connectFirestoreEmulator(db, 'localhost', 8080)
const storage = getStorage(app)
isLocal && isDev && connectStorageEmulator(storage, 'localhost', 9199)
const messaging = getMessaging(app)

const firebase = { app, auth, db, storage, messaging }

export default firebase
