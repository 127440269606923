import expensesApi from 'api/expenses'
import { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react'
import { IExpense, IExpenseBreakdown, IExpenseBreakdowns } from 'types/expenses'
import { frontendFilter } from './frontendFilter'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { expenseCategories } from 'constants/badgeSelect'

const useExpenses = () => {
  const [_availableCategories, setAvailableCategories] = useState<string[]>()
  const [unfilteredBreakdowns, setBreakdowns] = useState<IExpenseBreakdowns>()
  const [unfilteredExpenses, setExpenses] = useState<IExpense[]>()
  const [loading, setLoading] = useState(false)
  const {period} = useFilteringContext()

  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const [nextPage, setNextPage] = useState<string | null>(null)
  const queries = useRef<string[]>([])

  useEffect(() => {
    expensesApi.getBreakdowns(period).then(setBreakdowns)
  }, [period])

  useEffect(() => {
    setLoading(true)
    const queryKey = JSON.stringify(period)
    expensesApi
      .getExpenses(period)
      .then((res) => {
        setExpenses(res.expenses)
        setAvailableCategories(res.categories)
        queries.current = [queryKey]
        setNextPage(res.next)
      })
      .finally(() => setLoading(false))
  }, [period])

  const loadNext = useCallback(() => {
    if (loading || loadingNextPage || !nextPage) return
    setLoadingNextPage(true)
    const queryKey = JSON.stringify({ ...period, page: nextPage })
    if (queries.current.includes(queryKey)) return
    queries.current.push(queryKey)
    expensesApi
      .getExpenses({...period, next: nextPage})
      .then((res) => {
        setExpenses((prev) => [...(prev || []), ...res.expenses])
        setAvailableCategories(res.categories)
        setNextPage(res.next)
      })
      .catch(() => {
        queries.current = queries.current.filter((q) => q !== queryKey)
      })
      .finally(() => setLoadingNextPage(false))
  }, [loadingNextPage, nextPage, loading, period])

  const { opFilters: filters, search, ordering } = useFilteringContext()

  const expensesSorted = useMemo(() => {
    if (!unfilteredExpenses) return
    const filteredExpenses = unfilteredExpenses.filter((a) => {
      const aDate = new Date(a.dateOfExpense)
      const sinceDate = period.since ? new Date(period.since) : undefined
      if (!sinceDate) return true
      const untilDate = period.until ? new Date(period.until) : undefined
      if (!untilDate) return aDate >= sinceDate
      return aDate >= sinceDate && aDate < untilDate
    })
    const sortByDate = (a: IExpense, b: IExpense) => new Date(b.dateOfExpense).getTime() - new Date(a.dateOfExpense).getTime()
    const sortByCreatedAt = (a: IExpense, b: IExpense) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    const sortByGroup = (a: IExpense, b: IExpense) => {
      const monthYearOne = a.dateOfExpense.split('-').slice(0, 2).join('-')
      const monthYearTwo = b.dateOfExpense.split('-').slice(0, 2).join('-')
      return monthYearTwo.localeCompare(monthYearOne)
    }
    return frontendFilter(filteredExpenses, { filters, search, ordering }, [sortByDate, sortByCreatedAt]).slice(0).sort(sortByGroup)
  }, [unfilteredExpenses, period, filters, search, ordering])

  const expenses = useDeferredValue(expensesSorted)

  const availableCategories = [...expenseCategories, ..._availableCategories || []].dedupe()

  const breakdowns = useMemo(() => {
    if (!unfilteredBreakdowns?.breakdowns?.length) return
    const out = [] as (IExpenseBreakdown & {since: string, until: string})[]
    const buckets = unfilteredBreakdowns.buckets
    for (let i = 0; i < buckets.length; i++) {
      const since = new Date(buckets[i])
      const nextBucket = buckets[i + 1]
      const until = nextBucket ? new Date(nextBucket) : new Date()
      const period = buckets[i]
      const startPretty = since.toLocaleDateString('en-US').split("/").slice(0,2).join("/")
      const [endMonth, endDay, endYear] = until.toLocaleDateString('en-US').split("/")
      const endPretty = [endMonth, endDay].join("/")
      const prettyPeriod = startPretty + " - " + endPretty + "\n" + endYear
      const breakdowns = unfilteredBreakdowns.breakdowns.filter((b) => b.period === period)
      if (breakdowns.length === 0) {
        out.push({
          category: "",
          period: prettyPeriod,
          amount: 0,
          since: since.toISOString(),
          until: until.toISOString(),
        })
      }
      for (const breakdown of breakdowns) {
        out.push({
            since: since.toISOString(),
            until: until.toISOString(),
            ...breakdown,
            period: prettyPeriod,
        })
      }
    }
    return out
  }, [unfilteredBreakdowns])
  return { expenses, setExpenses, loading, loadingNextPage, loadNext, availableCategories, setAvailableCategories, hasMore: !!nextPage, breakdowns, setBreakdowns }
}

export default useExpenses
