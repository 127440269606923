import { useEffect, useMemo, useState } from 'react'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import pnlApi from 'api/pnl'
import { IPnLParameter, IPnLResponseDataPoint, IPnLResponseOtherDataPoint } from 'types/pnl'
import { usePnlSync } from 'contexts/PnlSync'

type Key = (string | null)[]

const otherValueFilter = (data: IPnLResponseOtherDataPoint, key: Key) => {
  for (let i = 0; i < key.length; i++) {
    if (key[i] === null) continue
    if (data.key[i] !== key[i]) return false
  }
  return true
}

const selectors = {
  adjustments: {
    clawback: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'adjustment', 'Refund', 'COMPENSATED_CLAWBACK', '-']),
    supportError: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'adjustment', 'Refund', 'CS_ERROR_ITEMS', '+']),
    freeReplacement: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'adjustment', 'Refund', 'FREE_REPLACEMENT_REFUND_ITEMS', '+']),
    inboundMissing: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'adjustment', 'Refund', 'MISSING_FROM_INBOUND', '+']),
    lostRemoval: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'adjustment', 'Refund', 'REMOVAL_ORDER_LOST', '+']),
    reversal: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'adjustment', 'Refund', 'REVERSAL_REIMBURSEMENT', '+']),
    warehouseDamage: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'adjustment', 'Refund', 'WAREHOUSE_DAMAGE', '+']),
    warehouseDamageException: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'adjustment', 'Refund', 'WAREHOUSE_DAMAGE_EXCEPTION', '+']),
    warehouseLost: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'adjustment', 'Refund', 'WAREHOUSE_LOST', '+']),
    warehouseLostManual: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'adjustment', 'Refund', 'WAREHOUSE_LOST_MANUAL', '+']),
    shippingChargeRefund: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'shipment-item', 'ShippingCharge', null, '-']),
    giftwrapChargeback: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'shipment-item', 'GiftwrapChargeback', null, '-']),
  },
  liquidations: {
    fees: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'removal-shipment-item', 'Refund', 'WHOLESALE_LIQUIDATION', '-']),
    refund: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'removal-shipment-item', 'Refund', 'WHOLESALE_LIQUIDATION', '+']),
    removalFee: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'removal-shipment-item', 'RemovalFee', null, '-']),
  },
  loans: {
    advance: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'loan-servicing', 'Charge', 'LoanAdvance', '+']),
    payment: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'loan-servicing', 'Charge', 'LoanPayment', '-']),
  },
  ads: {
    base: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'product-ads', 'Charge', null, '-']),
  },
  fba: {
    restocking: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['charge', 'shipment-item', 'ShippingCharge', null, '+']),
    couponRedemption: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'coupon-payment', 'CouponRedemptionFee', null, '-']),
    disposalFee: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'service-fee', 'FBADisposalFee', null, '-']),
    inboundShipmentCartonLevelInfoFee: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'service-fee', 'FBAInboundShipmentCartonLevelInfoFee', null, '-']),
    inboundTransportationFee: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'service-fee', 'FBAInboundTransportationFee', null, '-']),
    longTermStorageFee: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'service-fee', 'FBALongTermStorageFee', null, '-']),
    removalFee: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'service-fee', 'FBARemovalFee', null, '-']),
    storageFee: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'service-fee', 'FBAStorageFee', null, '-']),
    subscription: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'service-fee', 'Subscription', null, '-']),
    refundCommission: (data: IPnLResponseOtherDataPoint) => otherValueFilter(data, ['fee', 'shipment-item', 'RefundCommission', null, '-']),
  },
}

type NestedSelect<Key extends keyof typeof selectors> = {
  [K in keyof (typeof selectors)[Key]]: {
    value: number
    quantity: number
    events: number
  }
}

type SelectorKeys = keyof typeof selectors

type SelectedType = {
  [K in SelectorKeys]: NestedSelect<K>
}

type SelectedTypeEntries<Key extends SelectorKeys = SelectorKeys> = [Key, keyof NestedSelect<Key>]

const selectFromOther = (data: IPnLResponseOtherDataPoint[]) => {
  const zero = (Object.entries(selectors) as SelectedTypeEntries[]).reduce((acc, [key, value]) => {
    // @ts-ignore
    acc[key] = Object.entries(value).reduce((acc, [k, v]) => {
      // @ts-ignore
      acc[k] = {
        value: 0,
        quantity: 0,
        events: 0,
      }
      return acc
    }, {} as NestedSelect<typeof key>)
    return acc
  }, {} as SelectedType)
  return data.reduce((acc, d) => {
    for (const key in selectors) {
      for (const subKey in selectors[key as SelectorKeys]) {
        // @ts-ignore
        if (selectors[key][subKey](d)) {
          // @ts-ignore
          acc[key][subKey].value += d.value
          // @ts-ignore
          acc[key][subKey].quantity += d.quantity
          // @ts-ignore
          acc[key][subKey].events += d.events
        }
      }
    }
    return acc
  }, zero)
}

const compileDashboard = (data: IPnLResponseDataPoint, expenseCategories: string[]) => {
  const revenue = data.revenue
  const units = data.units
  // per order item fees
  const amazonFees = data.perUnitFee + data.referralFee + data.giftWrap + data.variableClosingFee + data.shippingChargeback

  const promotions = data.shippingPromotionDiscount + data.promotionDiscount

  const grossMarginTotal = data.revenue - data.costOfGoods - amazonFees - promotions
  const grossMarginPercentage = grossMarginTotal / data.revenue
  const grossProfitPerUnit = grossMarginTotal / units
  const grossProfitPerOrder = grossMarginTotal / data.orders

  const taxes = -data.shippingTax - data.giftWrapTax + data.tax

  const averageUnitPrice = revenue / units
  const averageOrderSize = units / data.orders
  const averageOrderValue = revenue / data.orders

  const other = selectFromOther(data.other)
  const liquidationFees = -other.liquidations.fees.value
  const liquidationRemovalFees = -other.liquidations.removalFee.value
  const liquidationRevenue = other.liquidations.refund.value
  const liquidationTotal = other.liquidations.fees.value + other.liquidations.removalFee.value + liquidationRevenue

  const clawback = other.adjustments.clawback.value
  const supportError = other.adjustments.supportError.value
  const freeReplacement = other.adjustments.freeReplacement.value
  const inboundMissing = other.adjustments.inboundMissing.value
  const lostRemoval = other.adjustments.lostRemoval.value
  const reversal = other.adjustments.reversal.value
  const warehouseDamage = other.adjustments.warehouseDamage.value
  const warehouseDamageException = other.adjustments.warehouseDamageException.value
  const warehouseLost = other.adjustments.warehouseLost.value
  const warehouseLostManual = other.adjustments.warehouseLostManual.value
  const shippingChargeRefund = other.adjustments.shippingChargeRefund.value
  const giftwrapChargeback = other.adjustments.giftwrapChargeback.value

  const ads = other.ads.base.value

  // not sure how to use these
  const advance = other.loans.advance.value
  const payment = other.loans.payment.value

  // fba fees
  const restocking = other.fba.restocking.value
  const couponRedemption = -other.fba.couponRedemption.value
  const disposalFee = -other.fba.disposalFee.value
  const inboundShipmentCartonLevelInfoFee = -other.fba.inboundShipmentCartonLevelInfoFee.value
  const inboundTransportationFee = -other.fba.inboundTransportationFee.value
  const longTermStorageFee = -other.fba.longTermStorageFee.value
  const removalFee = -other.fba.removalFee.value
  const storageFee = -other.fba.storageFee.value
  const subscription = -other.fba.subscription.value
  const refundCommission = -other.fba.refundCommission.value
  const totalFba =
    restocking + couponRedemption + disposalFee + inboundShipmentCartonLevelInfoFee + inboundTransportationFee + longTermStorageFee + removalFee + storageFee + subscription + refundCommission

  const revenueParameter: IPnLParameter = {
    parameter: 'Sales Revenue',
    type: 'currency',
    value: revenue,
  }

  const unitsParameter: IPnLParameter = {
    parameter: 'Units Sold',
    type: 'number',
    value: units,
    breakdowns: [
      {
        parameter: 'Average Unit Price',
        type: 'currency',
        value: averageUnitPrice,
      },
      {
        parameter: 'Average Order Size',
        value: averageOrderSize,
      },
      {
        parameter: 'Average Order Value',
        type: 'currency',
        value: averageOrderValue,
      },
      {
        parameter: 'Orders Sold',
        value: data.orders,
      },
    ],
  }

  const amazonFeesParameter: IPnLParameter = {
    parameter: 'Amazon Fees',
    type: 'currency',
    value: amazonFees,
    breakdowns: [
      {
        parameter: 'FBA Fee',
        value: data.perUnitFee,
      },
      {
        parameter: 'Referral Fee',
        value: data.referralFee,
      },
      {
        parameter: 'Variable Closing Fee',
        value: data.variableClosingFee,
      },
      {
        parameter: 'Shipping Chargeback',
        value: data.shippingChargeback,
      },
      {
        parameter: 'Gift Wrap',
        value: data.giftWrap,
      },
    ],
  }

  const costOfGoodsParameter: IPnLParameter = {
    parameter: 'Cost of Goods Sold',
    type: 'currency',
    value: data.costOfGoods,
  }

  const promotionsParameter: IPnLParameter = {
    parameter: 'Promotions',
    type: 'currency',
    value: promotions,
    breakdowns: [
      {
        parameter: 'Shipping Promotion Discount',
        value: data.shippingPromotionDiscount,
      },
      {
        parameter: 'Promotion Discount',
        value: data.promotionDiscount,
      },
    ],
  }

  const grossMarginParameter: IPnLParameter = {
    parameter: 'Gross Profit',
    type: 'currency',
    value: grossMarginTotal,
    breakdowns: [
      {
        parameter: 'Gross Margin',
        type: 'percentage',
        value: grossMarginPercentage,
      },
      {
        parameter: 'Gross Profit per Unit',
        type: 'currency',
        value: grossProfitPerUnit,
      },
      {
        parameter: 'Gross Profit per Order',
        type: 'currency',
        value: grossProfitPerOrder,
      },
    ],
  }

  const vatParameter: IPnLParameter = {
    parameter: 'VAT',
    type: 'currency',
    value: taxes,
    breakdowns: [
      {
        parameter: 'Sales Tax',
        value: data.tax,
      },
      {
        parameter: 'Shipping Tax',
        value: -data.shippingTax,
      },
      {
        parameter: 'Gift Wrap Tax',
        value: -data.giftWrapTax,
      },
    ],
  }

  const adjustmentsParameter: IPnLParameter = {
    parameter: 'Adjustments',
    type: 'currency',
    value:
      clawback +
      supportError +
      freeReplacement +
      inboundMissing +
      lostRemoval +
      reversal +
      warehouseDamage +
      warehouseDamageException +
      warehouseLost +
      warehouseLostManual +
      shippingChargeRefund +
      giftwrapChargeback,
    breakdowns: [
      {
        parameter: 'Clawback',
        value: clawback,
      },
      {
        parameter: 'Support Error',
        value: supportError,
      },
      {
        parameter: 'Free Replacement',
        value: freeReplacement,
      },
      {
        parameter: 'Inbound Missing',
        value: inboundMissing,
      },
      {
        parameter: 'Lost Removal',
        value: lostRemoval,
      },
      {
        parameter: 'Reversal',
        value: reversal,
      },
      {
        parameter: 'Warehouse Damage',
        value: warehouseDamage,
      },
      {
        parameter: 'Warehouse Damage Exception',
        value: warehouseDamageException,
      },
      {
        parameter: 'Warehouse Lost',
        value: warehouseLost,
      },
      {
        parameter: 'Warehouse Lost Manual',
        value: warehouseLostManual,
      },
      {
        parameter: 'Shipping Charge Refund',
        value: shippingChargeRefund,
      },
      {
        parameter: 'Giftwrap Chargeback',
        value: giftwrapChargeback,
      },
    ],
  }

  const adsParameter: IPnLParameter = {
    parameter: 'Ads',
    type: 'currency',
    value: -ads,
  }

  const loansParameter: IPnLParameter = {
    parameter: 'Loans',
    type: 'currency',
    value: advance + payment,
    breakdowns: [
      {
        parameter: 'Advance',
        value: advance,
      },
      {
        parameter: 'Payment',
        value: payment,
      },
    ],
  }

  const liquidationsParameter: IPnLParameter = {
    parameter: 'Liquidations',
    type: 'currency',
    value: liquidationTotal,
    breakdowns: [
      {
        parameter: 'Fees',
        value: liquidationFees,
      },
      {
        parameter: 'Removal Fees',
        value: liquidationRemovalFees,
      },
      {
        parameter: 'Revenue',
        value: liquidationRevenue,
      },
    ],
  }

  const fbaParameter: IPnLParameter = {
    parameter: 'Other FBA Costs',
    type: 'currency',
    value: totalFba,
    breakdowns: [
      {
        parameter: 'Restocking',
        value: restocking,
      },
      {
        parameter: 'Coupon Redemption',
        value: couponRedemption,
      },
      {
        parameter: 'Disposal Fee',
        value: disposalFee,
      },
      {
        parameter: 'Inbound Shipment Carton Level Info Fee',
        value: inboundShipmentCartonLevelInfoFee,
      },
      {
        parameter: 'Inbound Transportation Fee',
        value: inboundTransportationFee,
      },
      {
        parameter: 'Long Term Storage Fee',
        value: longTermStorageFee,
      },
      {
        parameter: 'Removal Fee',
        value: removalFee,
      },
      {
        parameter: 'Storage Fee',
        value: storageFee,
      },
      {
        parameter: 'Subscription',
        value: subscription,
      },
      {
        parameter: 'Refund Commission',
        value: refundCommission,
      },
    ],
  }

  // expenses
  const expensesParameter: IPnLParameter = expenseCategories.reduce(
    (acc, category) => {
      const value = data.expenses?.find((e) => e.category === category)?.value || 0
      acc.breakdowns.push({
        parameter: category,
        type: 'currency',
        value,
      })
      acc.value += value
      return acc
    },
    {
      parameter: 'Expenses',
      type: 'currency',
      value: 0,
      breakdowns: [] as IPnLParameter[],
    }
  )

  const productionCostPerUnitSold = {
    parameter: 'Production Cost per Unit Sold',
    type: 'currency',
    value: expensesParameter.value / units,
    breakdowns: expensesParameter.breakdowns?.map((b) => ({
      ...b,
      value: b.value / units,
    })),
  }

  return [
    revenueParameter,
    unitsParameter,
    amazonFeesParameter,
    costOfGoodsParameter,
    promotionsParameter,
    vatParameter,
    grossMarginParameter,
    adjustmentsParameter,
    adsParameter,
    loansParameter,
    liquidationsParameter,
    fbaParameter,
    expensesParameter,
    productionCostPerUnitSold,
  ]
}

const usePnL = () => {
  const [dashboard, setDashboard] = useState<IPnLResponseDataPoint[]>()
  const [loading, setLoading] = useState(false)
  const syncState = usePnlSync()
  const { period } = useFilteringContext()

  useEffect(() => {
    if (!syncState.synced) return
    if (dashboard) return
    setLoading(true)
    pnlApi
      .getDashboard(period)
      .then((res) => {
        setDashboard(res)
      })
      .finally(() => setLoading(false))
  }, [syncState.synced, period, dashboard])

  const data = useMemo(() => {
    if (!dashboard) return []
    const expenseCategories = new Set<string>()
    dashboard.forEach((d) => {
      d.expenses.forEach((e) => {
        expenseCategories.add(e.category)
      })
    })
    const sorted = dashboard.slice().sort((a, b) => b.month.localeCompare(a.month))
    const total = sorted.reduce(
      (acc, d) => {
        acc.revenue += d.revenue || 0
        acc.units += d.units || 0
        acc.orders += d.orders || 0
        acc.costOfGoods += d.costOfGoods || 0
        acc.perUnitFee += d.perUnitFee || 0
        acc.referralFee += d.referralFee || 0
        acc.variableClosingFee += d.variableClosingFee || 0
        acc.shippingChargeback += d.shippingChargeback || 0
        acc.giftWrap += d.giftWrap || 0
        acc.shippingPromotionDiscount += d.shippingPromotionDiscount || 0
        acc.promotionDiscount += d.promotionDiscount || 0
        acc.tax += d.tax || 0
        acc.shippingTax += d.shippingTax || 0
        acc.giftWrapTax += d.giftWrapTax || 0
        acc.other = d.other.reduce((acc, other) => {
          const found = acc.find((a) => a.key.join('-') === other.key.join('-'))
          if (found) {
            found.value += other.value
            found.quantity += other.quantity
            found.events += other.events
          } else {
            acc.push({ ...other })
          }
          return acc
        }, acc.other.map((a) => ({ ...a })) as IPnLResponseOtherDataPoint[])
        acc.expenses = d.expenses.reduce((acc, e) => {
          const found = acc.find((a) => a.category === e.category)
          if (found) {
            found.value += e.value
          } else {
            acc.push({ ...e })
          }
          return acc
        }, acc.expenses.map((a) => ({ ...a })) as { category: string; value: number }[])
        return acc
      },
      {
        revenue: 0,
        units: 0,
        orders: 0,
        costOfGoods: 0,
        perUnitFee: 0,
        referralFee: 0,
        variableClosingFee: 0,
        shippingChargeback: 0,
        giftWrap: 0,
        shippingPromotionDiscount: 0,
        promotionDiscount: 0,
        tax: 0,
        shippingTax: 0,
        giftWrapTax: 0,
        other: [] as IPnLResponseOtherDataPoint[],
        expenses: [] as { category: string; value: number }[],
      } as IPnLResponseDataPoint
    )
    const dashboardsDataByMonth = [
      ...sorted.map((d, i) => {
        const [year, month] = d.month.split('-')
        const prettyDate = new Date(Number(year), Number(month) - 1).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })
        const compiled = compileDashboard(d, Array.from(expenseCategories))
        return {
          month: prettyDate,
          data: compiled,
        }
      }),
    ]

    const totalCompiled = compileDashboard(total, Array.from(expenseCategories))
    dashboardsDataByMonth.unshift({
      month: 'Total',
      data: totalCompiled,
    })

    return [...dashboardsDataByMonth]
  }, [dashboard])

  return { dashboard, loading, setDashboard, data, syncState }
}

export default usePnL
