import { FC, Fragment, SVGAttributes, useMemo } from 'react'

export type Piece = {
  name: string | number
  value: number
  color: string
}

interface props extends Omit<SVGAttributes<SVGSVGElement>, 'width' | 'height' | 'viewBox'> {
  pieces?: Piece[]
}

const emptyPieces = [
  { name: 'a', value: 10, color: '#EDEBF0' },
  { name: 'b', value: 20, color: '#DBDBDB' },
  { name: 'c', value: 15, color: '#EDEBF0' },
  { name: 'd', value: 30, color: '#DBDBDB' },
] as Piece[]

const Pie: FC<props> = ({ pieces = emptyPieces, className, ...svgProps }) => {
  const pieId = svgProps.id || 'pie'
  const size = 164
  const outerWidth = 30
  const piecesWidth = 30
  const radius = (size - outerWidth) / 2
  const center = size / 2

  const gap = 0 // piecesWidth * 2

  const circleLength = 2 * Math.PI * radius
  const availableLength = circleLength - gap * pieces.length

  const piecesConfig = useMemo(() => {
    const piecesLength = pieces.length
    const half = Math.floor(piecesLength / 2)

    const totalMaybeZero = pieces.reduce((acc, curr) => acc + curr.value, 0)
    const total = totalMaybeZero || piecesLength

    const left = pieces.slice(0, half)
    const leftSize = left.reduce((acc, curr) => acc + curr.value, 0)
    const leftMidPoint = !totalMaybeZero ? piecesLength / 4 : leftSize / 2

    const offsetAngle = (-leftMidPoint / total) * 360
    let currentAngle = offsetAngle

    return pieces.map((piece, i) => {
      const id = `pie-${pieId}-${piece.name}`
      const percentage = totalMaybeZero ? piece.value / total : 1 / piecesLength
      const pieceLength = percentage * availableLength
      const initialRotation = currentAngle
      const angle = ((pieceLength + gap) / circleLength) * 360
      currentAngle += angle

      return { id, percentage: !totalMaybeZero ? 0 : percentage, initialRotation, pieceLength, color: piece.color, angle }
    })
  }, [pieces, pieId, availableLength, gap, circleLength])

  return (
    <svg {...svgProps} width={size} height={size} viewBox={`0 0 ${size} ${size}`} className={['!aspect-square shrink-0 transition-all overflow-visible z-50', className].asClass}>
      <circle cx={center} cy={center} r={radius} stroke="rgba(248,248,255,1)" strokeWidth={outerWidth} fill="transparent" />
      {piecesConfig.map((piece, i) => {
        const rotation = piece.initialRotation
        const pieceLength = piece.pieceLength

        const dashOffset = 0

        const dashArray = [pieceLength, circleLength - pieceLength]

        return (
          <circle
            key={piece.id}
            data-piece={i}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={piecesWidth}
            stroke={piece.color}
            fill="transparent"
            className="transition-all rotate-[0deg]"
            strokeDashoffset={dashOffset}
            strokeDasharray={dashArray.join(' ')}
            style={{
              transform: `rotate(${rotation}deg)`,
              transformOrigin: 'center',
              filter: piece.percentage === 0 ? 'grayscale(1)' : undefined,
            }}
          />
        )
      })}
      {piecesConfig.map((piece, i) => {
        const rotation = piece.initialRotation
        const pieceLength = piece.pieceLength

        const midPointRotation = Math.PI * 2 * (rotation + (pieceLength / (radius * 2 * Math.PI)) * 180) / 360
        
        const tooltipX = Math.cos(midPointRotation) * radius + radius + piecesWidth /2
        const tooltipY = Math.sin(midPointRotation) * radius + radius

        const dashOffset = 0

        const dashArray = [pieceLength, circleLength - pieceLength]

        const name = piece.id.split("-").slice(2).join("-")

        return (
          <Fragment key={piece.id}>
                <rect x={0} y={0} width={size} height={size} fill="none" />
                <circle
                  data-piece={i}
                  cx={center}
                  cy={center}
                  r={radius}
                  strokeWidth={piecesWidth}
                  stroke={piece.color}
                  fill="none"
                  className="transition-all rotate-[0deg]"
                  strokeDashoffset={dashOffset}
                  strokeDasharray={dashArray.join(" ")}
                  style={{
                    pointerEvents: "visibleStroke",
                    transform: `rotate(${rotation}deg)`,
                    transformOrigin: "center",
                  }}
                />
                <circle
                  key={i}
                  data-piece={i}
                  cx={center}
                  cy={center}
                  r={radius}
                  strokeWidth={piecesWidth + 8}
                  stroke={piece.color + "55"}
                  fill="none"
                  className="transition-all rotate-[0deg] opacity-0 hover:opacity-100 [&:hover+*]:opacity-100"
                  strokeDashoffset={dashOffset}
                  strokeDasharray={dashArray.join(" ")}
                  style={{
                    pointerEvents: "visibleStroke",
                    transform: `rotate(${rotation}deg)`,
                    transformOrigin: "center",
                  }}
                />
                <foreignObject
                   x={0} y={0} className="overflow-visible transition-colors opacity-0 relative" pointerEvents="none" width={size} height={size}
                   >
                    <div
                    style={{
                        top: tooltipY,
                        left: tooltipX
                    }}
                    className="text-xs text-white rounded py-1 px-2 font-medium bg-slate-950 absolute z-50 -translate-x-1/2 translate-y-1/4 w-max">
                        {name} - {(piece.percentage * 100).toFixed(2)}%
                    </div>
                </foreignObject>
          </Fragment>
        )
      })}
    </svg>
  )
}

export default Pie
