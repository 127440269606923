import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { AsinEntryItem, IShippingCost } from 'types/housedAsins'

const UpdateShippingCostsModal: AwaitableModal<AsinEntryItem> = ({ open, resolve }) => {
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState<Record<keyof IShippingCost, any>>()
  const navigate = useNavigate()

  useEffect(() => {
    if (!open) return setValues(undefined)
    housedAsinsApi.getShippingCost().then(setValues)
  }, [open])

  const handleSubmit = async () => {
    if (submitting) return
    if (!values) return
    setSubmitting(true)
    const item = {
      ...values,
      cost: parseFloat(values.cost),
    }
    housedAsinsApi
      .updateShippingCost({ ...item })
      .then(() => navigate(0))
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="flex items-start justify-start p-6 pb-4 gap-2">
            <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
            <div className="flex flex-col items-start gap-1">
              <span className="text-base text-text-primary font-medium">Shipping Cost</span>
              <span className="text-sm text-text-secondary">Add your shipping cost information.</span>
            </div>
          </div>
          <div className={['flex flex-col bg-surface-primary px-6', !values && '[&_input]:animate-pulse [&_select]:animate-pulse pointer-events-none'].asClass}>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Charge Type</span>
                <select className="border border-border-primary py-2.5 px-2 rounded-lg outline-none" value={values?.type} onChange={(e) => setValues((prev) => (prev && { ...prev, type: e.target.value }))}>
                  <option value="per_item">Per Unit</option>
                  <option value="per_pound">Per Pound</option>
                </select>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Cost</span>

                <div className="flex w-full rounded border border-border-primary">
                  <div className="border-r border-r-border-primary p-3 bg-surface-light">
                    <Icon name="Dollar" />
                  </div>
                  <input type="text" value={values?.cost} onChange={(e) => setValues((prev) => (prev && { ...prev, cost: e.target.value }))} className="p-1 pl-3.5" placeholder="0.00" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 p-4 w-full">
            <button className="button-secondary grow" disabled={submitting} onClick={() => resolve()}>
              Cancel
            </button>
            <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateShippingCostsModal
