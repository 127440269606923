import { IApiFilter, IOperatorFilter } from "types/tableFiltering";

const valueIsValid = <T = unknown>(value: T, opFilter: IOperatorFilter) => {
    switch (opFilter.o) {
        case '=':
            return value === opFilter.v
        case '!=':
            return value !== opFilter.v
        case '>':
            return value > opFilter.v
        case '<':
            return value < opFilter.v
        case '>=':
            return value >= opFilter.v
        case '<=':
            return value <= opFilter.v
        case 'IN':
            const opVal = opFilter.v as T[]
            return opVal.includes(value)
        case 'NOT IN':
            const v = opFilter.v as T[]
            return !v.includes(value)
        case 'LIKE':
            return (value as string).includes(opFilter.v as string)
        case 'ILIKE':
            return (value as string).toLowerCase().includes((opFilter.v as string).toLowerCase())
        case 'NOT LIKE':
            return !(value as string).includes(opFilter.v as string)
        case 'NOT ILIKE':
            return !(value as string).toLowerCase().includes((opFilter.v as string).toLowerCase())
        default:
            return false
    }
}

type SortFn = (a: any, b: any) => number

export const frontendFilter = <T extends Record<string, any>[] = Record<string, any>[]>(data: T, filter: IApiFilter, additionalSorts?: SortFn[]) => {
    const {search, ordering, filters} = filter
    let result = data
    if (search) {
        result = result.filter((item) => {
            return Object.values(item).some((value) => {
                if (typeof value === 'string' || typeof value === 'number') {
                    return value.toString().toLowerCase().includes(search.toLowerCase())
                }
                return false
            })
        }) as T
    }
    if (filters) {
        result = result.filter((item) => {
            return Object.entries(filters).every(([key, opFilter]) => {
                return valueIsValid(item[key], opFilter)
            })
        }) as T
    }
    if (ordering) {
        result = result.slice(0).sort((a: any, b: any) => {
            const isNumber = !isNaN(Number(a[ordering.by]))
            let sort = 0
            if (!isNumber) {
                sort = a[ordering.by].localeCompare(b[ordering.by]) * ordering.direction
            }
            if (isNumber) {
                sort = (Number(a[ordering.by]) - Number(b[ordering.by])) * ordering.direction
            }
            for (const sortFn of additionalSorts || []) {
                if (sort !== 0) break
                sort = sort || sortFn(a, b)
            }
            return sort
        }) as T
    }
    return result
} 