import { useState, FC, PropsWithChildren, useEffect } from "react"
import SearchContext from "./searchContext"
import { useLocation } from "react-router"

const SearchProvider: FC<PropsWithChildren> = ({ children }) => {
  const [search, setSearch] = useState<string>('')
  const [searching, setSearching] = useState<boolean>(false)
  const path = useLocation().pathname

  useEffect(() => {
    setSearching(false)
    setSearch('')
  }, [path])

  return (
    <SearchContext.Provider value={{ search, searching, setSearch, setSearching}}>
        {children}
    </SearchContext.Provider>
  )
}

export default SearchProvider