import { FC, FormEvent, useState } from 'react'
import { AsinEntryItem } from 'types/housedAsins'
import Icon from 'assets/icons/iconset'
import housedAsinsApi from 'api/housedAsins'
import TD from 'components/tables/TD'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import BadgeSelect from 'components/BadgeSelect'
import { IPrepCost } from 'types/prepCosts'
import { usNumber } from 'utils/formatting'

interface props {
  item: AsinEntryItem
  onDone: (supplier?: AsinEntryItem) => void
  onRemove: (id: string) => void
  selected?: boolean
  handleSelect?: (id: string) => void
}

const Item: FC<props> = ({ onDone, item }) => {
  const table = useTableContext()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({
    ...item,
  } as AsinEntryItem)
  const [editingWebsite, setEditingWebsite] = useState(false)
  const { locked, extra } = table.table
  const { supplierNames, prepCosts } = extra || {}
  const availableBuyers = extra?.availableBuyers || []

  const onSubmit = (e?: FormEvent, overrides?: Partial<AsinEntryItem>) => {
    if (locked) return false
    e?.preventDefault()
    if (submitting) return
    setSubmitting(true)
    housedAsinsApi
      .updateAsinEntry(item.id, { ...values, ...overrides })
      .then(() => {
        onDone({ ...values })
        setEditingWebsite(false)
      })
      .finally(() => setSubmitting(false))
    return false
  }

  const onValueChange = (key: string, value: any) => {
    return () => {
      if (locked) return
      setValues((old) => ({ ...old, [key]: value }))
    }
  }

  const isChecked = !!table.table.select?.selected.includes(item.id)
  const allSelected = !!table.table.select?.allSelected

  return (
    <tr>
      {!!table.table.select && (
        <TD onClick={() => table.table.select?.onSelectClick(item.id)}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </TD>
      )}
      <TD>
        <div className="flex items-center gap-2">
          {values.amzLink && (!editingWebsite || submitting || locked) ? (
            <a href={values.amzLink} target="_blank" rel="noreferrer">
              <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          ) : (
            <input type="text" className='table-input' readOnly={locked} value={values.amzLink} onBlur={onSubmit} onChange={(e) => onValueChange('amzLink', e.target.value)()} />
          )}
          {values.amzLink && !editingWebsite && !locked && (
            <button className="p-0.5 rounded-lg hover:bg-surface-primary group transition-colors hover:text-text-primary" onClick={() => setEditingWebsite(true)}>
              <Icon name="CaretDoubleLeft" className="group-hover:opacity-100 opacity-0 transition-all w-4 h-4" />
            </button>
          )}
        </div>
      </TD>
      <TD onBlur={(e) => e.stopPropagation()} className="!min-w-[8rem]">
        <BadgeSelect
          selected={values.supplier || ''}
          badges={supplierNames}
          onSelect={(supplier) => {
            setValues((old) => ({ ...old, supplier }))
            onSubmit(undefined, { supplier })
          }}
          editable={!locked && !submitting}
        />
      </TD>
      <TD>
        <div className="relative">
          <input
            type="text"
            readOnly={locked}
            value={values.currentCost}
            onBlur={onSubmit}
            onChange={(e) => onValueChange('currentCost', Number(e.target.value))()}
            placeholder="0.00"
            className="!pl-5 table-input"
          />
          <span className="text-sm text-base-900 p-2 absolute left-0">$</span>
        </div>
      </TD>
      <TD>
        <input readOnly={locked} type="text" className="table-input" placeholder="---" value={values.upc} onBlur={onSubmit} onChange={(e) => onValueChange('upc', e.target.value)()} />
      </TD>
      <TD>
        <input readOnly={locked} type="text" className="table-input" placeholder="---" value={values.vendorSKU} onBlur={onSubmit} onChange={(e) => onValueChange('vendorSKU', e.target.value)()} />
      </TD>
      <TD>
        <input type="text" className="table-input" readOnly={locked} value={values.bundleQty} onBlur={onSubmit} onChange={(e) => onValueChange('bundleQty', Number(e.target.value))()} placeholder="0" />
      </TD>
      <TD>
        <input type="text" className="table-input" readOnly={locked} value={values.caseQty} onBlur={onSubmit} onChange={(e) => onValueChange('caseQty', Number(e.target.value))()} placeholder="0" />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <div className='flex items-center gap-2'>
        <BadgeSelect
          selected={values.prepCost || ''}
          badges={prepCosts?.map((p: IPrepCost) => p.name) || []}
          onSelect={(prepCost) => {
            setValues((old) => ({ ...old, prepCost }))
            onSubmit(undefined, { prepCost })
          }}
          singleColor="blue"
          editable={!locked && !submitting}
        />
        <span>
        ${usNumber(prepCosts?.find((p: IPrepCost) => p.name === values.prepCost)?.amount, 2)}
        </span>
        </div>
      </TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <BadgeSelect
          selected={values.buyer || ''}
          badges={availableBuyers || []}
          onSelect={(buyer) => {
            setValues((old) => ({ ...old, buyer }))
            onSubmit(undefined, { buyer })
          }}
          editable={!locked && !submitting}
        />
      </TD>
      <TD>{item.created_at ? new Date(item.created_at).toLocaleDateString('en-US') : '---'}</TD>
    </tr>
  )
}

export default Item
