import { IColumnDefinition } from "types/table";

const columnDef: IColumnDefinition[] = [
    {
        field: "name",
        title: "Title",
    },
    {
        field: "amount",
        title: "Cost",
    },
]

export default columnDef;