import { IColumnDefinition } from "types/table";
import { IWarehouseInventoryItem } from "types/warehouse";

export const columnDef: IColumnDefinition<IWarehouseInventoryItem & {image: string}>[] = [
    {
        field: "image",
        title: "Image",
    },
    {
        field: "asin",
        title: "ASIN",
        filters: "string",
    },
    {
        field: "title",
        title: "Title",
        filters: "string",
    },
    {
        field: "upc",
        title: "UPC",
        filters: "string",
    },
    {
        field: "units",
        title: "Units",
        filters: "number",
    },
    {
        field: "bundle",
        title: "Bundle Quantity",
        filters: "number",
    },
    {
        field: "sellable",
        title: "Total Sellable Units",
        filters: "number",
    },
    {
        field: "prepCost_name",
        title: "Prep Type",
        filters: "string",
    },
    {
        field: "expirationDate",
        title: "Expiration Date",
        filters: "date",
    }
];