import { FC, useCallback } from 'react'
import Item from './components/Item'
import ContentLayout from 'components/layout/Content'
import Loader from 'components/loaders/Loader'
import Table from 'components/tables/Table'
import { MasterUPCCatalogItem } from 'types/housedAsins'
import useAwaitableModal from 'hooks/useAwaitableModal'
import ListCatalogs from 'layouts/HousedAsins/modals/ListCatalogs'
import columnDef from './tableDef'
import useTitle from 'contexts/Title/useTitle'
import useMasterCatalog from 'hooks/useMasterCatalog'
import withFiltering from 'contexts/Filter/wrapper'

const MasterUPCCatalog: FC = () => {
  const { catalog, importing, loadCatalog, loadingNextPage, importExcel, exportCSV, loadCatalogNextPage } = useMasterCatalog()
  const [listPreviousUploads, ListCatalogsModal] = useAwaitableModal(ListCatalogs, {})
  const uploaded = Boolean(catalog?.length)

  const renderItem = useCallback((item: MasterUPCCatalogItem) => <Item key={item.id} item={item} />, [])

  useTitle("Wholesale Supply Chain")

  return (
    <ContentLayout
      buttons={
        <div className="flex items-center gap-3">
          <button className="button-secondary" onClick={() => listPreviousUploads().then((res) => {
            if (res) loadCatalog()
          })}>
            Previous Uploads
          </button>
          {!!uploaded && (
            <button className="button-secondary" onClick={exportCSV}>
              Export CSV
            </button>
          )}
          {!importing ? (
            <button className="button-secondary" onClick={importExcel}>
              Import CSV
            </button>
          ) : (
            <Loader size={24} />
          )}
        </div>
      }
    >
      <ListCatalogsModal />
      <Table columns={columnDef} items={catalog} renderItem={renderItem} loading={!catalog} loadingNext={loadingNextPage} onBottom={loadCatalogNextPage} />
    </ContentLayout>
  )
}

export default withFiltering(MasterUPCCatalog)
