import Tooltip from 'components/Tooltip'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import Pie, { Piece } from './Pie'

const prettyVal = (num: number) => {
  if (num >= 1000000) return '$' + Math.floor(num / 1000000) + 'M'
  if (num >= 1000) return '$' + (num / 1000).toFixed(1) + 'K'
  if (num === 0) return 0
  return '$' + num.toFixed(1)
}

const colors = [
  { name: 'red', background: '#FEF3F2', text: '#F04438' },
  { name: 'green', background: '#ECFDF3', text: '#027A48' },
  { name: 'blue', background: '#EAF2FF', text: '#0F56CA' },
  { name: 'yellow', background: '#FFF4EA', text: '#F0A30D' },
  { name: 'purple', background: '#F5ECFF', text: '#8F20FF' },
]

interface props {
  breakdowns?: { period: string; amount: number; category: string; since: string; until: string }[]
  availableCategories?: string[]
}

const Graphs: FC<props> = ({breakdowns, availableCategories = []}) => {
  const scrollWrapperRef = useRef<HTMLDivElement>(null)
  const [scrollWrapperMeasure, setScrollWrapperMeasure] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const e = scrollWrapperRef.current
    if (!e) return

    const handler = () => {
      const width = scrollWrapperRef.current?.clientWidth || 0
      const height = scrollWrapperRef.current?.clientHeight || 0
      setScrollWrapperMeasure({ width, height })
    }

    handler()

    const ob = new ResizeObserver(handler)

    ob.observe(e)

    return () => ob.disconnect()
  }, [scrollWrapperRef])

  const [expensesByPeriod, max] = useMemo(() => {
    if (!breakdowns) return [undefined, 0] as const
    const out = [] as { period: string; value: number; since: string; until: string }[]
    for (const breakdown of breakdowns) {
      const existing = out.find((b) => b.period === breakdown.period)
      if (existing) {
        existing.value += breakdown.amount
      } else {
        out.push({ period: breakdown.period, value: breakdown.amount, since: breakdown.since, until: breakdown.until })
      }
    }
    return [out, Math.max(...out.map((o) => o.value), 0) * 1.1] as const
  }, [breakdowns])

  const [categoryBreakdownsPieces, total] = useMemo(() => {
    if (!breakdowns) return [undefined, 0] as const
    const out = [] as Piece[]
    const colorsByCategory = {} as Record<string, string>
    availableCategories.forEach((c, i) => (colorsByCategory[c] = colors[i % colors.length].text))

    let total = 0
    for (const breakdown of breakdowns) {
      if (!breakdown.category) continue
      const existing = out.find((b) => b.name === breakdown.category)
      if (existing) {
        existing.value += breakdown.amount
      } else {
        out.push({ name: breakdown.category, value: breakdown.amount, color: colorsByCategory[breakdown.category] })
      }
      total += breakdown.amount
    }

    return [out.sort((a, b) => a.value - b.value), total] as const
  }, [breakdowns, availableCategories])

  return (
    <div className="grid grid-cols-3 gap-4 h-[27rem]">
      <div className="flex flex-col w-full bg-white col-span-2 rounded-lg border-border-primary border py-4 px-4 gap-4">
        <div className="flex items-center gap-1">
          <span className="text-base font-medium text-text-primary">Expenses Over Time</span>
        </div>
        <div className="flex w-full grow h-full">
          <div className="w-12 h-full" />
          <div className="h-full grow relative w-[calc(100%-3rem)]">
            <div ref={scrollWrapperRef} className="flex gap-2 overflow-x-auto h-full">
              <div style={{ height: scrollWrapperMeasure.height - 24 }} className="flex flex-col w-12 items-end justify-between grow absolute top-0 -translate-x-[3.25rem] -translate-y-2 z-10">
                {Array(6)
                  .fill(null)
                  .map((_, i) => {
                    const val = ((6 - i - 1) * max) / 5
                    return (
                      <span key={`${i}_${val}_${max}`} className="text-xs text-text-secondary font-medium h-4 shrink-0 relative">
                        {prettyVal(val)}
                        <div className="h-px bg-border-secondary top-1/2 absolute left-full translate-x-1" style={{ width: scrollWrapperMeasure.width }} />
                      </span>
                    )
                  })}
              </div>
              {expensesByPeriod?.slice(0).map((e) => {
                return (
                  <div
                    key={e.period}
                    className="flex flex-col z-[10] justify-end grow items-center [&>div]:h-full [&>div]:flex [&>div]:flex-col [&>div]:justify-end"
                  >
                    <Tooltip text={`$${e.value.toLocaleString('en-US')}`} className="z-50" position="bottom">
                      <div className="bg-brand-primary rounded w-6" style={{ height: `${(e.value / max) * 100}%` }} />
                    </Tooltip>
                    <span className="text-[0.6125rem] text-text-secondary font-medium text-center whitespace-pre pt-2 mt-0.5 w-16">{e.period}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full bg-white rounded-lg border-border-primary border py-4 px-4 gap-4">
        <span className="text-base font-medium text-text-primary">Expenses by Category</span>
        <div className="flex gap-4 grow">
          <Pie pieces={categoryBreakdownsPieces} />
          <div className="flex flex-col grow h-full gap-1 justify-center">
            {categoryBreakdownsPieces?.map((bd) => {
              if (!total) return null
              const percentage = ((bd.value / total) * 100).toFixed(2) + '%'
              return (
                <div key={bd.name} className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded-full shrink-0" style={{ backgroundColor: bd.color }} />
                  <span className="text-sm text-text-primary grow">{bd.name}</span>
                  <span className="text-xs text-text-primary text-right">
                    {percentage} / {prettyVal(bd.value)}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Graphs
