import api from 'api'
import { CloseIcon } from 'assets/icons'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'

const SubscribeToNewsletterModal: AwaitableModal = ({ resolve, open }) => {
  const [fullName, setFullName] = useState<string>('')
  const [email, setEmailAddress] = useState<string>('')
  const [monthlyRevenue, setMonthlyRevenue] = useState<string>('')
  const [businessModel, setBusinessModel] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = () => {
    if (!fullName || !email || !monthlyRevenue || !businessModel || !message) return handleError('Please fill out all fields')
    setSubmitting(true)
    api
      .subscribeToNewsletter({ email, fullName, monthlyRevenue, businessModel, message })
      .then(() => {
        setSubmitting(false)
        setSubmitted(true)
      })
      .catch(() => setSubmitting(false))
  }
  return (
    <Modal open={open} close={resolve}>
      <div className="bg-white rounded-xl flex overflow-hidden w-full max-w-max max-h-[calc(100vh-2rem)] relative">
        <div className="absolute rounded-full bg-blue-500 blur-[256px] w-[384px] h-[384px] right-0 top-0" />
        <div className="overflow-y-auto z-[10] p-8">
          <div className="flex gap-8 items-start justify-between grow">
            <div className="flex flex-col gap-2 items-start z-[100] grow">
              {!submitted && (
                <>
                  <span className="text-base-950 text-lg font-semibold text-left">Contact Us</span>
                  <span className="text-sm text-base-950">We are currently enrolling our beta to a select few of sellers. Please fill our contact form to learn more.</span>
                </>
              )}
            </div>
            <CloseIcon onClick={resolve} className="rounded-full hover:bg-slate-200 p-2 cursor-pointer w-10 h-10 relative -right-4 -top-2" />
          </div>
          <div className="flex flex-col z-[100]">
            <div className="flex flex-col gap-4 rounded-lg [&>input]:w-full [&>textarea]:w-full">
              {submitted ? (
                <>
                  <span className="text-base-950 font-semibold text-2xl text-left">
                    Message <span className="text-blue-500">Sent Successfully</span>
                  </span>
                  <span className="text-base-950-700 text-base text-left mb-12">One of our team members has been notified and will get back to you shortly.</span>
                </>
              ) : (
                <>
                  <input
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.currentTarget.value)}
                    className="grow mt-8 p-5 basis-1 shrink-0 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                  />
                  <input
                    placeholder="Email Address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmailAddress(e.currentTarget.value)}
                    className="grow p-5 basis-1 shrink-0 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                  />
                  <input
                    placeholder="Current Monthly Revenue"
                    value={monthlyRevenue}
                    onChange={(e) => setMonthlyRevenue(e.currentTarget.value)}
                    className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                  />
                  <input
                    placeholder="Business Model"
                    value={businessModel}
                    onChange={(e) => setBusinessModel(e.currentTarget.value)}
                    className="grow p-5 rounded-md bg-white border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                  />
                  <textarea
                    placeholder="What is the current bottleneck in your business?"
                    maxLength={500}
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                    className="grow p-5 rounded-md bg-white min-h-[200px] border border-slate-200 text-base-950 text-base placeholder:text-base-950"
                  />
                  <button disabled={submitting} className="button-blue w-max self-start !rounded-xl !text-lg" onClick={handleSubmit}>
                    Submit →
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SubscribeToNewsletterModal
