import { DashboardTile } from "pages/app/Catalogue/components/Dashboard";
import { FC } from "react";
import { IShippingCost } from "types/housedAsins";
import { IPrepCost } from "types/prepCosts";
import { IPurchaseOrder } from "types/purchaseOrders";
import { usNumber } from "utils/formatting";
import { getPurchaseOrderOverview } from "utils/purchaseOrders";

interface props {
    purchaseOrder: IPurchaseOrder
    shippingCostDef: IShippingCost
    prepCosts: IPrepCost[]
}

const Dashboard: FC<props> = ({purchaseOrder, shippingCostDef, prepCosts}) => {
    const {
        totalGrossProfit,
        totalROI,
        totalMargin,
        totalUnitsPurchased,
    } = getPurchaseOrderOverview({purchaseOrder, shippingCostDef, prepCosts});

    return (
        <div className="grid grid-cols-4 gap-4">
            <DashboardTile title='Gross Profit' value={totalGrossProfit} color='#8F20FF' />
            <DashboardTile title='Gross Profit Per Unit' value={totalUnitsPurchased ? totalGrossProfit / totalUnitsPurchased : 0} color='#F0A30D' />
            <DashboardTile title='Gross Margin' value={usNumber(totalMargin) + "%"} color='#52CBFF' />
            <DashboardTile title='Gross ROI' value={usNumber(totalROI) + "%"} color='#12B76A' />
        </div>
    )
}

export default Dashboard