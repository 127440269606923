import axios from 'axios'
import useIconsax from 'hooks/useIconsax'
import { FC, useEffect, useState } from 'react'

interface props {
  name?: string
  size?: number
  className?: string
  button?: boolean
  active?: boolean
}

const IconBig: FC<props> = ({ name, size=24, className, active }) => {
  const SVG = useIconsax(`bulk/${name}.svg`)

  const combinedClass = [active ? "fill-primary-600 stroke-none" : `fill-base-300 stroke-none`, className].filter(Boolean).join(" ")

  if (!SVG) return null

  return <SVG width={size} height={size} viewBox="0 0 24 24" className={combinedClass} />
}

export default IconBig
