import { useCallback, useState } from "react";

export interface OperatorFilter {
  o: "<" | ">" | "=" | "<=" | ">=" | "!=" | "IN" | "NOT IN" | "LIKE" | "ILIKE" | "NOT LIKE" | "NOT ILIKE",
  v: string | number | boolean | string[] | number[] | boolean[]
}

const useOperatorFilter = () => {
    const [filter, setFilter] = useState<Record<string, OperatorFilter>>({});

    const operator = (key: string, value: OperatorFilter) => {
        setFilter({ ...filter, [key]: value });
    };

    const remove = useCallback((key: string) => {
        setFilter(old => {
            const { [key]: _, ...rest } = old;
            return {...rest};
        });
    }, []);

    const toString = useCallback(() => {
        return JSON.stringify(filter);
    }, [filter]);
    
    return {filter, operator, remove, setFilter, toString}
};

export default useOperatorFilter;