import ExcelUpload from "../base"

const schema = [
    {
        name: "ADDED AT",
        type: "date"
    },
    {
        name: "ASIN",
        type: "text"
    },
    {
        name: "SUPPLIER",
        type: "text"
    },
    {
        name: "UNITS",
        type: "number"
    },
    {
        name: "BUNDLE QTY",
        type: "number"
    },
    {
        name: "UNIT COST",
        type: "number"
    },
    {
        name: "SHIPPING COST",
        type: "number"
    },
    {
        name: "PREP TYPE",
        type: "text"
    },
    {
        name: "EXPIRATION DATE",
        type: "date"
    }
] as const

const excelUpload = new ExcelUpload(schema)

export default excelUpload
