import Icon from 'assets/icons/iconset'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { IWarehouseInboundShipment, IWarehouseInboundShipmentCursor } from 'types/warehouse'

interface props {
  shipment?: IWarehouseInboundShipment
  cursor?: IWarehouseInboundShipmentCursor
}

const Overview: FC<props> = ({ shipment, cursor }) => {
  const navigate = useNavigate()
  const nextCount = cursor?.nextCount || 0
  const previousCount = cursor?.previousCount || 0
  const totalPages = cursor ? nextCount + previousCount + 1 : '...'
  const currentPage = cursor ? cursor.previousCount + 1 : '...'
  const supplierName = shipment ? shipment.supplier.name || 'N/A' : '...'
  const title = shipment ? shipment.title || 'N/A' : '...'

  const asins = shipment ? shipment.items.length : '...'
  const units = shipment ? shipment.items.reduce((acc, item) => acc + item.units, 0) : '...'

  const onNext = () => {
    if (cursor?.next) {
      navigate('../' + cursor?.next, { relative: 'path', replace: true })
    }
  }
  return (
    <div className="bg-surface-primary border border-border-primary flex items-center p-4 gap-4 rounded-lg">
      <span className="bg-brand-disabled text-brand-primary py-1 px-2 rounded text-xs font-medium shrink-0">{supplierName}</span>
      <div className="flex flex-col gap-1 grow">
        <span className="text-text-primary text-xl">Title:</span>
        <span className="text-text-secondary text-sm">{title}</span>
      </div>
      <div className="flex items-center justify-between gap-4 grow">
        <span className="text-text-secondary text-sm font-medium truncate">ASINs:</span>
        <span className="text-text-primary text-sm font-medium">{asins}</span>
      </div>
      <div className="flex items-center justify-between gap-4 grow">
        <span className="text-text-secondary text-sm font-medium truncate">Units:</span>
        <span className="text-text-primary text-sm font-medium">{units}</span>
      </div>
      <div className="flex items-center gap-4">
        <span className="text-text-secondary text-sm font-medium truncate">
          Item {currentPage} / {totalPages}
        </span>
        {cursor?.next && (
          <button className="button-tertiary bg-surface-secondary hover:bg-surface-light" onClick={onNext}>
            <Icon name="CaretRight" className="w-5 h-5" />
            Next
          </button>
        )}
      </div>
    </div>
  )
}

export default Overview
