import { FC } from "react"
import { IShippingCost } from "types/housedAsins"
import { IPrepCost } from "types/prepCosts"
import { IPurchaseOrder } from "types/purchaseOrders"
import { usNumber } from "utils/formatting"
import { getPurchaseOrderOverview } from "utils/purchaseOrders"

interface props {
    purchaseOrder: IPurchaseOrder
    shippingCostDef: IShippingCost
    prepCosts: IPrepCost[]
}

const Overview: FC<props> = ({purchaseOrder, shippingCostDef, prepCosts}) => {
    const {
        totalEstimatedRevenue,
        totalCOGs,
        totalWeight,
        totalFees,
        totalCases,
        totalUnitsPurchased,
        totalGrossProfit,
        totalSellableAsins,
    } = getPurchaseOrderOverview({purchaseOrder, shippingCostDef, prepCosts});

    const grossProfitPerAsin = totalUnitsPurchased ? totalGrossProfit / totalSellableAsins : 0;
    const averageSalePrice = totalSellableAsins ? totalEstimatedRevenue / totalSellableAsins : 0;

    return (
        <div className="flex items-center pl-4 pb-4 pt-3 pr-0 gap-4 rounded-lg bg-surface-primary border border-border-primary">
            <div className="flex gap-4 items-center shrink-0">
                <span className="text-xl font-medium text-text-primary">
                    Supplier:
                </span>
                <span className="bg-brand-disabled text-brand-primary py-1 px-2 rounded text-xs font-medium">
                    {purchaseOrder.supplier}
                </span>
            </div>
            <div className="grid grid-cols-3 w-full [&>div]:mr-4 [&>div]:mt-1 grow">
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Total Revenue
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        ${usNumber(totalEstimatedRevenue)}
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        GPPA
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        ${usNumber(grossProfitPerAsin)}
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Number of Cases
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        {usNumber(totalCases, 0)}
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Total COG
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        ${usNumber(totalCOGs)}
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Average Sale Price
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        ${usNumber(averageSalePrice)}
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Shipment Weight
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        {usNumber(totalWeight)} Lbs
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Total Fees
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        ${usNumber(totalFees)}
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Units Sellable
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        {usNumber(totalSellableAsins, 0)}
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Date
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        {purchaseOrder.createdAt ? new Date(purchaseOrder.createdAt).toLocaleDateString("en-US") : 'N/A'}
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Gross Profit
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        ${usNumber(totalGrossProfit)}
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Units Amount
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        {usNumber(totalUnitsPurchased, 0)}
                    </span>
                </div>
                <div className="flex items-center bg-surface-primary justify-between gap-1">
                    <span className="text-text-secondary text-xs font-medium truncate">
                        Buyer
                    </span>
                    <span className="text-text-primary text-xs font-medium">
                        N/A
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Overview