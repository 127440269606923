import { FC } from 'react'
import Sidebar from '../../components/layout/Sidebar'
import { Outlet } from 'react-router-dom'
import Header from 'components/layout/Header'
import SearchProvider from 'contexts/Search/SearchProvider'
import PnlSyncProvider from 'contexts/PnlSync'
import OutboundSyncProvider from 'contexts/OutboundSync'

const MainLayout: FC = () => {
  return (
    <PnlSyncProvider>
      <OutboundSyncProvider>
        <SearchProvider>
          <div className="h-full flex w-full overflow-hidden">
            <Sidebar
              buttons={[
                { icon: 'PnL', name: 'P&L', path: '/app/pnl' },
                { icon: 'SquaresFour', name: 'Inventory', path: '/app/catalog' },
                {
                  icon: 'CreditCard',
                  name: 'Expenses',
                  path: '/app/expenses',
                  subRoutes: [
                    {
                      icon: 'CreditCard',
                      name: 'Expenses',
                      path: '/app/expenses',
                    },
                    {
                      icon: 'PrepCost',
                      name: 'Prep Cost',
                      path: '/app/expenses/prep-cost',
                    },
                  ],
                },
                {
                  icon: 'CurrencyCircleDollar',
                  name: 'Suppliers',
                  path: '/app/suppliers',
                  subRoutes: [
                    {
                      icon: 'MagnifyingGlass',
                      name: 'Supplier Accounts',
                      path: '/app/suppliers/settings',
                    },
                    {
                      icon: 'Stack',
                      name: 'Supplier Leads',
                      path: '/app/suppliers/leads',
                    },
                  ],
                },
                {
                  icon: 'Stack',
                  name: 'Purchase Orders',
                  path: '/app/purchase-orders',
                  subRoutes: [
                    {
                      icon: 'Stack',
                      name: 'Open',
                      path: '/app/purchase-orders/open',
                    },
                    {
                      icon: 'Stack',
                      name: 'Closed',
                      path: '/app/purchase-orders/closed',
                    },
                  ],
                },
                {
                  icon: 'Warehouse',
                  name: 'Warehouse',
                  path: '/app/warehouse',
                  subRoutes: [
                    { icon: 'Truck', name: 'Inbound', path: '/app/warehouse/inbound' },
                    { icon: 'Warehouse', name: 'Inventory', path: '/app/warehouse/inventory' },
                    { icon: 'TruckOut', name: 'Outbound', path: '/app/warehouse/outbound' },
                  ],
                },
                {
                  icon: 'Cube',
                  name: 'Internal Database',
                  path: '/app/housed',
                  subRoutes: [
                    { icon: 'Cube', name: 'Housed Asins', path: '/app/housed/housed' },
                    { icon: 'Stack', name: 'Master UPC Catalog', path: '/app/housed/master-catalog' },
                    { icon: 'CheckSquareOffset', name: 'Asin Entry', path: '/app/housed/asin-entry' },
                  ],
                },
              ]}
            />
            <div className="flex flex-col grow h-full overflow-hidden">
              <div className="flex">
                <Header />
              </div>
              <div className="flex grow w-full overflow-y-auto">
                <Outlet />
              </div>
            </div>
          </div>
        </SearchProvider>
      </OutboundSyncProvider>
    </PnlSyncProvider>
  )
}

export default MainLayout
