import { TableProps } from "components/tables/Table";
import { Dispatch, SetStateAction, createContext } from "react";

export interface TableContextType {
    table: {
        columnOffsets: number[],
    } & TableProps,
    setTable: Dispatch<SetStateAction<TableProps>>    
}

const TableContext = createContext<TableContextType>({} as TableContextType)

export default TableContext