import { useQuery, useQueryClient } from "@tanstack/react-query"
import prepCostApi from "api/prepCost"
import { Dispatch, SetStateAction, useCallback } from "react"
import { IPrepCost } from "types/prepCosts"

const queryFn = async () => {
    return prepCostApi.getPrepCosts()
}

const usePrepCosts = () => {
    const {data: prepCosts} = useQuery({
        queryKey: ['prepCosts'],
        queryFn,
    })

    const qc = useQueryClient();

    const setPrepCosts: Dispatch<SetStateAction<IPrepCost[] | undefined>> = useCallback((updater) => {
        qc.setQueryData<IPrepCost[] | undefined>(['prepCosts'], updater)
    }, [qc])

    return {
        prepCosts,
        setPrepCosts,
    }
}

export default usePrepCosts