import { FC, useCallback } from 'react'
import { columnDef } from './tableDef'
import Table from 'components/tables/Table'
import ContentLayout from 'components/layout/Content'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { useMemo } from 'react'
import { IApiFilter } from 'types/tableFiltering'
import warehouseApi from 'api/warehouse'
import { IWarehouseInboundShipment } from 'types/warehouse'
import Item from './Item'
import useSupplierNames from 'hooks/useSupplierNames'
import withFiltering from 'contexts/Filter/wrapper'

const queryFn = async ({ pageParam: page, queryKey }: { pageParam: number; queryKey: [string, IApiFilter] }) => {
  return warehouseApi.getWarehouseInboundShipments({ page, ...queryKey[1] })
}

const dashboardQueryFn = async () => {
  return warehouseApi.getWarehouseInboundDashboard()
}

const WarehouseInboundPage: FC = () => {
  const { supplierNames } = useSupplierNames()
  const { search, ordering, opFilters: filters } = useFilteringContext()

  const queryKey = useMemo(
    () => [
      'warehouse-inbound',
      {
        search,
        ordering,
        filters,
      },
    ],
    [search, ordering, filters]
  )

  const { data, isLoading, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: queryKey as [string, IApiFilter],
    queryFn: (params) => queryFn(params),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
  })

  const { data: dashboard } = useQuery({
    queryKey: ["warehouse-inbound-dashboard"],
    queryFn: dashboardQueryFn
  })

  const renderItem = useCallback((item: IWarehouseInboundShipment) => <Item shipment={item} />, [])

  return (
    <ContentLayout wrapperClass="gap-4 !bg-surface-light !border-none !flex">
      <div className="!overflow-y-auto !bg-surface-light !border-none !flex !flex-col">
        <div className="flex flex-col w-full h-full bg-surface-primary !rounded-lg gap-4 [&>div:nth-child(2)]:border [&>div:nth-child(2)]:border-border-primary [&>div:nth-child(2)]:rounded-lg [&>div:nth-child(2)]:min-h-full">
          <Table
            columns={columnDef}
            items={data?.pages.flatMap((p) => p.items)}
            renderItem={renderItem}
            loading={isLoading && !data}
            loadingNext={isFetchingNextPage}
            onBottom={fetchNextPage}
            extra={{ supplierNames }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 w-[12rem]">
        <div className="flex flex-col gap-1 p-4 rounded-lg border border-border-primary bg-surface-primary">
            <span className='text-text-secondary text-sm'>
                Total Shipments
            </span>
            <span className='text-text-primary text-xl'>
                {dashboard?.shipments ?? "..."}
            </span>
        </div>
        <div className="flex flex-col gap-1 p-4 rounded-lg border border-border-primary bg-surface-primary">
            <span className='text-text-secondary text-sm'>
                Total Sellable Units
            </span>
            <span className='text-text-primary text-xl'>
                {dashboard?.units ?? "..."}
            </span>
        </div>
      </div>
    </ContentLayout>
  )
}

export default withFiltering(WarehouseInboundPage)
