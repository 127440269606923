import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import useUserContext from 'contexts/User/useUserContext'
import FullpageLoader from 'components/loaders/FullpageLoader'
import MainLayout from 'layouts/Main'
import AmazonLoginPage from 'pages/app/Amazon/Login'
import AmazonLoginSuccessPage from 'pages/app/Amazon/Success'
import SettingsLayout from 'layouts/Settings'
import AmazonAccountSettings from 'pages/app/settings/AmazonAccount'
import SupplierSettings from 'pages/app/suppliers/SupplierSettings'
import SupplierLeads from 'pages/app/suppliers/SupplierLeads'
import CataloguePage from 'pages/app/Catalogue'
import MasterUPCCatalog from 'pages/app/housedAsins/MasterUPCCatalog'
import HousedAsins from 'pages/app/housedAsins/Housed'
import SupplierLeadsProvider from 'contexts/SupplierLeads/SupplierLeadsProvider'
import SuppliersProvider from 'contexts/Suppliers/SuppliersProvider'
import AsinEntry from 'pages/app/housedAsins/AsinEntry'
import Expenses from 'pages/app/Expenses'
import PnL from 'pages/app/PnL'
import PrepCost from 'pages/app/PrepCost'
import OpenPurchaseOrdersPage from 'pages/app/purchaseOrders/Open'
import PurchaseOrderPage from 'pages/app/purchaseOrders/PurchaseOrder'
import ClosedPurchaseOrdersPage from 'pages/app/purchaseOrders/Closed'
import WarehouseInboundPage from 'pages/app/warehouse/Inbound'
import WarehouseInboundShipmentPage from 'pages/app/warehouse/InboundShipment'
import WarehouseInventoryPage from 'pages/app/warehouse/Inventory'
import WarehouseOutboundPage from 'pages/app/warehouse/Outbound'

const AuthorizedRouter = () => {
  const user = useUserContext()

  document.querySelector('html')?.classList.toggle('dark', localStorage.getItem('darkMode') === 'true')

  if (!user) return <FullpageLoader />

  return (
    <Routes>
      <Route path="setup" element={user.amazonConnected ? <Navigate to="/app" replace /> : <AmazonLoginPage />} />
      <Route path="amazon/success" element={<AmazonLoginSuccessPage />} />
      <Route path="*" element={user.amazonConnected ? <MainLayout /> : <Navigate to="/app/setup" replace />}>
        <Route index element={<Navigate to="pnl" />} />
        <Route path="pnl" element={<PnL />} />
        <Route path="settings/*" element={<SettingsLayout />}>
          <Route index element={<Navigate to="amazon" />} />
          <Route path="amazon" element={<AmazonAccountSettings />} />
        </Route>
        <Route path="suppliers/*" element={<Outlet />}>
          <Route index element={<Navigate to="settings" />} />
          <Route
            path="settings"
            element={
              <SuppliersProvider>
                <SupplierSettings />
              </SuppliersProvider>
            }
          />
          <Route
            path="leads"
            element={
              <SupplierLeadsProvider>
                <SupplierLeads />
              </SupplierLeadsProvider>
            }
          />
        </Route>
        <Route path="expenses">
          <Route index element={<Expenses />} />
          <Route path="prep-cost" element={<PrepCost />} />
        </Route>
        <Route path="catalog" element={<CataloguePage />} />
        <Route
          path="housed/*"
          element={<Outlet />}
        >
          <Route index element={<Navigate to="master-catalog" />} />
          <Route path="master-catalog" element={<MasterUPCCatalog />} />
          <Route path="housed" element={<HousedAsins />} />
          <Route path="asin-entry" element={<AsinEntry />} />
        </Route>
        <Route
          path="purchase-orders/*"
          element={<Outlet />}
        >
          <Route index element={<Navigate to="open" />} />
          <Route path="open" element={<OpenPurchaseOrdersPage />} />
          <Route path="closed" element={<ClosedPurchaseOrdersPage />} />
          <Route path=":status/:id" element={<PurchaseOrderPage />} />
        </Route>
        <Route
          path="warehouse/*"
          element={<Outlet />}
        >
          <Route index element={<Navigate to="inbound" />} />
          <Route path="inbound" element={<WarehouseInboundPage />} />
          <Route path="inbound/:id" element={<WarehouseInboundShipmentPage />} />
          <Route path="inventory" element={<WarehouseInventoryPage />} />
          <Route path="outbound" element={<WarehouseOutboundPage />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AuthorizedRouter
