import amazonItemImgUrl from "helpers/amazonItemImgUrl";
import useAwaitableModal from "hooks/useAwaitableModal";
import { FC } from "react";
import PreviewImageModal from "./PreviewImageModal";
import { DogFallback } from "assets";
import useAmazonProductImage from "hooks/useAmazonProductImage";

interface props extends Omit<React.HTMLAttributes<HTMLImageElement>, "onClick" | "alt"> {
    asin: string,
    size: number,
    imageSize?: number
}

const AmazonProductImage: FC<props> = ({asin, size, className, imageSize, ...props}) => {
    const [request, Modal] = useAwaitableModal(PreviewImageModal, {} as any)    
     const image = useAmazonProductImage(asin)

     const url = amazonItemImgUrl(image?.url, imageSize || size, false)

     if (!image) return <div className={[className, "bg-slate-200 rounded animate-pulse"].asClass} {...props} />

    return (
    <>
        <Modal />
        <img src={url || DogFallback} className={[className, "cursor-pointer"].asClass} onClick={() => request({image})} alt={asin} {...props} />
    </>
    )
}

export default AmazonProductImage