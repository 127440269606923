import Table from 'components/tables/Table'
import { FC, useCallback } from 'react'
import columnDef from './tableDef'
import PrepCostItem from './components/PrepCostItem'
import { IPrepCost } from 'types/prepCosts'
import usePrepCosts from 'hooks/usePrepCosts'
import Icon from 'assets/icons/iconset'
import prepCostApi from 'api/prepCost'
import useAwaitableModal from 'hooks/useAwaitableModal'
import CreateFormModal from 'components/modals/CreateForm'

const PrepCost: FC = () => {
  const { prepCosts, setPrepCosts } = usePrepCosts()

  const renderItem = useCallback(
    (item: IPrepCost) => {
      return <PrepCostItem item={item} setItems={setPrepCosts} />
    },
    [setPrepCosts]
  )

  const createPrepCost = useCallback((cost: any) => {
    return prepCostApi.createPrepCost(cost.name, cost.amount)
  }, [])

  const formConfig = {
    onSubmit: createPrepCost,
    config: {
      title: 'New Prep Cost',
      sections: [
        {
          title: '',
          fields: [
            {
              id: 'name',
              name: 'Title',
              type: 'text' as const,
              required: true,
            },
            {
              id: 'amount',
              name: 'Cost',
              type: 'number' as const,
              required: true,
            },
          ],
        },
      ],
    },
  }

  const [addPrepCost, CreatePrepCostModal] = useAwaitableModal(CreateFormModal, formConfig)

  const onAdd = () => {
    addPrepCost(formConfig).then((entry) => {
      if (entry) {
        setPrepCosts((old) => old && [entry, ...old])
      }
    })
  }

  return (
    <div className="flex flex-col h-full bg-surface-light w-full p-4 overflow-hidden gap-3">
      <CreatePrepCostModal />
      <div className="flex w-full grow overflow-hidden rounded-lg bg-surface-primary border border-border-primary">
        <Table columns={columnDef} renderItem={renderItem} items={prepCosts} loading={!prepCosts} />
      </div>
      <div className="w-full flex flex-col items-start">
          <button className="button-primary !pl-2 shrink-0" onClick={onAdd} disabled={false}>
            <Icon name="Plus" className="w-5 h-5" />
            <span>Create</span>
          </button>
      </div>
    </div>
  )
}

export default PrepCost
