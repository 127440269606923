import { IExpense } from "types/expenses";
import { IColumnDefinition } from "types/table";

const columnDef: IColumnDefinition<IExpense>[] = [
    {
        field: "dateOfExpense",
        title: "Date of Expense",
        filters: "date",
        pin: true,
    },
    {
        field: "title",
        title: "Title",
        filters: "string",
        pin: true,
    },
    {
        field: "category",
        title: "Category",
        filters: "string"
    },
    {
        field: "amount",
        title: "Amount",
        filters: "number",
    },
    {
        field: "frequency",
        title: "Frequency",
        filters: "string",
    },
]

export default columnDef;