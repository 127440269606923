import { IColumnDefinition } from "types/table";
import { IWarehouseOutboundShipment } from "types/warehouse";

export const columnDef: IColumnDefinition<IWarehouseOutboundShipment>[] = [
    {
        field: "dateIn",
        title: "Date In",
        filters: "date",
    },
    {
        field: "shipmentId",
        queryField: "referenceId",
        title: "Shipment ID",
        filters: "string",
    },
    {
        field: "asins",
        title: "Unique ASINs",
        filters: "number",
    },
    {
        field: "units",
        title: "Units",
        filters: "number",
    },
    {
        field: "shipmentStatus",
        title: "Status",
        filters: "string",
    },
    {
        field: "dateOut",
        title: "Last Item Processed At",
        filters: "date",
    },
];