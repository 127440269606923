import { useState, FC, PropsWithChildren, useEffect, useRef } from "react"
import TableContext from "./tableContext"
import { TableProps } from "components/tables/Table"

const TableProvider: FC<PropsWithChildren<{table: TableProps}>> = ({ children, table: tableProps }) => {
  const [table, setTable] = useState(tableProps)
  const wrapper = useRef<HTMLDivElement>(null)
  const [columnOffsets, setColumnOffsets] = useState<number[]>([])

  useEffect(() => {
    if (wrapper.current) {
      const columns = wrapper.current.querySelectorAll('th')
      const widths = Array.from(columns).map(column => column.clientWidth)
      const offsets = widths.reduce((acc, cur) => {
        acc.push(acc[acc.length - 1] + cur)
        return acc
      }, [0])
      setColumnOffsets(offsets)
    }
  }, [tableProps])

  useEffect(() => {
    setTable(tableProps)
  }, [tableProps])

  return (
    <TableContext.Provider value={{ table: {...table, columnOffsets}, setTable }}>
      <div ref={wrapper} className="w-full h-full overflow-hidden">
        {children}
      </div>
    </TableContext.Provider>
  )
}

export default TableProvider