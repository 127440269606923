import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { HousedAsin } from 'types/housedAsins'
import housedAsinsApi from 'api/housedAsins'
import AmazonProductImage from 'components/AmazonProductImage'
import TD from 'components/tables/TD'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import Icon from 'assets/icons/iconset'
import BadgeSelect from 'components/BadgeSelect'
import { usNumber } from 'utils/formatting'
import TR from 'components/tables/TR'
import { IPrepCost } from 'types/prepCosts'

interface props {
  item: HousedAsin
  onDone: (asin?: HousedAsin) => void
}

const Item: FC<props> = ({ onDone, item }) => {
  const table = useTableContext()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({
    ...item,
  } as HousedAsin)
  const supplierNames = table.table.extra?.supplierNames || []
  const prepCosts = table.table.extra?.prepCosts || []
  const availableBuyers = table.table.extra?.availableBuyers || []
  const locked = !!table.table.locked

  useEffect(() => {
    setValues({ ...item })
  }, [item])

  const update = (overrides?: Partial<HousedAsin>) => {
    if (submitting) return
    if (locked) return
    setSubmitting(true)
    housedAsinsApi
      .updateHoused(item.id, { ...values, ...overrides })
      .then((res) => {
        onDone(res)
      })
      .finally(() => setSubmitting(false))
  }

  const onSubmit = () => {
    update()
    return false
  }

  const hasASINError = !item.weightUnit

  const onValueChange = (key: string, value: any) => {
    return (submit?: boolean) => {
      setValues((old) => ({ ...old, [key]: value }))
      submit && update({ [key]: value })
    }
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | null>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if (isNaN(value)) return updater(null)
    updater(value)
  }

  const listing = `https://www.amazon.com/dp/${item.asin}`

  const length = item?.dimensions?.length
  const width = item?.dimensions?.width
  const height = item?.dimensions?.height
  const sizeUnit = item?.dimensions?.unit
  const weight = item?.weightValue
  const weightUnit = item?.weightUnit

  const isChecked = !!table.table.select?.selected.includes(item.id)
  const allSelected = !!table.table.select?.allSelected

  return (
    <TR className={['relative', submitting && 'animate-pulse'].asClass} onBlur={onSubmit}>
      {!!table.table.select && (
        <TD onBlur={(e) => e.stopPropagation()} onClick={() => table.table.select?.onSelectClick(item.id)}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </TD>
      )}
      <TD onBlur={(e) => e.stopPropagation()}>
        <AmazonProductImage asin={item.asin} size={48} imageSize={96} />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={values.asin} readOnly />
          {!!values.asin && (
            <a href={listing} target="_blank" rel="noreferrer">
              <Icon name="Globe" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
        </div>
      </TD>
      <TD onBlur={(e) => e.stopPropagation()} className="w-32">
        <input type="text" value={values.title} readOnly />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <BadgeSelect
          selected={item.supplier || ''}
          badges={supplierNames}
          onSelect={(supplier) => {
            onValueChange('supplier', supplier)(true)
          }}
          editable={false}
        />
      </TD>
      <TD className="!py-1.5 !px-3">
        <input className="table-input" readOnly={locked} type="text" value={values.upc} onChange={(e) => onValueChange('upc', e.target.value)()} />
      </TD>
      <TD className="!py-1.5 !px-3">
        <input className="table-input" readOnly={locked} value={values.vendorSKU} onChange={(e) => onValueChange('vendorSKU', e.target.value)()} />
      </TD>
      <TD className="!py-1.5 !px-3">
        <input className="table-input" type="number" readOnly={locked} value={values.caseQty} onChange={onNumberChange((val) => onValueChange('caseQty', val)())} placeholder="0" />
      </TD>
      <TD className="!py-1.5 !px-3">
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input type="number" readOnly={locked} className="!pl-2.5" value={values.unitCost || ''} onChange={onNumberChange((val) => onValueChange('unitCost', val)())} placeholder="0.00" />
        </div>
      </TD>
      <TD className="!py-1.5 !px-3">
        <input className="table-input" readOnly={locked} type="number" value={values.bundle} onChange={onNumberChange((val) => onValueChange('bundle', val)())} placeholder="0" />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <BadgeSelect
          selected={values.buyer || ''}
          badges={availableBuyers || []}
          onSelect={(buyer) => {
            setValues((old) => ({ ...old, buyer }))
            update({ buyer })
          }}
          editable={!locked && !submitting}
        />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={values.prepCost || ''}
            badges={prepCosts?.map((p: IPrepCost) => p.name) || []}
            onSelect={(prepCost) => {
              setValues((old) => ({ ...old, prepCost }))
              update({ prepCost })
            }}
            singleColor="blue"
            editable={!locked && !submitting}
          />
          <span>${usNumber(prepCosts?.find((p: IPrepCost) => p.name === values.prepCost)?.amount, 2)}</span>
        </div>
      </TD>
      <TD>$ {usNumber(item.asinCost)}</TD>
      <TD>$ {usNumber(item.shippingCost)}</TD>
      <TD>$ {usNumber(item.landedCost)}</TD>
      <TD>$ {usNumber(item.price)}</TD>
      <TD className="!py-1.5 !px-3">
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input type="number" readOnly={locked} className="!pl-2.5" value={values.targetPrice || ''} onChange={onNumberChange((val) => onValueChange('targetPrice', val)())} placeholder="0.00" />
        </div>
      </TD>
      <TD>$ {usNumber(item.net)}</TD>
      <TD className={item.gross < 0 ? 'text-red-500' : item.gross > 0 ? 'text-green-500' : ''}>$ {usNumber(item.gross)}</TD>
      <TD>{usNumber(item.margin !== null ? item.margin * 100 : null, 2)}%</TD>
      <TD>{usNumber(item.roi !== null ? item.roi * 100 : null, 2)}%</TD>
      <TD className={[hasASINError && 'bg-red-200'].asClass}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <span>{item.salesRank ? usNumber(item.salesRank, 0) : '-'}</span>
        </div>
      </TD>
      <TD className={[hasASINError && 'bg-red-200'].asClass}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <span>
            {usNumber(weight)} <span className="font-normal">{weightUnit || '-'}</span>
          </span>
        </div>
      </TD>
      <TD className={[hasASINError && 'bg-red-200'].asClass}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <span>
            {usNumber(length)} x {usNumber(width)} x {usNumber(height)} <span className="font-normal">{sizeUnit || '-'}</span>
          </span>
        </div>
      </TD>
    </TR>
  )
}

export default Item
