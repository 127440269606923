import ExcelUpload from "../base"

const schema = [
    {
        name: "Amazon Link",
        type: "text"
    },
    {
        name: "Supplier",
        type: "text"
    },
    {
        name: "Unit Cost",
        type: "number"
    },
    {
        name: "UPC",
        type: "text"
    },
    {
        name: "Buyer",
        type: "text"
    },
    {
        name: "Bundle Qty",
        type: "number"
    },
    {
        name: "Case Qty",
        type: "number"
    },
    {
        name: "Prep Type",
        type: "text"
    }
] as const

const excelUpload = new ExcelUpload(schema)

export default excelUpload
