import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import AmazonProductImage from 'components/AmazonProductImage'
import TD from 'components/tables/TD'
import useTableContext from 'contexts/Table/useTableContext'
import Icon from 'assets/icons/iconset'
import TR from 'components/tables/TR'
import { useParams } from 'react-router'
import { IWarehouseInboundShipmentItem } from 'types/warehouse'
import warehouseApi from 'api/warehouse'
import { IWarehouseInboundShipmentItemColumn } from 'utils/warehouse'

interface props {
  raw: IWarehouseInboundShipmentItem
  item: IWarehouseInboundShipmentItemColumn
  onUpdate: (item: IWarehouseInboundShipmentItem) => void
}

const Item: FC<props> = ({ onUpdate, item, raw }) => {
  const { id } = useParams() as { id: string }
  const table = useTableContext()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({
    ...raw,
  } as IWarehouseInboundShipmentItem)

  const onForwardUnitQty = () => {
    const qty = values.units
    onValueChange('arrivedUnits', qty)()
    onValueChange('missing', 0)()
    onValueChange('damaged', 0)()
  }

  const onForwardCaseQty = () => {
    const qty = values.caseQty
    onValueChange('arrivedCaseQty', qty)(true)
  }

  const locked = !!table.table.locked

  useEffect(() => {
    setValues({ ...raw })
  }, [raw])

  const update = (overrides?: Partial<IWarehouseInboundShipmentItem>) => {
    if (submitting) return
    if (locked) return
    setSubmitting(true)
    const value = { ...values, ...overrides }
    if (value.expirationDate) {
      const date = new Date(value.expirationDate)
      date.setHours(0, 0, 0, 0)
      value.expirationDate = date.toISOString()
    }
    warehouseApi
      .updateWarehouseInboundShipmentItem(id, raw.id, value)
      .then((res) => {
        onUpdate(res)
      })
      .finally(() => setSubmitting(false))
  }

  const onSubmit = () => {
    update()
    return false
  }

  const onValueChange = (key: string, value: any) => {
    return (submit?: boolean) => {
      setValues((old) => ({ ...old, [key]: value }))
      submit && update({ [key]: value })
    }
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | null>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (isNaN(value)) return updater(null)
    updater(value)
  }

  const listing = `https://www.amazon.com/dp/${item.asin}`

  const someArrived = item.arrivedUnits !== null || raw.arrivedCaseQty !== null
  const accountedFor = (item.arrivedUnits ?? 0) + (item.missing ?? 0) + (item.damaged ?? 0)
  const hasNotAccountedFor = someArrived && item.units - accountedFor > 0

  const expirationDate = useMemo(() => {
    if (!values.expirationDate) return ''
    const date = new Date(values.expirationDate)
    const [month, day, year] = date.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, "-").split("-")
    return `${year}-${month}-${day}`
  }, [values.expirationDate])

  return (
    <TR className={['relative', submitting && 'animate-pulse'].asClass} onBlur={onSubmit}>
      <TD onBlur={(e) => e.stopPropagation()}>{item.invoiceNumber}</TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <AmazonProductImage asin={item?.asin} size={32} imageSize={32} />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={item?.asin} readOnly />
          {!!raw.asin && (
            <a href={listing} target="_blank" rel="noreferrer">
              <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
        </div>
      </TD>
      <TD onBlur={(e) => e.stopPropagation()} className="w-32">
        <input type="text" value={item.title} readOnly />
      </TD>
      <TD onBlur={(e) => e.stopPropagation()}>
        <input type="text" value={item.upcOverSKU} readOnly />
      </TD>
      <TD>{item.casePack}</TD>
      <TD>
        <div className="flex items-center gap-4 pointer-events-none justify-between">
          <span className="pointer-events-auto">{item.caseOrder}</span>
          <button className="!p-1 button-tertiary pointer-events-auto" onClick={onForwardCaseQty}>
            <Icon name="CaretRight" className="w-4 h-4" />
          </button>
        </div>
      </TD>
      <TD>
        <input readOnly={locked} className="table-input" type="text" value={values.arrivedCaseQty || ''} onChange={onNumberChange((val) => onValueChange('arrivedCaseQty', val)())} placeholder="0" />
      </TD>
      <TD>
        <div className="flex items-center gap-4 pointer-events-none justify-between">
          <span className="pointer-events-auto">{item.units}</span>
          <button className="!p-1 button-tertiary pointer-events-auto" onClick={onForwardUnitQty}>
            <Icon name="CaretRight" className="w-4 h-4" />
          </button>
        </div>
      </TD>
      <TD>
        <input readOnly={locked} className="table-input" type="text" value={values.arrivedUnits || ''} onChange={onNumberChange((val) => onValueChange('arrivedUnits', val)())} placeholder="0" />
      </TD>
      <TD>{someArrived ? item.difference : '---'}</TD>
      <TD className={hasNotAccountedFor ? 'bg-red-400' : ''}>
        {someArrived && item.difference ? (
          <input readOnly={locked} className="table-input" type="text" value={values.missing || ''} onChange={onNumberChange((val) => onValueChange('missing', val)())} placeholder="0" />
        ) : (
          '---'
        )}
      </TD>
      <TD className={hasNotAccountedFor ? 'bg-red-400' : ''}>
        {someArrived && item.difference ? (
          <input readOnly={locked} className="table-input" type="text" value={values.damaged || ''} onChange={onNumberChange((val) => onValueChange('damaged', val)())} placeholder="0" />
        ) : (
          '---'
        )}
      </TD>
      <TD>{item.bundle}</TD>
      <TD>{item.sellable}</TD>
      <TD>{item.prepCost?.name}</TD>
      <TD>
        <input type="date" value={expirationDate} onChange={(e) => onValueChange('expirationDate', e.target.value)()} />
      </TD>
    </TR>
  )
}

export default Item
