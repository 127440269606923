import { AxiosInstance } from "axios"
import axios from "./axios"
import { IPnLResponseDataPoint } from "types/pnl"

class PnLApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getSyncStatus() {
    return this.axios.get('/pnl/sync/status').then((res) => res.data as {synced: boolean, syncedUntil: string})
  }

  async sync() {
    // long running operation, firebase hosting redirect fails after a minute
    const base = 'https://us-central1-apex-blue.cloudfunctions.net/api'
    const url = `${base}/pnl/sync`

    return this.axios.get(url).then((res) => res.data as {synced: boolean, syncedUntil: string})
  }

  async getDashboard(params: {next?: string, since?: string, until?: string, queryKey?: string}) {
    return this.axios.get('/pnl', {params}).then((res) => res.data as IPnLResponseDataPoint[])
  }
}

const pnlApi = new PnLApi()

export default pnlApi