import { AxiosInstance } from "axios"
import axios from "./axios"
import { IExpense, IExpenseBreakdowns } from "types/expenses"
import firebase from "config/firebase"
import { IApiFilter } from "types/tableFiltering"

class ExpensesApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getBreakdowns(data: {since?: string, until?: string}) {
    return this.axios.get('/expenses/breakdowns', {params: data}).then((res) => res.data as IExpenseBreakdowns)
  }

  async createExpense(data: {
    title: string,
    amount: number,
    category: string,
    frequency: "One Time" | "Weekly" | "Monthly" | "Quarterly" | "Yearly",
    dateOfExpense: string,
  }) {
    return this.axios.post('/expenses', data).then((res) => res.data as {
      categories: string[],
      expenses: IExpense[]
    })
  }

  async updateExpense(id: string, data: {
    title: string,
    amount: number,
    category: string,
    frequency: "One Time" | "Weekly" | "Monthly" | "Quarterly" | "Yearly",
    dateOfExpense: string,
  }) {
    return this.axios.patch('/expenses/'+id, data).then((res) => res.data as {
      categories: string[],
      expenses: IExpense[]
    })
  }

  getLocalDate = (iso: string) => {
    const d = new Date(iso)
    const uk = d.toLocaleDateString("en-GB").split(",")[0].split("/").reverse().join("-")
    return uk
  }

  async getExpenses(params: {next?: string, since?: string, until?: string, queryKey?: string}) {
    const since = !params.since ? undefined : this.getLocalDate(params.since)
    const until = !params.until ? undefined : this.getLocalDate(params.until)
    return this.axios.get('/expenses', {params: {
      ...params,
      since,
      until
    }}).then((res) => ({queryKey: params.queryKey, ...res.data} as {
        queryKey?: string
        key: string,
        expenses: IExpense[],
        pages: number,
        next: string | null,
        categories: string[],
    }))
  }

  async deleteExpenses({ids, except}: {ids?: string[], except?: string[]}) {
    return axios.post('/expenses/delete', {ids, except})
  }

  async stopExpenses({ids, except}: {ids?: string[], except?: string[]}) {
    return axios.post('/expenses/stop', {ids, except})
  }

  async exportExpenses({search, filters, ordering, period}: IApiFilter) {
    const sp = new URLSearchParams()
    if (search) sp.append('search', search)
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (period) {
      if (period.since) sp.append('since', period.since)
      if (period.until) sp.append('until', period.until)
    }
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    const token = await firebase.auth.currentUser?.getIdToken()
    sp.append('token', token || '')
    window.open(`${this.axios.defaults.baseURL}/expenses/bulk/export?${sp.toString()}`, '_blank')
  }

  uploadExpenses = async (uri: string) => {
    return this.axios.post(`/expenses/bulk/import`, {uri})
  }
}

const expensesApi = new ExpensesApi()

export default expensesApi
