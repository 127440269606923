import { createRef, Dispatch, SetStateAction } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { IUser } from 'types/user'
import FullpageLoader from './components/loaders/FullpageLoader'
import useAuthContext from './contexts/Auth/useAuthContext'
import UserProvider from './contexts/User/UserProvider'
import AuthorizedRouter from './routes/AuthorizedRouter'
import UnauthorizedRouter from './routes/UnauthorizedRouter'
import useNoScrollNumberInput from 'hooks/useNoScrollNumberInput'
import PublicRouter from 'routes/PublicRouter'
import { initDB } from 'react-indexed-db-hook'
import indexedDB from 'utils/indexedDB'

initDB(indexedDB);

export const userData = createRef<{ setUser: Dispatch<SetStateAction<IUser | null>> }>()

function Root() {
  const user = useAuthContext()
  useNoScrollNumberInput();

  if (user === undefined) return <FullpageLoader />

  return (
    <UserProvider ref={userData}>
      <Routes>
        <Route path="app/*" element={user !== null ? <AuthorizedRouter /> : <Navigate to="/auth" replace />} />
        <Route path="auth/*" element={user === null ? <UnauthorizedRouter /> : <Navigate to="/app" replace />} />
        <Route path="*" element={<PublicRouter />} />
      </Routes>
    </UserProvider>
  )
}

export default Root
