import { FC, PropsWithChildren, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'
import { DefaultProfilePic } from 'assets'
import Icon from 'components/Icon'
import Iconset from 'assets/icons/iconset'
import { signOut } from 'firebase/auth'
import firebase from 'config/firebase'
import useSearchContext from 'contexts/Search/useSearchContext'
import useCurrentTitle from 'contexts/Title/useCurrentTitle'
import { ArrowIcon } from 'assets/icons'

const Header: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const profileDropdown = useRef<HTMLDivElement>(null)
  const { search, setSearch, searching, setSearching } = useSearchContext()
  const currentTitle = useCurrentTitle()

  const onGoBack = () => {
    navigate("..", {relative: "path"})
  }

  const onToggleProfileDropdown = () => {
    const isToggled = profileDropdown.current?.classList.contains('opacity-1')
    if (isToggled) {
      profileDropdown.current?.classList.replace('opacity-1', 'opacity-0')
      profileDropdown.current?.classList.replace('translate-y-2', '-translate-y-10')
      profileDropdown.current?.classList.replace('pointer-events-auto', 'pointer-events-none')
    } else {
      profileDropdown.current?.classList.replace('opacity-0', 'opacity-1')
      profileDropdown.current?.classList.replace('-translate-y-10', 'translate-y-2')
      profileDropdown.current?.classList.replace('pointer-events-none', 'pointer-events-auto')
    }
  }

  useEffect(() => {
    const cb = () => {
      const isToggled = profileDropdown.current?.classList.contains('opacity-1')
      if (isToggled) {
        profileDropdown.current?.classList.replace('opacity-1', 'opacity-0')
        profileDropdown.current?.classList.replace('translate-y-2', '-translate-y-10')
        profileDropdown.current?.classList.replace('pointer-events-auto', 'pointer-events-none')
      }
    }

    window.addEventListener('click', cb)

    return () => {
      window.removeEventListener('click', cb)
    }
  }, [])

  const toSubscription = () => {
    onToggleProfileDropdown()
    navigate('/app/subscription')
  }

  const toSettings = () => {
    onToggleProfileDropdown()
    navigate('/app/settings')
  }

  const onLogout = () => {
    onToggleProfileDropdown()
    signOut(firebase.auth)
  }

  return (
    <div id="header" className="flex w-full bg-surface-primary border-b border-b-border-primary items-center p-4 gap-2 overflow-visible">
      <span className="grow text-2xl font-bold text-text-primary">
        {!currentTitle ? (
          ''
        ) : currentTitle.includes('__back__') ? (
          <div className="flex items-center gap-4">
            <button onClick={onGoBack} className={'button-secondary !px-2 !text-text-primary !rounded-full !border-none'}>
              <Iconset name="CaretLeft" />
            </button>
            {currentTitle.split('__back__')[1]}
          </div>
        ) : (
          currentTitle
        )}
      </span>
      <div className="relative z-[10000]" onClick={(e) => e.stopPropagation()}>
        <div className="cursor-pointer flex items-center gap-3 group" onClick={onToggleProfileDropdown}>
          <DefaultProfilePic className="w-12 h-12 border-2 border-transparent group-hover:border-brand-primary group-hover:opacity-95 rounded-full transition-all" />
          <ArrowIcon className="group-hover:translate-y-0.5 transition-transform" />
        </div>
        <div
          ref={profileDropdown}
          className="absolute top-full right-0 drop-shadow-xl border border-base-200 transition-all divide-y divide-y-base-200 z-10 bg-white rounded-xl opacity-0 -translate-y-10 pointer-events-none"
        >
          <button onClick={toSettings} className="w-full px-4 py-2 text-base-400 min-w-max w-full text-xs font-medium items-center group flex gap-2 hover:text-orange-500 transition-all">
            <Icon name="setting-4" type="outline" className="fill-base-400 group-hover:fill-orange-500 transition-all" />
            <span>Settings</span>
          </button>
          <button onClick={toSubscription} className="w-full px-4 py-2 text-base-400 min-w-max w-full text-xs font-medium items-center group flex gap-2 hover:text-orange-500 transition-all">
            <Icon name="card" type="outline" className="fill-base-400 group-hover:fill-orange-500 transition-all" />
            <span>Subscription</span>
          </button>
          <button onClick={onLogout} className="w-full px-4 py-2 text-base-400 text-xs min-w-max w-full font-medium items-center group flex gap-2 hover:text-primary-600 transition-all">
            <Icon name="logout-1" type="outline" className="fill-base-400 group-hover:fill-orange-500 transition-all" />
            <span>Sign Out</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
