import { Supplier } from "types/suppliers";
import { IColumnDefinition } from "types/table";

const columnDef: IColumnDefinition<Supplier>[] = [
    {
        field: "name",
        title: "Supplier Name",
        pin: true,
        filters: "string",
    },
    {
        field: "website",
        title: "Supplier Website",
        filters: "string",
        pin: true,
    },
    {
        field: "accountNumber",
        title: "Account #",
        filters: "string",
    },
    {
        field: "userName",
        title: "Username",
        filters: "string",
    },
    {
        field: "password",
        title: "Password",
    },
    {
        field: "orderDay",
        title: "Order Day Of The Week",
        filters: "array",
    },
    {
        field: "orderDueBy",
        title: "Order Due By",
        filters: "number",
    },
    {
        field: "deliveryDay",
        title: "Delivery Day Of The Week",
        filters: "array",
    },
    {
        field: "leadTime",
        title: "Lead Time",
        filters: "number",
    },
    {
        field: "paymentTerms",
        title: "Payment Terms",
        filters: "string",
    },
    {
        field: "paymentDate",
        title: "Payment Due Date",
    },
    {
        field: "carrier",
        title: "Carrier",
        filters: "string",
    },
    {
        field: "discrepancyTime",
        title: "Discrepancy Timeframe",
        filters: "string",
    },
    {
        field: "repName",
        title: "Sales Rep Name",
        filters: "string",
    },
    {
        field: "repPhone",
        title: "Sales Rep Phone",
        filters: "string",
    },
    {
        field: "repEmail",
        title: "Sales Rep Email",
        filters: "string",
    },
    {
        field: "repBirthday",
        title: "Sales Rep Birthday",
        filters: "date",
    },
    {
        field: "creditDept",
        title: "Credit Department",
        filters: "string",
    },
    {
        field: "notes",
        title: "Notes",
        filters: "string",
    }
]

export default columnDef;