import { useCallback, useState } from "react"

const useSelect = () => {
    const [selected, setSelected] = useState<string[]>([])
    const [allSelected, setAllSelected] = useState(false)

    const onSelectAllClick = useCallback(() => {
        setAllSelected(old => !old)
        setSelected([])
    }, [])

    const onSelectClick = useCallback((id: string) => {
        setSelected(old => {
            if (old.includes(id)) return old.filter(i => i !== id)
            return [...old, id]
        })
    }, [])

    const clear = useCallback(() => {
        setSelected([])
        setAllSelected(false)
    }, [])

    return { selected, onSelectClick, allSelected, onSelectAllClick, clear }
}

export default useSelect