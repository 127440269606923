import ExcelUpload from "../base"

const schema = [
    {
        name: "ASIN",
        type: "text"
    },
    {
        name: "SUPPLIER",
        type: "text"
    },
    {
        name: "UPC",
        type: "text"
    },
    {
        name: "VENDOR SKU",
        type: "text"
    },
    {
        name: "CASE QTY",
        type: "number"
    },
    {
        name: "UNIT COST",
        type: "number"
    },
    {
        name: "BUNDLE",
        type: "number"
    },
    {
        name: "BUYER",
        type: "text"
    },
    {
        name: "PREP TYPE",
        type: "text"
    },
    {
        name: "TARGET PRICE",
        type: "number"
    }

] as const

const excelUpload = new ExcelUpload(schema)

export default excelUpload
