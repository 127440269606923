import Icon from 'assets/icons/iconset'
import Dropdown from 'components/Dropdown'
import IconBig from 'components/IconBig'
import AreYouSure from 'components/modals/AreYouSure'
import useAwaitableModal from 'hooks/useAwaitableModal'
import { FC, MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router'
import { IShippingCost } from 'types/housedAsins'
import { IPrepCost } from 'types/prepCosts'
import { IPurchaseOrder } from 'types/purchaseOrders'
import { usNumber } from 'utils/formatting'
import { getPurchaseOrderOverview } from 'utils/purchaseOrders'
import UploadInvoiceModal from './UploadInvoiceModal'
import purchaseOrdersApi from 'api/purchaseOrders'
import { getDownloadURL, ref } from 'firebase/storage'
import firebase from 'config/firebase'
import PDFPreviewModal from 'components/modals/PDFPreview'
import download from 'helpers/download'

interface props {
  purchaseOrder: IPurchaseOrder
  shippingCostDef?: IShippingCost
  prepCosts?: IPrepCost[]
  updateInvoice: (id: string, invoice: string | null) => void
}

const StatusBadge: FC<{ purchaseOrder: IPurchaseOrder }> = ({ purchaseOrder }) => {
  if (purchaseOrder.status === 'open') return null

  if (purchaseOrder.arrivedAt) {
    return <span className="bg-[#ECFDF3] text-[#027A48] text-xs px-2 py-1 rounded">Arrived</span>
  }

  return <span className="bg-[#FFF4EA] text-[#F0A30D] text-xs px-2 py-1 rounded">Shipped</span>
}

const PurchaseOrder: FC<props> = ({ purchaseOrder, shippingCostDef, prepCosts, updateInvoice }) => {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const [areYouSure, AreYouSureComponent] = useAwaitableModal(AreYouSure, { header: 'Delete Invoice' })
  const [uploadInvoice, UploadInvoiceComponent] = useAwaitableModal(UploadInvoiceModal, { id: purchaseOrder.id })
  const [previewPDF, PreviewPDFComponent] = useAwaitableModal(PDFPreviewModal, { url: '' })

  const {
    totalEstimatedRevenue,
    totalCOGs,
    totalWeight,
    totalShipping,
    totalProduction,
    totalExpenses,
    totalCases,
    totalUnitsPurchased,
    totalSellableAsins,
    totalGrossProfit,
    totalMargin,
    totalROI,
    buttonText,
  } = getPurchaseOrderOverview({ purchaseOrder, shippingCostDef, prepCosts })

  const goToPurchaseOrder = () => {
    navigate(`./${purchaseOrder.id}`)
  }

  const getInvoiceUrl = () => {
    if (!purchaseOrder.invoice) return null
    const fileName = purchaseOrder.invoice
    const path = `/users/${purchaseOrder.user}/purchaseOrders/${fileName}`
    return getDownloadURL(ref(firebase.storage, path))
  }

  const onAddInvoice = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const event = new CustomEvent('hideSelector')
    document.dispatchEvent(event)
    uploadInvoice({ id: purchaseOrder.id }).then((res) => {
      if (res) {
        updateInvoice(purchaseOrder.id, res.invoice)
      }
    })
  }

  const onDeleteInvoice = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const event = new CustomEvent('hideSelector')
    document.dispatchEvent(event)
    if (submitting) return
    setSubmitting(true)
    areYouSure({ header: 'Delete Invoice?' })
      .then(async () => {
          return purchaseOrdersApi.updateInvoice(purchaseOrder.id).then(() => {
            updateInvoice(purchaseOrder.id, null)
          })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onViewInvoice = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const event = new CustomEvent('hideSelector')
    document.dispatchEvent(event)
    getInvoiceUrl()?.then((url) => {
      previewPDF({ url })
    })
  }

  const onDownloadInvoice = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const event = new CustomEvent('hideSelector')
    document.dispatchEvent(event)
    getInvoiceUrl()?.then(download)
  }

  return (
    <div
      onClick={goToPurchaseOrder}
      className="bg-border-secondary hover:drop-shadow hover:opacity-80 transition-all cursor-pointer group rounded-lg grid grid-cols-4 grid-rows-5 border border-border-primary gap-px overflow-hidden "
    >
      <AreYouSureComponent />
      <UploadInvoiceComponent />
      <PreviewPDFComponent />
      <div className="p-3 bg-surface-primary w-full flex items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <span className="text-text-primary font-medium text-base">Supplier</span>
          <StatusBadge purchaseOrder={purchaseOrder} />
        </div>
        <span className="bg-brand-disabled text-brand-primary py-1 px-2 rounded text-xs font-medium">{purchaseOrder.supplier}</span>
      </div>
      <div className="p-3 justify-between w-full flex items-center bg-surface-light">
        <span className="text-text-primary font-medium text-base">Net Profit</span>
      </div>
      <div className="p-3 justify-between w-full flex items-center bg-surface-light">
        <span className="text-text-primary font-medium text-base">Expenses</span>
      </div>
      <div className="p-3 justify-between w-full flex items-center bg-surface-light">
        <span className="text-text-primary font-medium text-base">Weight</span>
      </div>
      <div className="row-span-5 flex flex-col p-3 items-center justify-center gap-3 bg-surface-primary">
        <IconBig name="box-1" className="fill-brand-primary w-12 h-12 group-hover:-translate-y-1 transition-transform" />
        <div className="flex flex-col items-center gap-1">
          <span className="text-sm text-text-secondary">Total Purchase Order Items</span>
          <span className="text-text-primary font-medium text-xl">{purchaseOrder.items.length}</span>
        </div>
        <div className="flex items-center gap-3">
          <button className="button-primary" onClick={goToPurchaseOrder}>
            {buttonText}
          </button>
          {purchaseOrder.status !== 'open' &&
            (purchaseOrder.invoice ? (
              <Dropdown
                anchorEl={
                  <button className="button-secondary !px-2">
                    <Icon name="Badge" className="w-6 h-6" />
                  </button>
                }
              >
                <div className="flex flex-col bg-white rounded-lg">
                  <button className="button-tertiary !text-left !justify-start !text-text-secondary" disabled={submitting} onClick={onViewInvoice}>
                    View
                  </button>
                  <div className="w-full h-px bg-border-secondary" />
                  <button className="button-tertiary !text-left !justify-start !text-text-secondary" disabled={submitting} onClick={onDownloadInvoice}>
                    Download
                  </button>
                  <div className="w-full h-px bg-border-secondary" />
                  <button className="button-tertiary !text-left !justify-start !text-text-secondary" disabled={submitting} onClick={onDeleteInvoice}>
                    Delete
                  </button>
                </div>
              </Dropdown>
            ) : (
              <button className="button-tertiary !px-2" onClick={onAddInvoice}>
                <Icon name="Badge" className="w-6 h-6" />
              </button>
            ))}
        </div>
      </div>
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">Total Estimated Revenue</span>
        <span className="text-text-primary text-xs font-medium">${usNumber(totalEstimatedRevenue)}</span>
      </div>
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">COGs</span>
        <span className="text-text-primary text-xs font-medium">${usNumber(totalCOGs)}</span>
      </div>
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">Total Order Weight</span>
        <span className="text-text-primary text-xs font-medium">{usNumber(totalWeight)} Lbs</span>
      </div>
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">Total Expenses</span>
        <span className="text-text-primary text-xs font-medium">${usNumber(totalExpenses)}</span>
      </div>
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">Shipping</span>
        <span className="text-text-primary text-xs font-medium">${usNumber(totalShipping)}</span>
      </div>
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">Number of Cases</span>
        <span className="text-text-primary text-xs font-medium">{usNumber(totalCases, 0)}</span>
      </div>
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">Gross Profit</span>
        <span className="text-text-primary text-xs font-medium">${usNumber(totalGrossProfit)}</span>
      </div>
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">Production</span>
        <span className="text-text-primary text-xs font-medium">${usNumber(totalProduction)}</span>
      </div>
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">Units Purchased</span>
        <span className="text-text-primary text-xs font-medium">{usNumber(totalUnitsPurchased, 0)}</span>
      </div>
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">Margin / ROI</span>
        <span className="text-text-primary text-xs font-medium">
          {usNumber(totalMargin)}% / {usNumber(totalROI)}%
        </span>
      </div>
      <div className="bg-surface-primary" />
      <div className="flex items-center bg-surface-primary justify-between p-3 gap-1">
        <span className="text-text-secondary text-xs font-medium truncate">Sellable Asins</span>
        <span className="text-text-primary text-xs font-medium">{usNumber(totalSellableAsins, 0)}</span>
      </div>
    </div>
  )
}

export default PurchaseOrder
