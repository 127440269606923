import axios from 'axios'
import useIconsax from 'hooks/useIconsax'
import { FC, useEffect, useState } from 'react'

interface props {
  name?: string
  type?: 'bulk' | 'outline'
  size?: number
  className?: string
  button?: boolean
  active?: boolean
  preserveAspectRatio?: string
}

const Icon: FC<props> = ({ name, type, size=24, className, active, button, preserveAspectRatio }) => {
   const _type = button ? active ? 'bulk' : 'outline' : type || 'outline'
  const SVG = useIconsax(`${_type}/${name}.svg`)

  const combinedClass = [active ? "fill-primary-600 stroke-none" : `fill-base-300 stroke-none ${button && 'group-hover:fill-primary-600'}`, className].filter(Boolean).join(" ")

  if (!SVG) return null

  return <SVG preserveAspectRatio={preserveAspectRatio} width={size} height={size} viewBox="0 0 24 24" className={combinedClass}/>
}

export default Icon
