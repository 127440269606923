import pnlApi from "api/pnl"
import { useRef, useState, useCallback, useEffect, createContext, FC, PropsWithChildren, useContext } from "react"


const useSyncPnL = () => {
  const syncStarted = useRef(false)
  const [syncResponse, setSyncResponse] = useState<{synced: boolean, syncedUntil: string}>(
    { synced: false, syncedUntil: '' }
  )

  const sync = useCallback(() => {
    pnlApi.sync().then((res) => {
        setSyncResponse(res)
        if (!res.synced) {
          return sync()
        }
      })
  }, [])

  useEffect(() => {
    if (syncStarted.current) return
    syncStarted.current = true
    pnlApi.getSyncStatus().then((res) => {
      setSyncResponse(res)
      if (!res.synced) {
        sync()
      }
    })
  }, [sync])

  return syncResponse
}

const PnlSyncContext = createContext<ReturnType<typeof useSyncPnL>>({ synced: false, syncedUntil: '' })

export const usePnlSync = () => useContext(PnlSyncContext)

const PnlSyncProvider: FC<PropsWithChildren> = ({ children }) => {
  const syncState = useSyncPnL()

  return (
    <PnlSyncContext.Provider value={syncState}>
      {children}
    </PnlSyncContext.Provider>
  )
}

export default PnlSyncProvider