import { IColumnDefinition } from "types/table";
import { IWarehouseInboundShipmentItemColumn } from "utils/warehouse";

export const columnDef: IColumnDefinition<IWarehouseInboundShipmentItemColumn>[] = [
    {
        field: "invoiceNumber",
        title: "Invoice Number",
        filters: "string",
    }, 
    {
        field: "image",
        title: "Image",
        filters: "string",
    }, 
    {
        field: "asin",
        title: "ASIN",
        filters: "string",
    },
    {
        field: "title",
        title: "Title",
        filters: "string",
    },
    {
        field: "upcOverSKU",
        title: "UPC / Vendor SKU",
        filters: "string",
    },
    {
        field: "casePack",
        title: "Case Pack",
        filters: "number",
    },
    {
        field: "caseOrder",
        title: "Case Order",
        filters: "number",
    },
    {
        field: "arrivedCaseQty",
        title: "Arrived Cases",
        filters: "number",
    },
    {
        field: "units",
        title: "Expected Units",
        filters: "number",
    },
    {
        field: "arrivedUnits",
        title: "Arrived Units",
        filters: "number",
    },
    {
        field: "difference",
        title: "Difference",
        filters: "number",
    },
    {
        field: "missing",
        title: "Missing",
        filters: "number",
    },
    {
        field: "damaged",
        title: "Damaged",
        filters: "number",
    },
    {
        field: "bundle",
        title: "Bundle Quantity",
        filters: "number",
    },
    {
        field: "sellable",
        title: "Sellable Units",
        filters: "number",
    },
    {
        field: "prepCost",
        title: "Prep Type",
        filters: "number",
    },
    {
        field: "expirationDate",
        title: "Expiration Date",
        filters: "date",
    }
]