import { IApiFilter } from "types/tableFiltering";
import useSelect from "./useSelect";
import { useCallback, useState } from "react";
import useFilteringContext from "contexts/Filter/useFilteringContext";
import { useNavigate } from "react-router";

const useBulkAction = (
    select: ReturnType<typeof useSelect>, 
    actionFn: (data: IApiFilter & {except?: string[], ids?: string[]}) => Promise<any>, 
    navigateTo?: string
    ) => {
    const [deleting, setDeleting] = useState(false);
    const {opFilters: filters, search, ordering} = useFilteringContext();
    const navigate = useNavigate();
    const handleDelete = useCallback(() => {
        if (deleting) return;
        setDeleting(true);
        let data = {} as Record<string, any>;
        if (select.allSelected) data.except = select.selected;
        else data.ids = select.selected;
        actionFn({...data, filters, search, ordering})
            .then(() => navigateTo ? navigate(navigateTo) : navigate(0))
            .finally(() => setDeleting(false));
    }, [actionFn, deleting, filters, ordering, search, select.allSelected, select.selected, navigateTo])

    const onDelete = useCallback(() => {
        handleDelete();
    }, [handleDelete]);

    return [onDelete, deleting] as const;
};

export default useBulkAction;