import Icon from 'assets/icons/iconset'
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react'
import { IPeriodFilter } from 'types/tableFiltering'

interface props {
  end?: boolean
  period: IPeriodFilter
  setPeriod: Dispatch<SetStateAction<IPeriodFilter>>
}

const getDate = (iso?: string | Date) => {
  return iso ? new Date(iso).toLocaleString("en-GB").split(",")[0].split("/").reverse().join("-") : undefined
}

const Calendar: FC<props> = ({ end = false, period: currentPeriod, setPeriod }) => {
  const currentSince = currentPeriod.since
  const currentUntil = currentPeriod.until
  const currentSinceDate = getDate(currentSince)
  const currentUntilDate = getDate(currentUntil)

  const relevant = end ? currentUntil : currentSince
  const thisDate = relevant ? new Date(relevant) : new Date()
  thisDate.setDate(1)

  const [current, setCurrent] = useState<Date>(thisDate)

  const dates = useMemo(() => {
    const arr = Array(7*6).fill(null)
    const beginningDay = current.getDay()
    const out = arr.map((_, i) => {
        const offset = i - beginningDay + 2
        const d = new Date(current)
        d.setDate(offset)
        return new Date(d)
    })
    return out
  }, [current])

  const setRelevant = useCallback((date: Date) => {
    if (end) {
        if (currentPeriod.since && (date < new Date(currentPeriod.since))) {
            setPeriod(old => ({...old, since: date.toISOString()}))
        } else {
            setPeriod(old => ({...old, until: date.toISOString()}))
        }
    } else {
        if (currentPeriod.until && (date > new Date(currentPeriod.until))) {
            setPeriod(old => ({...old, until: date.toISOString()}))
        } else {
            setPeriod(old => ({...old, since: date.toISOString()}))
        }
    }
  }, [end, currentPeriod, setPeriod])

  const prevMonth = useCallback(() => {
    setCurrent(old => {
        const d = new Date(old)
        d.setMonth(d.getMonth() - 1)
        return d
    })
  }, [])

  const nextMonth = useCallback(() => {
    setCurrent(old => {
        const d = new Date(old)
        d.setMonth(d.getMonth() + 1)
        return d
    })
  }, [])

  return (
    <div className="flex flex-col py-5 px-6">
      <div className="flex items-center w-full">
        <Icon name="CaretLeft" onClick={prevMonth} className="w-10 h-10 p-2 rounded-lg text-text-secondary hover:bg-surface-secondary transition-colors hover:text-brand-primary cursor-pointer" />
        <span className="text-text-primary grow text-center text-base font-medium">{current.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
        <Icon name="CaretRight" onClick={nextMonth} className="w-10 h-10 p-2 rounded-lg text-text-secondary hover:bg-surface-secondary transition-colors hover:text-brand-primary cursor-pointer" />
      </div>
      <div className="grid grid-cols-7 w-[17.5rem] shrink-0 space-y-1">
        {[
            "Mo",
            "Tu",
            "We",
            "Th",
            "Fr",
            "Sa",
            "Su"
        ].map((wd) => (
        <span key={wd} className="font-medium text-text-primary text-center w-full !h-10 py-2.5">
            {wd}
        </span>
        ))}
        
        {
            dates.map((date) => {
                const month = date.getMonth()
                const isCurrent = month === current.getMonth()
                const dateCmp = getDate(date) as string
                const isSince = dateCmp === currentSinceDate
                const isUntil = dateCmp === currentUntilDate
                const isActive = isSince || isUntil
                const isBetween = !!currentSinceDate && !!currentUntilDate && !isActive && dateCmp.localeCompare(currentSinceDate) > 0 && dateCmp.localeCompare(currentUntilDate) < 0

                return (
                    <div key={date.toISOString()} className={[
                        "w-10 h-10",
                        (isBetween || isActive) ? "bg-brand-disabled" : "bg-white",
                        isSince ? "rounded-l-full" : "rounded-l-none",
                        isUntil ? "rounded-r-full" : "rounded-r-none",
                        ].asClass}>
                    <button 
                    onClick={() => setRelevant(date)}
                    className={[
                            "w-10 h-10 p-2 rounded-full hover:bg-brand-primary hover:text-white transition-colors",
                            isActive ? "bg-brand-primary text-white font-medium" : isBetween ? "text-brand-primary bg-transparent font-medium" : isCurrent ? "text-text-primary" : "text-text-secondary"
                        ].asClass}
                    >
                        {date.getDate()}
                    </button>
                    </div>
                )
            })
        }
      </div>
    </div>
  )
}

export default Calendar
