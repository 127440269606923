import prepCostApi from 'api/prepCost'
import Icon from 'assets/icons/iconset'
import TD from 'components/tables/TD'
import TR from 'components/tables/TR'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { IPrepCost } from 'types/prepCosts'

interface props {
  item: IPrepCost
  setItems: Dispatch<SetStateAction<IPrepCost[] | undefined>>
}

const PrepCostItem: FC<props> = ({ item, setItems }) => {
  const [submitting, setSubmitting] = useState(false)
  const [amount, setAmount] = useState<string>(item.amount.toString())

  useEffect(() => {
    setAmount(item.amount.toString())
  }, [item])

  const onSubmit = useCallback(() => {
    if (submitting) return
    setSubmitting(true)
    const parsed = parseFloat(amount)
    if (isNaN(parsed)) {
      setAmount(item.amount.toString())
      setSubmitting(false)
      return
    } else {
      prepCostApi
        .updatePrepCost(item.id, parsed)
        .then((res) => {
          setItems((old) => old && old.map((i) => (i.id === item.id ? res : i)))
        })
        .finally(() => setSubmitting(false))
    }
  }, [setItems, item, amount, submitting])

  return (
    <TR className={['relative', submitting && 'animate-pulse'].asClass}>
      <TD className="w-1/2">
        <span className="text-center rounded px-2 py-1 bg-[#ECFDF3] text-xs font-medium text-[#027A48]">{item.name}</span>
      </TD>
      <TD className="w-1/2 !p-1">
        <div className="flex w-full rounded border border-border-primary">
          <div className="border-r border-r-border-primary p-3 bg-surface-light">
            <Icon name="Dollar" />
          </div>
          <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} onBlur={onSubmit} className="p-1 pl-3.5" placeholder="0.00" />
        </div>
      </TD>
    </TR>
  )
}

export default PrepCostItem
