import { FC, useCallback, useState } from 'react'
import Item from './components/Item'
import { HousedAsin } from 'types/housedAsins'
import ContentLayout from 'components/layout/Content'
import Loader from 'components/loaders/Loader'
import Table from 'components/tables/Table'
import columnDef from './tableDef'
import useTitle from 'contexts/Title/useTitle'
import useBulkDelete from 'hooks/useBulkDelete'
import housedAsinsApi from 'api/housedAsins'
import useSelect from 'hooks/useSelect'
import usePrepCosts from 'hooks/usePrepCosts'
import LockButton from 'components/buttons/LockButton'
import useAwaitableModal from 'hooks/useAwaitableModal'
import UpdateShippingCostsModal from './components/UpdateShippingCostsModal'
import useBulkAction from 'hooks/useBulkAction'
import useSupplierNames from 'hooks/useSupplierNames'
import useHoused from 'hooks/useHoused'
import withFiltering from 'contexts/Filter/wrapper'
import useAvailableBuyers from 'hooks/useAvailableBuyers'

const HousedAsins: FC = () => {
  const { housed, setHoused, importing, importExcel, loadingNextPage, UploadModal, loadHousedNextPage } = useHoused()
  const {availableBuyers} = useAvailableBuyers()
  const {prepCosts} = usePrepCosts()
  const select = useSelect()
  const [locked, setLocked] = useState(true)
  const [onDelete, deleting, AreYouSureModal] = useBulkDelete(select, housedAsinsApi.deleteHousedBulk, { header: 'Delete Asin', description: 'Are you sure you want to delete these entries?' })
  const [onCreatePurchaseOrder, creatingPurchaseOrder] = useBulkAction(select, housedAsinsApi.createPurchaseOrder, "/app/purchase-orders/open")

  const {supplierNames} = useSupplierNames();

  const onUpdate = useCallback(
    (entry?: HousedAsin) => {
      if (!entry) return
      setHoused((old) => old && old.map((s) => (s.id === entry.id ? entry : s)))
    },
    [setHoused]
  )

  const [changeShippingCost, UpdateShippingCosts] = useAwaitableModal(UpdateShippingCostsModal, undefined)

  useTitle('Data Entry Page for Master ASIN List')

  const renderItem = useCallback((item: HousedAsin) => <Item key={item.id} item={item} onDone={onUpdate} />, [onUpdate])

  const canDoBulk = select.selected.length > 0 || select.allSelected
  const bulkInProgress = creatingPurchaseOrder || deleting

  return (
    <ContentLayout
      afterSearch={<LockButton locked={locked} toggle={setLocked} />}
      underSearch={
        canDoBulk ? (
          <div className="flex gap-4">
            <button className="button-primary w-max" onClick={onCreatePurchaseOrder} disabled={bulkInProgress}>
              Create a Purchase Order
            </button>
            <button className="button-destructive w-max" onClick={onDelete} disabled={bulkInProgress}>
              Delete
            </button>
          </div>
        ) : undefined
      }
      buttons={
        <>
          {!importing ? (
            <div className="flex gap-4 items-center">
              <button className="button-secondary" onClick={changeShippingCost}>
                Shipping Cost
              </button>
              <button className="button-secondary" onClick={importExcel}>
                Import CSV
              </button>
            </div>
          ) : (
            <Loader size={24} />
          )}
        </>
      }
    >
      <UploadModal />
      <AreYouSureModal />
      <UpdateShippingCosts />
      <Table
        columns={columnDef}
        items={housed}
        renderItem={renderItem}
        loading={!housed}
        loadingNext={loadingNextPage}
        onBottom={loadHousedNextPage}
        select={select}
        locked={locked}
        extra={{ supplierNames, prepCosts: prepCosts?.map((p) => p) || [], availableBuyers }}
      />
    </ContentLayout>
  )
}

export default withFiltering(HousedAsins)
