import { IColumnDefinition } from "types/table";

const columnDef: IColumnDefinition[] = [
    {
        field: "expander",
        title: "",
        pin: true,
    },
    {
        field: "parameter",
        title: "Parameter",
        filters: "string",
        pin: true,
    },
]

export default columnDef;