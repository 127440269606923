import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'

type InputField = {
  id: string
  name: string
  type: 'text' | 'number' | 'date'
  required: boolean
}

type SelectField = {
  id: string
  name: string
  type: 'select'
  options: (string | { value: string; label: string })[]
  extendable?: boolean
  required: boolean
}

interface Config {
  title: string
  sections: {
    title?: string
    fields: (InputField | SelectField)[]
  }[]
}

const CreateFormModal: AwaitableModal<any, { config: Config; onSubmit: <T = any>(data: T) => Promise<any> }> = ({ initialData, open, resolve }) => {
  const config = initialData?.config
  const onSubmit = initialData?.onSubmit || ((data: unknown) => Promise.resolve())
  const [values, setValues] = useState({} as Record<string, any>)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!config) return
    setValues(
      config.sections.reduce((acc, section) => {
        section.fields.forEach((field) => {
          acc[field.id] = ''
        })
        return acc
      }, {} as Record<string, any>)
    )
  }, [config])

  const handleSubmit = async () => {
    if (submitting) return
    if (Object.values(values).some((value) => !value)) return handleError('All fields are required')
    setSubmitting(true)
    onSubmit(values)
      .then((res) => {
        resolve(res)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const valueTransformer = (fieldType: string, value: any) => {
    switch (fieldType) {
      case 'number':
        return Number(value) || 0
      default:
        return value
    }
  }

  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="px-4 py-4 flex items-center justify-between">
            <span className="text-base font-medium text-text-primary">{config?.title}</span>
            <Icon name="X" className="cursor-pointer" onClick={() => resolve()} />
          </div>
          <div className="flex flex-col gap-4 bg-surface-secondary px-4 py-2">
            {config?.sections.map((section) => (
              <div key={section.title} className="flex flex-col gap-2">
                {!!section.title && <span className="text-sm font-medium text-text-primary">{section.title}</span>}
                <div className="flex flex-col gap-2">
                  {section.fields.map((field) => (
                    <div key={field.id} className="flex flex-col gap-1 input-box">
                      {field.type !== 'select' ? (
                        <input
                          id={field.id}
                          type={field.type === 'number' ? 'text' : field.type}
                          required={field.required}
                          value={values[field.id]}
                          onBlur={(e) => setValues((prev) => ({ ...prev, [field.id]: valueTransformer(field.type, e.target.value) }))}
                          onChange={(e) => setValues((prev) => ({ ...prev, [field.id]: e.target.value }))}
                        />
                      ) : (
                        <>
                          {field.extendable && (
                            <input
                              id={field.id}
                              type="text"
                              placeholder='Add new option'
                              required={field.required}
                              value={values[field.id] || ''}
                              onBlur={(e) => setValues((prev) => ({ ...prev, [field.id]: e.target.value }))}
                              onChange={(e) => setValues((prev) => ({ ...prev, [field.id]: e.target.value }))}
                            />
                          )}
                          <select className="px-4 py-2 rounded-lg" onChange={(e) => setValues((prev) => ({ ...prev, [field.id]: e.target.value }))}>
                            <option value="">Select an option</option>
                            {field.options.map((option) =>
                              typeof option === 'string' ? (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ) : (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              )
                            )}
                          </select>
                        </>
                      )}
                      <label htmlFor={field.id}>{field.name}</label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center gap-4 p-4 w-full">
            <button className="button-tertiary grow" disabled={submitting} onClick={() => resolve()}>
              Cancel
            </button>
            <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CreateFormModal
