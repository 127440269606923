import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

interface props {
    children: React.ReactNode
    anchorEl: ReactNode
    top?: boolean
    wrapperClass?: string
}

const Dropdown: FC<props> = ({ anchorEl, children, top: _top, wrapperClass }) => {
  const [show, setShow] = useState<boolean>(false)
  const listener = useRef<() => void>()
  const anchor = useRef<HTMLDivElement>(null)
  const display = useRef<HTMLDivElement>(null)
  const [shouldBeTop, setShouldBeTop] = useState<boolean>(false)

  const top = _top || shouldBeTop

  useEffect(() => {
    const handler = () => {
      setShow(false)
    }

    document.addEventListener("hideSelector", handler)

    listener.current?.()
    listener.current = () => document.removeEventListener("hideSelector", handler)

    return listener.current
  }, [show])

  useEffect(() => {
    const listener = () => setShow(false)

    window.addEventListener("click", listener)

    return () => window.removeEventListener("click", listener)
  }, [show])

  const getPosition = useCallback(() => {
    const rect = anchor.current?.getBoundingClientRect()
    if (!rect) return [0, 0]
    const y = rect.y
    const x = rect.x
    const h = rect.height
    const t = y + h + 200 > window.innerHeight
    setShouldBeTop(t)
    if (top) return [x,y - (display.current?.clientHeight || 0)]
    return [x, y + h]
  }, [anchor, top])

  useEffect(() => {
    const onResize = () => {
      if(!show) {
        if (display.current) {
          display.current.style.maxHeight = "none"
          display.current.style.overflowY = "none"
          display.current.style.paddingRight = undefined as any
        }
        return
      }
      const [x, y] = getPosition()
      const el = display.current
      if (!el) return
      const w = anchor.current?.clientWidth || 0;
      display.current.style.filter = `blur(0px)`
      display.current.style.minWidth = `${w}px`
      const width = el.clientWidth
      const height = el.clientHeight
      const xWillBeOffScreen = x + width > window.innerWidth;
      const yWillBeOffScreen = y + height > window.innerHeight;
      const yCanFit = height < window.innerHeight - 16
      const xToSet = xWillBeOffScreen ? window.innerWidth - width : x
      const yToSet = yCanFit ? yWillBeOffScreen ? window.innerHeight - height : y : 0
      const maxHeightToSet = yCanFit ? height : (window.innerHeight - 16)
      const yWithinBounds = Math.max(0, Math.min(window.innerHeight - height - 16, yToSet))
      display.current.style.left = Math.floor(xToSet) + "px"
      display.current.style.top = Math.floor(yWithinBounds) + "px"
      if (!yCanFit) {
        display.current.style.maxHeight = `${maxHeightToSet}px`
        display.current.style.overflowY = "auto"
        display.current.style.paddingRight = "0px"
      } else {
        display.current.style.maxHeight = "none"
        display.current.style.overflowY = "none"
        display.current.style.paddingRight = undefined as any
      }
    }

    document.addEventListener("scroll", onResize, true)

    onResize()

    window.addEventListener("resize", onResize, true)

    return () => {
      window.removeEventListener("resize", onResize)
      document.removeEventListener("scroll", onResize, true)
    }
  }, [getPosition, show])

  return (
    <div className="relative flex" ref={anchor}>
      <button
        className="selector"
        children={anchorEl}
        onClick={e => {
          e.stopPropagation()
          setShow(old => {
            if (old) return false
            listener.current?.()

            const event = new CustomEvent("hideSelector")
            document.dispatchEvent(event)

            return true
          })
        }}
      />
      {createPortal(<div
        className={[
          "fixed top-0 left-0 min-w-max z-[100000000000]",
          wrapperClass,
          show ? "pointer-events-auto" : "pointer-events-none",
        ].asClass}
        ref={display}
      >
        <div
          className={[
            "overflow-hidden drop-shadow-xl",
            "transition-all duration-300 ease-in-out",
            (top ? [
              "-top-2",
              !show ? "translate-y-full max-h-full opacity-0 pointer-events-none" : "translate-y-0 opacity-1",
            ] : [
              "top-2",
              !show ? "-translate-y-full max-h-full opacity-0 pointer-events-none" : "translate-y-0 opacity-1",
            ]).asClass,
          ].asClass}
        >
          {children}
        </div>
      </div>, document.body)}
    </div>
  )
}

export default Dropdown
