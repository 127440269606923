import BadgeSelect from "components/BadgeSelect";
import TD from "components/tables/TD";
import TR from "components/tables/TR";
import useTableContext from "contexts/Table/useTableContext";
import { FC } from "react";
import { useNavigate } from "react-router";
import { IWarehouseInboundShipment } from "types/warehouse";

interface props {
    shipment: IWarehouseInboundShipment
}

const StatusBadge: FC<{ status: "inbound" | "back-order" }> = ({ status }) => {
  if (status === "inbound") {
    return <span className="bg-[#ECFDF3] text-[#027A48] text-xs px-2 py-1 rounded">Inbound</span>
  }

  return <span className="bg-[#FFF4EA] text-[#F0A30D] text-xs px-2 py-1 rounded">Back Order</span>
}

const Item: FC<props> = ({shipment}) => {
    const table = useTableContext()
    const {supplierNames} = table.table.extra
    const navigate = useNavigate()

    const onClick = () => {
        navigate(`/app/warehouse/inbound/${shipment.id}`)
    }

    return (
        <TR onClick={onClick} className="cursor-pointer">
            <TD>
                <input type="date" value={shipment.createdAt.split("T")[0]} readOnly />
            </TD>
            <TD>
                {shipment.title}
            </TD>
            <TD>
                <BadgeSelect
                    selected={shipment.supplier.name}
                    badges={supplierNames}
                    onSelect={() => {}}
                    editable={false}
                />
            </TD>
            <TD>
                {shipment.asins}
            </TD>
            <TD>
                {shipment.units}
            </TD>
            <TD>
                <StatusBadge status={shipment.status} />
            </TD>
            <TD>
                {shipment.eta ? <input type="date" value={shipment.eta.split("T")[0]} readOnly /> : "N/A"}
            </TD>
        </TR>
    )
}

export default Item