// import ContactPage from 'pages/public/Contact'
import { Navigate, Route, Routes } from 'react-router-dom'
// import PrepFeaturesPage from 'pages/public/Prep'
// import SellerFeaturesPage from 'pages/public/Seller'
// import PrivacyPolicy from 'pages/public/PrivacyPolicy'
// import TermsOfService from 'pages/public/TermsOfService'
import PublicLayout from 'layouts/Public'
import HomePageV2 from 'pages/public/Home'

const PublicRouter = () => {
  document.querySelector('html')?.classList.remove('dark')

  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route index element={<HomePageV2 />} />
        {/*<Route path="prep" element={<PrepFeaturesPage />} />
        <Route path="seller" element={<SellerFeaturesPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="seller-signup" element={<SellerSignupForm />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
  <Route path="terms-of-service" element={<TermsOfService />} />*/}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  )
}

export default PublicRouter
