import { HousedAsin } from 'types/housedAsins'
import { IColumnDefinition } from 'types/table'

const columnDef: IColumnDefinition<HousedAsin & { image: string }>[] = [
  {
    field: 'image',
    title: 'Image',
    pin: true,
  },
  {
    field: 'asin',
    queryField: 'item.asin',
    title: 'ASIN',
    filters: 'string',
    pin: true,
  },
  {
    field: 'title',
    queryField: 'item.title',
    title: 'Title',
    filters: 'string',
  },
  {
    field: 'supplier',
    queryField: 'supplier.name',
    title: 'Supplier',
    filters: 'string',
  },
  {
    field: 'upc',
    queryField: 'item.upc',
    title: 'UPC',
    filters: 'string',
  },
  {
    field: 'vendorSKU',
    queryField: 'item."vendorSKU"',
    title: 'Vendor SKU',
    filters: 'string',
  },
  {
    field: 'caseQty',
    queryField: 'item."caseQty"',
    title: 'Case Qty',
    filters: 'number',
  },
  {
    field: 'unitCost',
    queryField: 'item."unitCost"',
    title: 'Unit Cost',
    filters: 'number',
  },
  {
    field: 'bundle',
    queryField: 'item.bundle',
    title: 'Bundle Qty',
    filters: 'number',
  },
  {
    field: 'buyer',
    queryField: 'item.buyer',
    title: 'Buyer',
    filters: 'string',
  },
  {
    field: 'prepCost',
    queryField: '"prepCost".name', 
    title: 'Prep Type',
    filters: 'string',
  },
  {
    field: 'asinCost',
    queryField: '"asinCost"',
    title: 'ASIN Cost',
  },
  {
    field: 'shippingCost',
    queryField: '"shippingCost"',
    title: 'Shipping Cost',
  },
  {
    field: 'landedCost',
    queryField: '"landedCost"',
    title: 'Landed Cost',
  },
  {
    field: 'price',
    queryField: 'item.price',
    title: 'Current Price',
    filters: 'number',
  },
  {
    field: 'targetPrice',
    queryField: 'item."targetPrice"',
    title: 'Target Sold Price',
    filters: 'number',
  },
  {
    field: 'net',
    queryField: "net",
    title: 'Net Proceeds',
  },
  {
    field: 'gross',
    queryField: "gross",
    title: 'Profit',
  },
  {
    field: 'margin',
    queryField: "margin",
    title: 'Margin',
  },
  {
    field: 'roi',
    queryField: "roi",
    title: 'ROI',
  },
  {
    field: 'salesRank',
    queryField: 'item."salesRank"',
    title: 'Rank',
    filters: 'number',
  },
  {
    field: 'weightValue',
    queryField: 'item."weightValue"',
    title: 'Weight',
    filters: 'number',
  },
  {
    field: 'dimensions',
    title: 'Dimensions',
  },
]

export default columnDef
