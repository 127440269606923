import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import AuthPage from '../../pages/auth/Auth'
import RegisterPage from '../../pages/auth/Register'
import PasswordResetPage from 'pages/auth/PasswordReset'

const UnauthorizedRouter = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<AuthPage />} />
        <Route path="login" element={<AuthPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="register/:referrer" element={<RegisterPage />} />
        <Route path="reset-password" element={<PasswordResetPage />} />
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Route>
    </Routes>
  )
}

export default UnauthorizedRouter
