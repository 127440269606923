import { IColumnDefinition } from "types/table";

const columnDef: IColumnDefinition[] = [
    {
        field: "image",
        title: "Image",
        pin: true,
    },
    {
        field: "asin",
        title: "ASIN",
        filters: "string",
        pin: true,
    },
    {
        field: "url",
        title: "URL",
        pin: true,
    },
    {
        field: "name",
        queryField: "item.name",
        title: "Title",
        filters: "string",
        pin: true,
    },
    {
        field: "sku",
        title: "MSKU",
        filters: "string",
    },
    {
        field: "supplier",
        title: "Supplier",
        filters: "string",
    },
    {
        field: "buyPrice",
        title: "Unit Cost",
        filters: "number",
    },
    {
        field: "bundle",
        title: "Bundle Qty",
        filters: "number",
    },
    {
        field: "prepCost",
        title: "Prep Type",
        filters: "string",
    },
    {
        field: "asinCost",
        title: "ASIN Cost",
    },
    {
        field: "stock",
        title: "Total Inventory",
        filters: "number",
    },
    {
        field: "offerPrice",
        title: "Listing Price",
    },
    {
        field: "totalRevenue",
        title: "Total Revenue",
    },
    {
        field: "totalCOGs",
        title: "Total COGs",
    },
    {
        field: "totalNetProceeds",
        title: "Total Net Proceeds",
    },
    {
        field: "totalProfit",
        title: "Total Profits",
    },
    {
        field: "listingCreatedAt",
        title: "Date Created",
        filters: "date",
    },
    {
        field: "type",
        title: "Fulfillment Method",
        filters: "string"
    }
]

export default columnDef;